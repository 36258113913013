import React, { memo, useContext, useEffect, useState } from 'react';

import Serchicon from '../../assets/img/search_icon.svg';
import OutsideClickHandler from 'react-outside-click-handler';
import { listCommunity } from '../../services/CommunityService';
import Img1 from '../../assets/img/img-1.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import { IoLocationOutline } from 'react-icons/io5';
import { DashboardContext } from '../../context/DashboardContext';

const SearchBar = (props: any) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { community_name, setCommunityName } = useContext(DashboardContext);
    const [communityDrpData, setCommunityDrpData] = useState([]);
    const [searchTxt, setSearchTxt] = useState<any>('');
    const [isOpenSuggestion, setIsOpenSuggestion] = useState<boolean>(false);

    const handleCommunityChange = (selected: any) => {
        setIsOpenSuggestion(false);
        navigate(ROUTES.COMMUNITY + '/' + selected);
    };

    const handleSearchBtn = () => {
        if (searchTxt !== '') {
            setCommunityName(searchTxt);
        }
        if (location.pathname !== ROUTES.HOME) {
            navigate(ROUTES.HOME);
        }
    };
    const handleOutsideClick = () => {
        setIsOpenSuggestion(false);
    };
    const handleChangeSearch = (e:any) => {
        setSearchTxt(e.target.value)
        setCommunityName('')
    };

    useEffect(() => {
        setIsOpenSuggestion(true);
        if (searchTxt === '') {
            setIsOpenSuggestion(false);
            setCommunityDrpData([]);
            return;
        }
        const getDataTimeout = setTimeout(() => {
            listCommunity({ is_drp_listing: false, community_name: searchTxt })
                .then((response) => {
                    const communityData = response?.data.map((v: any) => {
                        return { value: v.id, label: v.community_name, status: v.status, city: v.city?.name , image: v.image };
                    });
                    setCommunityDrpData(communityData);
                })
                .catch(console.log);
        }, 1000);

        return () => clearTimeout(getDataTimeout);
    }, [searchTxt]);

    return (
        <>
            <div className="drop-shadow-[0px_4px_25px__rgba(116,110,109,0.04)] border-gray-200 bg-white rounded-2xl w-full z-[20]">
                <OutsideClickHandler
                    onOutsideClick={() => {
                        return handleOutsideClick();
                    }}
                >
                    <div className="flex">
                        <div className="flex-1 relative">
                            <input
                                type="search"
                                className={
                                    'palceholder:capitalize block w-full border-inherit  border-r-0 text-gray-900  placeholder:text-gray-400 sm:text-sm sm:leading-6 focus-visible:outline-none dark:bg-[#252525] dark:text-[#b9b7b6] dark:border-[#393939] rounded-l-lg'
                                }
                                placeholder="Search Communities or Properties"
                                value={searchTxt || community_name}
                                onFocus={() => setIsOpenSuggestion(true)}
                                onChange={(e) => handleChangeSearch(e)}
                            />
                            {isOpenSuggestion && communityDrpData?.length > 0 && (
                                <div className="drop-shadow-[0px_4px_25px__rgba(116,110,109,0.04)] border-gray-200 bg-white rounded-2xl block w-full z-[50] absolute">
                                    <div className="topbar__menu css-1nmdiq5-menu">
                                        <div className="topbar__menu-list css-1n6sfyn-MenuList">
                                            {communityDrpData.map((community: any, index: number) => (
                                                <div
                                                    className="topbar__option"
                                                    aria-disabled="false"
                                                    onClick={() => handleCommunityChange(community?.value)}
                                                    key={index}
                                                >
                                                    <div className="flex">
                                                        <img
                                                            src={community?.image || Img1}
                                                            alt=""
                                                            className="w-[50px] h-[50px] md:w-[50px] md:h-[50px] object-cover me-2 rounded"
                                                        />
                                                        <div>
                                                            <div className="flex items-center">
                                                                <p className="xs:text-base text-sm text-[#2E2624] dark:text-[#b9b7b6]">
                                                                    {community.label}
                                                                </p>
                                                                <span
                                                                    className={
                                                                        'ml-2 xs:text-sm text-xs px-3 py-[4px] h-6 flex items-center rounded-full leading-4' +
                                                                        (community?.status === 'ACTIVE'
                                                                            ? ' bg-greenClr/20 pt-2 text-greenClr'
                                                                            : ' bg-[#EAE9E9] text-[#746E6D] dark:bg-[#393939] pt-2')
                                                                    }
                                                                >
                                                                    {community?.status}
                                                                </span>
                                                            </div>
                                                            <div className="flex items-center">
                                                                <IoLocationOutline className="text-base" />
                                                                <p className="mt-1 pl-1 text-sm text-[#2E2624]">{community?.city}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <button
                            disabled={props?.isDisabledCommunitySearch}
                            className="inset-y-0 bg-orange-500 sm:w-[148px] w-[110px] flex items-center justify-center lg:px-5 px-3 rounded-tr-md rounded-br-md pb-[2px] "
                            onClick={handleSearchBtn}
                        >
                            <img src={Serchicon} alt="" className="md:w-[32px] sm:w-[26px] w-[22px] object-cover sm:pe-2 pe-1" />
                            <h4 className="text-white md:text-base sm:text-sm text-xs font-normal uppercase m-0 mt-1">Search</h4>
                        </button>
                    </div>
                </OutsideClickHandler>
            </div>
        </>
    );
};

export default memo(SearchBar);
