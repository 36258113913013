import { axiosInterceptor } from './interceptors';
import { API } from '../constants/api';

export const listPhase = async (data: any) => {
    const response = await axiosInterceptor.post(`${API.PHASE.LIST}`, data);
    return response;
};

export const fetchPhaseDetails = async (id: string) => {
    const response = await axiosInterceptor.get(`${API.PHASE.LIST}?id=${id}`,);
    return response;
};


export const downloadContactDetails = async (id: number) => {
    const response = await axiosInterceptor.get(`${API.PHASE.DOWNLOAD}?id=${id}`,{responseType: 'blob'});
    return response;
};

export const createPhase = async (data: any) => {    
    const response = await axiosInterceptor.post(`${API.PHASE.CREATE}`, data);
    return response;
};

export const updatePhase = async (id: string, data: any) => {    
    const response = await axiosInterceptor.patch(`${API.PHASE.UPDATE}/${id}`, data);
    return response;
};
export const deletePhase = async (id: string) => {
    const response = await axiosInterceptor.delete(`${API.PHASE.DELETE}/${id}`);
    return response;
};