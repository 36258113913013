import { memo, useContext } from 'react';
import { Link } from 'react-router-dom';
import { CommunityContext } from '../../../context/CommunityContext';
import { ROUTES } from '../../../constants/routes';

const BreadCrumb = ({ className, data }: any) => {
    const { communityData } = useContext(CommunityContext);
    return (
        <>
            <div className={`flex text-sm ${className}`}>
                <Link className="dark:text-[#746E6D]" to={ROUTES?.HOME}>
                    <span className="mr-2 dark:text-[#746E6D] ">Home</span>
                </Link>
                <span className="mr-2 dark:text-[#746E6D]">/</span>
                <Link className="text-orangeClr" to={''}>
                    {data?.communityName || communityData?.community_name}
                </Link>
            </div>
        </>
    );
};

export default memo(BreadCrumb);
