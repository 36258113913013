import { memo, ReactElement, useContext, useEffect, useState } from 'react';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import MyMap from './MyMap';
import { DashboardContext } from '../../../context/DashboardContext';
import Loader from '../../../components/Loader';
const TopSearchBox = (props: any) => {
    const { communityData, communityPageDetail } = useContext(DashboardContext);
    const [locations, setLocations] = useState<any['']>([]);

    const render = (status: Status): ReactElement => {
        if (status === Status.LOADING) return <h3>{status} ..</h3>;
        if (status === Status.FAILURE) return <h3>{status} ...</h3>;
        return <h3>{status} ...</h3>;
    };

    useEffect(() => {
        if (communityPageDetail) {
            setLocations([communityPageDetail]);
        } else {
            setLocations(communityData);
        }
    }, [communityData, communityPageDetail]);
    return (
        <>
            <div className="flex-1 lg:ml-6 border lg:order-2 order-1 h-[360px] sm:h-[450px] lg:h-auto lg:mb-0 sm:mb-9 mb-5 border-gray-200 bg-white dark:bg-[#252525] dark:border-[#252525] sm:rounded-2xl rounded-lg sm:p-4 p-2 transition-all duration-75 ease-linear map-detail">
                {props?.isLoading &&
                    <Loader />
                }
                {locations?.length > 0 && props?.isLoading === false && (
                    <Wrapper apiKey={window.GOOGLE_API_KEY!} render={render} version="beta" libraries={['marker']}>
                        <MyMap mapClassName="h-full w-full" locations={locations} />
                    </Wrapper>
                )}
            </div>
        </>
    );
};

export default memo(TopSearchBox);
