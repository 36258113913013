import { axiosInterceptor } from './interceptors';
import { API } from '../constants/api';

export const createLot = async (data: any) => {
    const response = await axiosInterceptor.post(`${API.LOT.LOT_CREATE}`, data);
    return response;
};
export const updateLot = async (id: string, data: any) => {   
    const response = await axiosInterceptor.patch(`${API.LOT.LOT_UPDATE}/${id}`, data);
    return response;
};
export const deleteLot = async (id: { projectId: string; model: string }) => {
    const response = await axiosInterceptor.delete(`${API.LOT.LOT_DELETE}?pId=${id.projectId}&model=${id.model}`);
    return response;
};
export const deleteLotClassDetail = async (id: { projectId: string; model: string ;elevation: string;}) => {
    const response = await axiosInterceptor.delete(`${API.LOT.LOT_DETAIL_DELETE}?pId=${id.projectId}&model=${id.model}&el=${id.elevation}`);
    return response;
};
export const updateModelName = async (id: string, data: any) => {
    const response = await axiosInterceptor.patch(`${API.LOT.LOT_UPDATE_MODELNAME}/${id}`, data);
    return response;
};