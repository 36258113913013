import React from 'react';
import { Spinner } from 'flowbite-react';

const Loader = () => (
    <div className='loader-container'>
        <Spinner aria-label="Loading..." size="xl" color="gray" />
    </div>

);

export default Loader;
