import { createContext, useState } from 'react';
import PropTypes from 'prop-types';
export const DashboardContext = createContext<any>({});

export const DashboardProvider = (props: any) => {
    const { children } = props;
    const [community_name, setCommunityName] = useState<string>('');
    const [status, setStatus] = useState<string>('');
    const [community_id, setCommunityId] = useState<any[''] | ''>([]);
    const [phase_id, setPhaseId] = useState<any[''] | ''>('');
    const [communityData, setCommunityData] = useState<[]>([]);
    const [communityPageDetail, setCommunityPageDetail] = useState<any>(null);


    const value = {
        community_name,
        status,
        community_id,
        phase_id,
        communityData,
        communityPageDetail,
        setCommunityName,
        setStatus,
        setCommunityId,
        setPhaseId,
        setCommunityPageDetail,
        setCommunityData
    }

    return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>;
};

DashboardProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
