import { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { FiMail, FiLock } from 'react-icons/fi';
// import Loginlogo from '../../assets/img/login_page_logo.svg';
// import InputText from '../../components/InputText/InputText';
// import { ILoginFormData } from './Login.interface';
import { login } from '../../services/AuthService';
// import { AuthContext } from '../../context/AuthContext';
// import { ROUTES } from '../../constants/routes';
import './login.scss';
import { toast } from 'react-toastify';

// const initValidForm = {
//     email: true,
//     isEmailValid: true,
//     password: true,
// };

const Login = () => {
    // const { setIsAuthenticated } = useContext(AuthContext);
    // const [validForm, setValidForm] = useState(initValidForm);
    // const initFormData: ILoginFormData = {
    //     email: '',
    //     password: '',
    // };
    // const navigate = useNavigate();

    // const [formData, setFormData] = useState<ILoginFormData>(initFormData);
    // const [errorMsg, setErrorMsg] = useState<any>('');

    // const valid = () => {
    //     let valid = true;
    //     const validFormData = {
    //         email: true,
    //         isEmailValid: true,
    //         password: true,
    //     };
    //     const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g;

    //     if (formData.email === '' || formData.email === null) {
    //         validFormData.email = false;
    //         valid = false;
    //     }

    //     if (formData.email && !emailRegex.test(formData.email)) {
    //         validFormData.isEmailValid = false;
    //         valid = false;
    //     }

    //     if (formData.password === '' || formData.password === null) {
    //         validFormData.password = false;
    //         valid = false;
    //     }

    //     setValidForm({ ...validForm, ...validFormData });
    //     return valid;
    // };

    // const handleInputChange = (event: any) => {
    //     setFormData({ ...formData, [event.target.name]: event.target.value });
    // };
    // let newWindow: any;
    // const handleWindowClose = (message: any) => {
    //     if (message === "loginSuccess") {
    //         setIsAuthenticated(true);

    //     } else if (message === "loginFailure") {
    //         toast.error("Your email is not included in the app. \n Please contact to administrator.");
    //     }
    //     newWindow.close();
    // };

    useEffect(() => {
        // window.addEventListener("message", (event) => {
        //     if (event.source === newWindow) {
        //         handleWindowClose(event.data);
        //     }
        // });
        // return () => window.removeEventListener("message", handleWindowClose);
        signIn()
    }, []);

    const signIn = () => {

        // setErrorMsg('');
        // if (!valid()) {
        //     return true;
        // }
        login()
            .then((response) => {
                const url = response.data.url;
                window.location.href = url
                // const height = 600;
                // const width = 500;
                // const leftPosition = window.screen.width / 2 - (width / 2 + 10);
                // const topPosition = window.screen.height / 2 - (height / 2 + 50);
                // newWindow = window.open(
                //     url,
                //     "Window2",
                //     `status=no,height=${height},width=${width},resizable=yes,left=${leftPosition},top=${topPosition},screenX=${leftPosition},screenY=${topPosition},toolbar=no,menubar=no,scrollbars=no,location=no,directories=no`,
                // );
            })
            .catch((error): any => {
                toast.error(error?.response?.data?.message || 'Something went wrong with login');
            });
    };

    return (
        // <div className="h-screen max-h-screen bg-no-repeat bg-cover flex flex-col justify-center items-center empire__auth_banner-bg">
        //     <img src={Loginlogo} alt="logo-dark" className="w-[130px] mb-4 md:mb-6" />
        //     <div className="bg-white w-[350px] md:w-[450px] lg:w-[550px] rounded-3xl shadow-md">
        //         <div className="px-5 py-[30px] md:p-10">
        //             {/* <h1 className="text-xl md:text-[32px] -tracking-[0.24px] md:-tracking-[0.384px] leading-6 md:leading-10 font-semibold">
        //                 Sign In
        //             </h1> */}
        //             <div className="mt-[30px] md:mt-[10px]">
        //                 {/* <div className="flex flex-col">
        //                     <label className="fl-field-title-label font-bold text-base my-2 md:my-3">Email</label>
        //                     <div className="relative mb-4">
        //                         <InputText
        //                             inputName="email"
        //                             className="pt-3 pb-2 px-4 border border-gray-300 rounded-md w-full text-gray-500 text-[14px] md:text-[16px]"
        //                             placeholder="Enter email."
        //                             value={formData.email}
        //                             onChangeFunc={handleInputChange}
        //                             autoComplete="on"
        //                             errorText={
        //                                 !validForm.email ? 'Email is required' : !validForm.isEmailValid ? 'Must be a valid email address' : null
        //                             }
        //                         />
        //                         <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
        //                             <FiMail className="h-5 w-5 text-gray-500" />
        //                         </div>
        //                     </div>
        //                     <label className="fl-field-title-label font-bold text-base my-2 md:my-3">Password</label>
        //                     <div className="relative mb-4">
        //                         <InputText
        //                             inputType="password"
        //                             inputName="password"
        //                             className="pt-3 pb-2 px-4 border border-gray-300 rounded-md w-full text-[14px] md:text-[16px]"
        //                             placeholder="Enter password."
        //                             value={formData.password}
        //                             errorText={!validForm.password ? 'Password is required' : null}
        //                             onChangeFunc={handleInputChange}
        //                         />
        //                         <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
        //                             <FiLock className="h-5 w-5 text-gray-500" />
        //                         </div>
        //                     </div>
        //                     <div className="relative mb-4">
        //                         <p className="text-sm text-red-600 dark:text-red-500">{errorMsg}</p>
        //                     </div>
        //                 </div> */}
        //                 <button
        //                     type="submit"
        //                     onClick={signIn}
        //                     className="group flex items-center justify-center p-0.5 text-center font-medium relative focus:z-10 focus:outline-none rounded-full focus:ring-2 text-base btn_primary fl-btn w-full mt-5 opacity-75"
        //                 >
        //                     <span className="flex items-center transition-all duration-200 rounded-md text-base px-3 py-1.5 text-white">Login with Microsoft</span>
        //                 </button>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        <div className="h-screen max-h-screen bg-no-repeat bg-cover flex flex-col justify-center items-center"></div>
    );
};

export default Login;
