import { memo, useContext } from 'react';
import { CommunityContext } from '../../../context/CommunityContext';
import Loader from '../../../components/Loader';
import no_image from '../../../assets/img/no-image.svg';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../constants/routes';
import { Icon } from '../../../components/Icon';
import { AuthContext } from '../../../context/AuthContext';
import active from '../../../assets/img/active.svg';
import closed from '../../../assets/img/closed.svg';

const CommunitySideBar = () => {
    const { communityData, isLoading } = useContext(CommunityContext);
    const { currentUser } = useContext(AuthContext);

    return (
        <>
            <div className="bg-white dark:bg-[#252525] sm:rounded-2xl rounded-lg drop-shadow-[0px_4px_25px_rgba(116,110,109,0.04)] xxl:w-[533px] xl:w-[400px] lg:w-[35%] w-full lg:mb-0 sm:mb-9 mb-5 xl:p-6 sm:p-5 p-3 pt-[28px] min-h-[76vh]">
                {isLoading && <Loader />}
                {isLoading === false && (
                    <>
                        <div className="flex items-center xl:mb-5 mb-3 justify-between">
                            <div className="flex sm:items-center">
                                <h2 className="capitalize sm:text-[24px] text-xl dark:text-white">{communityData?.community_name}</h2>

                                <button className="ml-2 mb-[5px] flex-none">
                                    <img src={(communityData?.status === 'ACTIVE') ? active : closed} alt="" className="h-[24px]" />
                                </button>
                            </div>

                            <div>
                                {currentUser?.user_group === 'admin' && (
                                    <span className="flex">
                                        <Link
                                            to={`${ROUTES.COMMUNITY_ADD}/${communityData?.id}`}
                                            state={{ communityName: communityData?.community_name }}
                                        >
                                            <Icon
                                                nameIcon={'FaEdit'}
                                                propsIcon={{ className: 'mb-2 text-base text-orangeClr dark:text-white h-4 w-full' }}
                                            />
                                        </Link>
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="xl:rounded-2xl rounded-lg overflow-hidden xl:h-[288px] h-[220px] mb-6 text-orangeClr">
                            <img
                                src={communityData?.image || no_image}
                                alt=""
                                className="w-full h-full object-cover"
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = no_image;
                                }}
                            />
                        </div>

                        <div className="max-h-full overflow-y-auto">
                            <div className="border-b dark:border-[#393939]">
                                <div className="sm:mb-6 mb-4">
                                    <p className="sm:text-sm text-xs text-grayTextClr dark:text-[#746E6D] leading-[16px] mb-2">Legal Name</p>
                                    <p className="leading-[16px] sm:text-lg text-base dark:text-white ">{communityData?.legal_name || '-'}</p>
                                </div>
                                <div className="sm:mb-6 mb-4">
                                    <p className="sm:text-sm text-xs text-grayTextClr leading-[16px] mb-2 dark:text-[#746E6D]">Division</p>
                                    <p className="leading-[16px] sm:text-lg text-base dark:text-white">{communityData?.division || '-'}</p>
                                </div>
                                <div className="sm:mb-6 mb-4">
                                    <p className="sm:text-sm text-xs text-grayTextClr leading-[16px] mb-2 dark:text-[#746E6D]">Marketing Name</p>
                                    <p className="leading-[16px] sm:text-lg text-base dark:text-white">{communityData?.marketing_name || '-'}</p>
                                </div>
                            </div>
                            <div className="mt-5">
                                <div className="flex flex-wrap">
                                    <div className="sm:mb-6 mb-4 xxl:w-1/2 w-full">
                                        <p className="sm:text-sm text-xs text-grayTextClr leading-[16px] mb-2">Region</p>
                                        <p className="leading-[16px] sm:text-lg text-base dark:text-white">{communityData?.country?.name || '-' }</p>
                                    </div>
                                    <div className="sm:mb-6 mb-4 xxl:w-1/2 w-full">
                                        <p className="sm:text-sm text-xs text-grayTextClr leading-[16px] mb-2">Province</p>
                                        <p className="leading-[16px] sm:text-lg text-base dark:text-white">{communityData?.state?.name || '-' }</p>
                                    </div>
                                </div>

                                <div className="flex flex-wrap">
                                    <div className="mb-6 xxl:w-1/2 w-full">
                                        <p className="sm:text-sm text-xs text-grayTextClr leading-[16px] mb-2">Address</p>
                                        <p className="leading-[16px] sm:text-lg text-base dark:text-white">{communityData?.address || '-'}</p>
                                    </div>
                                    <div className="xxl:w-1/2 w-full">
                                        <p className="sm:text-sm text-xs text-grayTextClr leading-[16px] mb-2">City</p>
                                        <p className="leading-[16px] sm:text-lg text-base dark:text-white">{communityData?.city?.name || '-'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default memo(CommunitySideBar);
