import { memo, useCallback, useContext, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import DataTable from 'react-data-table-component';
import { AxiosResponse,AxiosError } from 'axios';
import moment from 'moment';
import { FiArrowRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { BasicContext } from '../../../context/BasicContext';
import { CommunityContext } from '../../../context/CommunityContext';
import toggleicon1 from '../../../../src/assets/img/gridTwo.svg';
import toggleicon2 from '../../../../src/assets/img/gridFour.svg';
import no_image from '../../../assets/img/no-image.svg';
import { ROUTES } from '../../../constants/routes';
import Loader from '../../../components/Loader';
import { Icon } from '../../../components/Icon';
import DeleteModal from '../../../components/DeleteModal';
import { toast } from 'react-toastify';
import { deletePhase } from '../../../services/PhaseService';
import { AuthContext } from '../../../context/AuthContext';
import active from '../../../assets/img/active.svg';
import closed from '../../../assets/img/closed.svg';
import { MdCalendarToday } from 'react-icons/md';
import { FaTrash, FaEdit } from 'react-icons/fa';
import { TbBuildingCommunity } from 'react-icons/tb';

type ToggleType = 'card' | 'table';
type StatusType = 'ACTIVE' | 'CLOSED' | '' ;
interface PlanModel {
    model_name: string;
    // other properties
}
interface Image {
    createdAt: string;
    updatedAt: string;
    id: number;
    type: string;
    url: string; 
    // Add other properties if there are more
}
interface PhaseDetails {
    area_of_experties: string;
    available_lots: number;
    closed_lots: number;
    community_id: number;
    construction_end: string | null;
    construction_start: string;
    createdAt: string;
    id: string;
    projectId: string;
    images: Image[];
    no_of_lots: number;
    no_of_plans: string;
    phase_name: string;
    plan_models: PlanModel[];
    product_type: string;
    sold_lots: number;
    status: StatusType;
    updatedAt: string;
    uuid: string;
    toggleSelected?: boolean;
    project_name: string;
    no_of_modal:any;
    operating_name: string;
}
interface PageChangeEvent {
    selected: number;
}
interface DeletePhaseResponse {
    data: PhaseDetails[];
    message: string;
}
interface ErrorMessage{
    message: string;
}
interface PlanModel {
    name: string;
    // other properties
}
const PhaseList = () => {
    const { currentUser } = useContext(AuthContext);
    const { communityData, isLoading, fetchCommunityData } = useContext(CommunityContext);
    const [communityDetails, setCommunityDetails] = useState<PhaseDetails[]>([]);
    const [selectedPhaseDetails, setSelectedPhaseDetails] = useState<PhaseDetails | null>(null);
    const [status, setStatus] = useState<StatusType>('');
    const [toggleType, setToggleType] = useState<ToggleType>('table');
    const [isDeleteModel, setIsDeleteModel] = useState<boolean>(false);
    const [deleteID, setDeleteId] = useState<string | null>(null);
    const [communityId,setCommunityId] = useState<string | null>(null);
    const { isDarkTheme } = useContext(BasicContext);
    const tableColumns = [
        {
            name: 'Phase Name',
            selector: (row: PhaseDetails) => row.phase_name,
            sortable: true,
        },
        {
            name: 'Status',
            cell: (row: PhaseDetails) => (                
                <button
                    className={`flex-none`}
                >
                    <img src={(row?.status === 'ACTIVE') ? active : closed} alt="" className="h-[24px]" />
                </button>
            ),
            sortable: false,
        },
        {
            name: 'Construction Start',
            selector: (row: PhaseDetails) => (row?.construction_start ? moment(row.construction_start).format('MMM Do, YYYY') : '-'),
            sortable: true,
        },
        {
            name: 'Construction Finish',
            selector: (row: PhaseDetails) => (row?.construction_end ? moment(row.construction_end).format('MMM Do, YYYY') : '-'),
            sortable: true,
        },
        {
            name: 'No. Of Lots',
            selector: (row: PhaseDetails) => (row?.no_of_lots ? Number(row?.no_of_lots)?.toLocaleString() : '-'),
            sortable: true,
        },
    ];

    const [columns] = useState<any>(tableColumns);

    const [currentItems, setCurrentItems] = useState<PhaseDetails[] | null>(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 10;

    useEffect(() => {
        const pathname = window.location.pathname;
        const communityId = pathname.split('/').pop();
        setCommunityId(communityId || null);
    }, []);
    useEffect(() => {
        // Fetch items from another resources.
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(communityDetails?.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(communityDetails?.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, communityDetails]);

    const handlePageClick = (event: PageChangeEvent) => {
        const newOffset = (event.selected * itemsPerPage) % communityDetails.length;
        setItemOffset(newOffset);
    };

    useEffect(() => {
        setCommunityDetails(communityData?.phases);
    }, [communityData]);

    useEffect(() => {
        handleSelectedRow(selectedPhaseDetails);
    }, [toggleType]);
    useEffect(() => {
        if (currentUser?.user_group === 'admin') {
            const actionColumn = {
                name: 'Action',
                cell: (row: PhaseDetails, id: number) => (
                    <>
                        <Link
                            to={`${ROUTES.PHASE_UPDATE}/${row?.projectId}`}
                            state={{ phaseName: row?.phase_name, communityName: communityData?.community_name, communityId: communityData?.id }}
                        >
                            <Icon nameIcon={'FaEdit'} propsIcon={{ className: 'me-2 text-base text-orangeClr dark:text-white h-3 w-full' }} />
                        </Link>
                        <div
                            className="cursor-pointer"
                            onClick={() => {
                                setDeleteId(row?.projectId);
                                setIsDeleteModel(true);
                            }}
                        >
                            <Icon nameIcon={'FaTrash'} propsIcon={{ className: 'me-2 text-base text-orangeClr dark:text-white h-3 w-full' }} />
                        </div>
                    </>
                ),
                ignoreRowClick: true,
                allowOverflow: true,
            };
            // Find if the action column already exists
                const actionColumnIndex = columns.findIndex((column: any) => column.name === 'Action');

            // Remove the existing action column if found
            if (actionColumnIndex !== -1) {
                columns.splice(actionColumnIndex, 1);
            }
            columns.push(actionColumn);
        }
    }, [communityData]);    
    
    const handleStatusChange = (selected: StatusType) => {
        setStatus(selected);
        
        // Check if communityData exists and has phases
        if (!communityData?.phases) {
            setCommunityDetails([]); // Set empty array if communityData or phases not found
            return;
        }
    
        if (selected === '') {
            setCommunityDetails(communityData.phases); // Set all phases if no status is selected
        } else {
            setCommunityDetails(communityData.phases.filter((v: PhaseDetails) => v.status === selected));
        }
    };    
    
    const conditionalRowStyles = [
        {
            when: (row: PhaseDetails) => !!row.toggleSelected,
            style: {
                backgroundColor: isDarkTheme ? '#a73c07 !important' : '#FFEFE7',                
                userSelect: 'none',
            },
        },
    ];

    const handleSelectedRow = (selected: PhaseDetails | null) => {
        const updatedData = communityDetails?.map((item) => {
           
            const iData = item as PhaseDetails;
            if (iData.projectId !== selected?.projectId) {
                return {
                    ...iData,
                    toggleSelected: false,
                };
            }

            return {
                ...iData,
                toggleSelected: true,
            };
        });
        

        setCommunityDetails(updatedData as PhaseDetails[]);
        setSelectedPhaseDetails(selected);
    };

    const handleModalClose = useCallback(
        (status: boolean) => () => {
            if (status) {
                if (deleteID !== null) {
                    deletePhase(deleteID)
                        .then((result: AxiosResponse<DeletePhaseResponse>) => {
                            if (selectedPhaseDetails?.projectId === deleteID) {
                                setSelectedPhaseDetails(null); // Set to null if they match
                            }
                            setDeleteId(null);
                            toast.success('Successfully delete phase details');
                            fetchCommunityData(communityId);
                        })
                        .catch((error: AxiosError<ErrorMessage>) => {
                            setDeleteId(null);
                            const errMsg =
                                error?.response?.status === 409 ? error?.response?.data?.message : 'Something went wrong! Please try again';
                            toast.error(errMsg);
                        });
                    setIsDeleteModel(false);
                }
            } else {
                setIsDeleteModel(false);
            }
            setDeleteId(null);
        },
        [isDeleteModel],
    );

    return (
        <>
            <div className="xl:w-full lg:w-3/5 w-full lg:ml-6">
                {selectedPhaseDetails && (
                    <div className="mb-4 bg-white sm:rounded-2xl rounded-lg drop-shadow-[0px_4px_25px_rgba(116,110,109,0.04)] sm:p-6 px-3 py-4 dark:bg-[#252525]">
                        <div className="bg-white drop-shadow-[0px_4px_25px_rgba(116,110,109,0.04)] dark:bg-[#252525]">
                            <div className="flex justify-between text-center items-center mb-5">
                                <p className="font-normal xl:text-2xl text-xl leading-6 capitalize text-mainTextClr dark:text-white">
                                    {selectedPhaseDetails.phase_name}
                                </p>
                                <Link
                                    to={`${ROUTES.PHASE}/${selectedPhaseDetails?.projectId}`}
                                    state={{ communityName: communityData?.community_name, phaseName: selectedPhaseDetails?.phase_name }}
                                >
                                    <button className="border border-orange-500 flex text-center items-center justify-center py-1 px-2 md:px-4 rounded-lg">
                                        <span className="px-1 pt-1 text-orange-500 font-normal text-sm xl:text-base leading-none uppercase">
                                            See details
                                        </span>
                                        <FiArrowRight className="text-orange-500 xl:text-xl text-base self-center hidden md:block" />
                                    </button>
                                </Link>
                            </div>
                            <div className="flex sm:flex-nowrap flex-wrap">
                                <div className="overflow-hidden text-orangeClr flex-none">
                                    <img
                                        src={selectedPhaseDetails?.images?.[0]?.url || no_image}
                                        alt=""
                                        className="xl:w-full md:w-[330px] lg:w-[280px] sm:w-full md:h-[250px] h-full sm:rounded-2xl rounded-lg object-cover"
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = no_image;
                                        }}
                                    />
                                </div>
                                <div className='flex flex-wrap'>
                                <div className="sm:ml-6 pt-4 xl:pt-0">
                                    <div className="mb-5">
                                        <p className="text-grayTextClr leading-4 font-normal sm:text-sm text-xs mb-1">Phase name</p>
                                        <span className="mainTextClr leading-4 font-normal sm:text-lg text-base dark:text-white">
                                            {selectedPhaseDetails.phase_name || '-'}
                                        </span>
                                    </div>
                                    <div className="mb-5">
                                        <p className="text-grayTextClr leading-4 font-normal sm:text-sm text-xs mb-1">Number of Lots</p>
                                        <span className="mainTextClr leading-4 font-normal sm:text-lg text-base dark:text-white">
                                            {Number(selectedPhaseDetails.no_of_lots)?.toLocaleString() || '-'}
                                        </span>
                                    </div>
                                    <div className="mb-5">
                                        <p className="text-grayTextClr leading-4 font-normal sm:text-sm text-xs mb-1">Number of Models</p>
                                        <span className="mainTextClr leading-4 font-normal sm:text-lg text-base dark:text-white">
                                            {selectedPhaseDetails.no_of_modal || '-'}
                                        </span>
                                    </div>
                                    
                                </div>
                                <div className="sm:ml-6 pt-4 xl:pt-0">
                                    <div className="mb-5">
                                        <p className="text-grayTextClr leading-4 font-normal sm:text-sm text-xs mb-1">Project Name</p>
                                        <span className="mainTextClr leading-4 font-normal sm:text-lg text-base dark:text-white">
                                            {selectedPhaseDetails.project_name || '-'}
                                        </span>
                                    </div>
                                    <div className="mb-5">
                                        <p className="text-grayTextClr leading-4 font-normal sm:text-sm text-xs mb-1">Operating Name</p>
                                        <span className="mainTextClr leading-4 font-normal sm:text-lg text-base dark:text-white">
                                            {selectedPhaseDetails.operating_name || '-'}
                                        </span>
                                    </div>
                                </div>
                                
                                <div className="sm:ml-6 pt-4 xl:pt-0 w-full">
                                        <p className="text-grayTextClr leading-4 font-normal sm:text-sm text-xs mb-1">Name of Models</p>
                                        {selectedPhaseDetails?.plan_models && (
                                            <div className="flex flex-wrap ">
                                                {selectedPhaseDetails?.plan_models?.map((models: any, index: number) => (
                                                    <span
                                                        className="bg-closebg text-xs text-grayTextClr rounded-full px-2 py-1 me-1 text-center leading-[14px] mb-1"
                                                        key={index}
                                                    >
                                                        {models}
                                                    </span>
                                                ))}
                                            </div>
                                        )}
                                        {(selectedPhaseDetails?.plan_models?.length < 1 || !selectedPhaseDetails?.plan_models) && <>-</>}
                                    </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="sm:p-6 px-3 py-4 pt-8 bg-white dark:bg-[#252525] sm:rounded-2xl rounded-lg drop-shadow-[0px_4px_25px_rgba(116,110,109,0.04)] min-h-[75vh]">
                    {isLoading && <Loader />}
                    {isLoading === false && (
                        <>
                            <div className="mb-6 flex items-center justify-between relative">
                                <h2 className="capitalize text-[24px] dark:text-white">Phase List</h2>
                                {currentUser?.user_group === 'admin' && (
                                    <div>
                                        <Link
                                            to={`${ROUTES.PHASE_ADD}?community_id=${communityData?.id}`}
                                            state={{ phaseName: null, communityName: communityData?.community_name, communityId: communityData?.id }}
                                        >
                                            <Icon
                                                nameIcon={'IoMdAddCircle'}
                                                propsIcon={{ className: 'me-2 text-base text-orangeClr dark:text-white h-5 w-full' }}
                                            />
                                        </Link>
                                    </div>
                                )}
                            </div>
                            <div className="flex justify-between xs:flex-nowrap items-center flex-wrap mb-4">
                                <div className="flex items-center sm:flex-nowrap flex-wrap">
                                    <p className="p-0 m-0 text-sm sm:w-auto w-full font-normal mr-6 text-grayTextClr dark:text-[#746E6D]">
                                        Show only:
                                    </p>
                                    <div className="bg-orange-100 sm:mt-0 mt-1 dark:bg-[#393939] rounded-md  flex p-1 justify-center text-sm items-center">
                                        <button
                                            className={`text-sm font-normal leading-0 rounded-md  xxl:w-[134px] lg:w-[115px] w-[80px] p-1 pt-[6px] dark:text-[#746E6D] ${
                                                status === '' ? 'bg-white text-orange-400 dark:bg-[#4D4D4D] dark:!text-white ' : ''
                                            }`}
                                            onClick={() => handleStatusChange('')}
                                        >
                                            All
                                        </button>
                                        <button
                                            className={`text-sm font-normal leading-0 rounded-md  xxl:w-[134px] lg:w-[115px] w-[80px] p-1 pt-[6px] mr-1 dark:text-[#746E6D] ${
                                                status === 'ACTIVE' ? ' bg-white text-orange-400 dark:bg-[#4D4D4D] dark:!text-white ' : ''
                                            }`}
                                            onClick={() => handleStatusChange('ACTIVE')}
                                        >
                                            Active
                                        </button>
                                        <button
                                            className={`text-sm font-normal leading-0 rounded-md xxl:w-[134px] lg:w-[115px] w-[80px] p-1 pt-[6px] dark:text-[#746E6D] ${
                                                status === 'CLOSED' ? ' bg-white text-orange-400 dark:bg-[#4D4D4D] dark:!text-white ' : ''
                                            }`}
                                            onClick={() => handleStatusChange('CLOSED')}
                                        >
                                            Closed
                                        </button>
                                    </div>
                                </div>

                                <div className=" bg-[#FFEFE7] xs:mt-0 mt-2 dark:bg-[#393939] p-1 rounded-md flex cursor-pointer h-[36px]">
                                    <div
                                        className={`rounded p-1 ${toggleType === 'table' ? ' toggle-active ' : ''}`}
                                        onClick={() => setToggleType('table')}
                                    >
                                        <img src={toggleicon1} alt="toggleicon1" />
                                    </div>
                                    <div
                                        className={`rounded p-1 ${toggleType === 'card' ? ' toggle-active ' : ''}`}
                                        onClick={() => setToggleType('card')}
                                    >
                                        <img src={toggleicon2} alt="toggleicon2" />
                                    </div>
                                </div>
                            </div>
                            {toggleType === 'table' && communityDetails?.length > 0 && (
                                <DataTable
                                    className="table-detail"
                                    columns={columns}
                                    data={communityDetails || []}
                                    pagination={true}
                                    conditionalRowStyles={conditionalRowStyles}
                                    onRowClicked={(v: PhaseDetails) => handleSelectedRow(v)}
                                />
                            )}
                            {toggleType === 'table' && (!communityDetails || communityDetails.length < 1) && (
                                <div className="flex flex-wrap">
                                    <div className="box-content h-full w-full text-center p-4 border-2 dark:text-white">
                                        <div style={{ padding: '24px' }}>Not found any Phase</div>
                                    </div>
                                </div>
                            )}
                            {toggleType === 'card' && (
                                <div className="">
                                    <div className="flex flex-wrap">
                                        {currentItems?.length ? (
                                            currentItems.map((phase: PhaseDetails, index: number) => (
                                                <div
                                                    className="xl:w-1/3 sm:w-1/2 w-full md:pe-6 sm:pe-3 mb-6 cursor-pointer"
                                                    key={index}
                                                    onClick={() => setSelectedPhaseDetails(phase)}
                                                >
                                                    <div
                                                        className={`border-2 border-[#EAE9E9] dark:border-[#383838] md:rounded-lg rounded-md min-h-[175px] ${
                                                            selectedPhaseDetails?.projectId === phase?.projectId ? 'phase-card-active' : ''
                                                        }`}
                                                    >
                                                        <div className="md:px-4 px-2 py-3 flex justify-between items-center bg-[#F7F7F7] dark:bg-[#4D4D4D] border-b border-[#EAE9E9] dark:border-[#383838] rounded-t card-head">
                                                            <div className="flex">
                                                                <h4 className="sm:text-base text-sm font-normal md:me-4 me-2 dark:text-white">
                                                                    {phase?.phase_name}
                                                                </h4>
                                                                 <button
                                                                    className={`flex-none`}
                                                                >
                                                                    <img src={(phase?.status === 'ACTIVE') ? active : closed} alt="" className="h-[24px]" />
                                                                </button>
                                                            </div>
                                                            <div className="flex">
                                                                {currentUser?.user_group === 'admin' && (
                                                                    <>
                                                                        <Link
                                                                            to={`${ROUTES.PHASE_UPDATE}/${phase?.projectId}`}
                                                                            state={{
                                                                                phaseName: phase?.phase_name,
                                                                                communityName: communityData?.community_name,
                                                                                communityId: communityData?.id,
                                                                            }}
                                                                        >                                                                            
                                                                            <FaEdit size={24}  className=
                                                                                        'me-2 text-base text-orangeClr dark:text-white h-4 w-full'/>
                                                                        </Link>
                                                                        <div
                                                                            className="cursor-pointer"
                                                                            onClick={() => {
                                                                                setDeleteId(`${phase?.projectId}`);
                                                                                setIsDeleteModel(true);
                                                                            }}
                                                                        >                                                                            
                                                                            <FaTrash size={24}  className=
                                                                                'me-2 text-base text-orangeClr dark:text-white h-4 w-full'/>

                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="p-4 card-body rounded-b-lg">
                                                            <div className="flex items-center pb-3">                                                                
                                                                <TbBuildingCommunity size={20}  className='me-2 dark:text-white'/>                                                               
                                                                <h4 className="sm:text-base text-sm  mt-1 dark:text-white ">
                                                                    <label className='font-medium'>No. of Lots: </label>
                                                                    <span className="ml-2">{Number(phase?.no_of_lots)?.toLocaleString()}</span>
                                                                </h4>
                                                            </div>
                                                            {phase?.construction_start && (
                                                                <div className="flex items-center  dark:border-[#393939]">
                                                                <h4 className="xl:text-base text-sm font-normal mt-1 dark:text-white ">                                                                
                                                                    <div className='flex mb-3 text-base'>
                                                                        <MdCalendarToday size={20}  className='me-2 dark:text-white'/>                                                                  
                                                                        <label className='font-medium'>Project Start Date: </label>
                                                                        <span className="ml-2">{moment(phase?.construction_start).format('MMM Do, YYYY')}</span>
                                                                    </div>
                                                                    {phase?.construction_end && (
                                                                        <div className='flex text-base'>
                                                                            <MdCalendarToday size={20}  className='me-2 dark:text-white'/>
                                                                            <label className='font-medium'>Project End Date: </label>
                                                                            <span className="ml-2">{moment(phase?.construction_end).format('MMM Do, YYYY')}</span>                                                                            
                                                                        </div>
                                                                    )}
                                                                </h4>
                                                            </div>
                                                            
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <div className="box-content h-full w-full text-center p-4 border-2 dark:text-white">
                                                <div style={{ padding: '24px' }}>Not found any Phase</div>
                                            </div>
                                        )}
                                    </div>
                                    {communityDetails?.length > itemsPerPage && (
                                        <div className="flex justify-end">
                                            <ReactPaginate
                                                nextLabel=">"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={3}
                                                marginPagesDisplayed={2}
                                                pageCount={pageCount}
                                                previousLabel="<"
                                                pageClassName="page-item"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-item-previous"
                                                previousLinkClassName="page-link"
                                                nextClassName="page-item-next"
                                                nextLinkClassName="page-link"
                                                breakLabel="..."
                                                breakClassName="page-item"
                                                breakLinkClassName="page-link"
                                                containerClassName="pagination"
                                                activeClassName="active"
                                                renderOnZeroPageCount={null}
                                                className="flex card-pagination xl:mt-5 mt-0"
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                        </>
                    )}
                    <DeleteModal message="Deleting this phase will remove all associated lots and models. Are you sure you want to proceed?" show={isDeleteModel} handleClose={handleModalClose} />
                </div>
            </div>
        </>
    );
};

export default memo(PhaseList);
