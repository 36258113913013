import React, { useContext } from 'react';
import ReactSelect, { components } from 'react-select';
import { BasicContext } from '../../context/BasicContext';
import ErrorMsg from '../errorMsg';
import { ISelectBoxProps } from './SelectBox.interface';

const { Option } = components;

const CustomOption = ({ children, ...props }: any) => {
    const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
    const newProps = { ...props, innerProps: rest };
    return <Option {...newProps}>{children}</Option>;
};

const SelectBox = ({
    name,
    errorText,
    label,
    id,
    options,
    className,
    classNamePrefix,
    tabIndex,
    isSearchable,
    labelClassname,
    isDisabled,
    tooltip,
    value,
    onChangeFunc,
    isClearable,
    isLoading,
    placeholder,
    classComp,
    ...props
}: ISelectBoxProps) => {
    // Get the isDarkTheme value from context
    const { isDarkTheme } = useContext(BasicContext);

    // Custom styles to apply gray background when dark theme is enabled
    const customStyles = {
        control: (base: any, state: any) => ({
            ...base,
            backgroundColor: isDarkTheme ? 'transparent' : 'white', // Gray for dark theme, white for light theme
            border: isDarkTheme 
      ? '1px solid rgba(229, 231, 235, 0.5)'  // Applying opacity with rgba
      : '1px solid #e5e7eb',
            boxShadow: state.isFocused ? '0 0 0 1px rgb(255 90 31)' : 'none', // Orange shadow when focused
            '&:hover': { borderColor: '#e5e7eb !important' }, // Orange border on hover
            outline: 'none', // Remove default focus outline
            color: isDarkTheme ? 'white' : 'black', // White text in dark theme, black in light theme
        }),
        singleValue: (base: any) => ({
            ...base,
            color: isDarkTheme ? 'white' : 'black', // White text for dark theme, orange for light
        }),
        menu: (base: any) => ({
            ...base,
            backgroundColor: isDarkTheme ? '#333' : 'white', // Gray dropdown for dark theme
        }),
        option: (base: any, state: any) => ({
            ...base,
            backgroundColor: state.isSelected
            ? 'rgb(255 90 31) !important' // Orange background for selected option
            : state.isFocused
            ? isDarkTheme
                ? '#333 !important' // Dark gray for focused option in dark theme
                : 'white !important' // Light gray for focused option in light theme
            : isDarkTheme
            ? '#333 !important' // Dark gray for unselected options in dark theme
            : 'white', // White background for unselected options in light theme
        color: state.isSelected ? 'white' : isDarkTheme ? 'white' : 'black', // White text for dark theme, black for light theme
        '&:hover': {
            backgroundColor: state.isSelected
                ? 'rgb(255 90 31) !important' // Orange background for selected option on hover
                : isDarkTheme
                ? 'rgb(255 90 31) !important' // Dark gray background for unselected options on hover in dark theme
                : 'rgb(255 90 31) !important', // Light gray background for unselected options on hover in light theme
            color: 'white', // White text on hover
        },
        }),
        multiValue: (base: any) => ({
            ...base,
            backgroundColor: 'rgb(255 90 31)', // Background for multi-select values
        }),
        multiValueLabel: (base: any) => ({
            ...base,
            color: '', // Text color for multi-select values
        }),
    };

    return (
        <>
            <div>
                {label && <label className={labelClassname}>{label}</label>}
                <ReactSelect
                    value={value}
                    onChange={onChangeFunc}
                    options={options}
                    tabIndex={tabIndex}
                    classNamePrefix={classNamePrefix ? classNamePrefix : 'empire_select'}
                    isSearchable={isSearchable}
                    isClearable={isClearable}
                    isDisabled={isDisabled}
                    isLoading={isLoading}
                    styles={customStyles} // Apply the custom styles here
                    placeholder={placeholder}
                    className={`${classComp}`}
                    components={{ Option: CustomOption }}
                />
            </div>
            {errorText && <ErrorMsg errorText={errorText}></ErrorMsg>}
        </>
    );
};

SelectBox.defaultProps = {
    name: '',
    errorText: '',
    label: '',
    tooltip: '',
    className: '',
    options: '',
    id: '',
    labelClassname: '',
    classComp: '',
};

export default SelectBox;
