import React from 'react';
import { BrowserRouter, Route, Routes as ReactRoutes } from 'react-router-dom';
import { ROUTES } from '../constants/routes';

import AppLayout from '../app/AppLayout';
import Login from '../app/Login';
import PageNotFound from '../app/Errors/404';
import Dashboard from '../app/Dashboard';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import Community from '../app/Community';
import CreateCommunity from '../app/Community/create';
import Phase from '../app/Phase';
import CreatePhase from '../app/Phase/create';
import Callback from '../app/Callback';
const Routes = () => (
    <BrowserRouter>
        <ReactRoutes>
            <Route path={ROUTES.LOGIN} element={<PublicRoute component={Login} title="Login" />} />
            <Route path={ROUTES.CALLBACK} element={<PublicRoute component={Callback} title="Callback" />} />
            <Route path={ROUTES.HOME} element={<AppLayout />}>
                <Route index path={ROUTES.HOME} element={<PrivateRoute component={Dashboard} title="Dashboard" />} />
                <Route index path={ROUTES.COMMUNITY + '/:id'} element={<PrivateRoute component={Community} title="Community" />} />
                <Route index path={ROUTES.PHASE + '/:id'} element={<PrivateRoute component={Phase} title="Phase" />} />
                <Route index path={ROUTES.COMMUNITY_ADD} element={<PrivateRoute component={CreateCommunity} title="Add New Community" />} />
                <Route index path={ROUTES.COMMUNITY_ADD + '/:id'} element={<PrivateRoute component={CreateCommunity} title="Update Community" />} />
                <Route index path={ROUTES.PHASE_ADD} element={<PrivateRoute component={CreatePhase} title="Add New Phase" />} />
                <Route index path={ROUTES.PHASE_UPDATE + '/:id'} element={<PrivateRoute component={CreatePhase} title="Update Phase" />} />
                <Route path="*" element={<PageNotFound />} />
            </Route>
        </ReactRoutes>
    </BrowserRouter>
);

export default Routes;
