import { memo, useEffect, useState } from 'react';
import TopSearchBox from './component/TopSearchBar';

import PhaseDetailSideBar from './component/PhaseDetailSideBar';
import BreadCrumb from '../Phase/component/BreadCrumb';
import { GoArrowLeft } from 'react-icons/go';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { fetchPhaseDetails } from '../../services/PhaseService';
import { ROUTES } from '../../constants/routes';
import { toast } from 'react-toastify';
import PhaseDetails from './component/PhaseDetails';

const PhaseIndex = () => {
    const [phaseData, setPhaseData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const parts = pathname.split('/');
        const phaseId: any = parts[parts.length - 1];
        if (phaseId) {
            setIsLoading(true)
            getPhaseData(phaseId)
        }
    }, [pathname]);

    const getPhaseData = (phaseId: any) => {
        fetchPhaseDetails(phaseId)
            .then((response: any) => {
                if (!response?.data?.[0] || response?.data?.[0]?.length < 0) {
                    toast.error('Phase details not found');
                    navigate(ROUTES.HOME);
                }
                setPhaseData(response?.data?.[0] || []);
                setIsLoading(false)
            })
            .catch((e) => {
                console.log(e);
                setIsLoading(false)
            });
    }

    return (
        <>
            <section className="flex flex-col px-5 lg:px-[64px] min-h-[calc(100vh-104px)]">
                <BreadCrumb phaseData={phaseData} data={location?.state} />

                <TopSearchBox />
                <div className="mt-3">
                    <Link
                        to={`${ROUTES.COMMUNITY}/${phaseData?.community_id?.[0]?.uuid}`}
                        state={{ communityName: phaseData?.community_id?.[0]?.community_name }}
                    >
                        <div className="text-orangeClr text-sm font-normal flex items-center  px-1 py-[4px] focus:!shadow-none phase-btn">
                            <GoArrowLeft size="20" className="mr-[6px]" /> <p className="mt-[2px]">Back</p>
                        </div>
                    </Link>
                </div>
                <div className="flex h-full pt-2 pb-6 lg:flex-nowrap flex-wrap">
                    <div className="flex-initial flex-none bg-white lg:mb-0 mb-5 dark:bg-[#252525] sm:rounded-2xl rounded-lg drop-shadow-[0px_4px_25px_rgba(116,110,109,0.04)] xxl:w-[533px] xl:w-[400px] lg:w-[35%] w-full xl:p-6 sm:p-5 p-3">
                        {phaseData && <PhaseDetailSideBar phaseData={phaseData} isLoading={isLoading} />}
                    </div>
                    <div className="flex-1 w-full">
                        <PhaseDetails phaseData={phaseData} isLoading={isLoading} getPhaseData={getPhaseData}/>
                    </div>
                </div>
            </section>
        </>
    );
};

export default memo(PhaseIndex);
