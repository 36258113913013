import { Link } from 'react-router-dom';
import Logolight from '../../assets/img/Empire_logo.svg';
import Usericon from '../../assets/img/empire_user.svg';
import { ROUTES } from '../../constants/routes';
import { useContext, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import CommonModal from '../../components/CommonModal';
import { Dropdown } from 'flowbite-react';

import { FaRegUser } from 'react-icons/fa';
import { FaChevronDown } from 'react-icons/fa';

import modalImg from '../../assets/img/modal-img.png';
import { FiPhone } from 'react-icons/fi';
import { MdMailOutline } from 'react-icons/md';
import { IoLocationOutline, IoBagOutline } from 'react-icons/io5';

import { FaArrowRightFromBracket } from 'react-icons/fa6';
import { IoMoon, IoSunny } from 'react-icons/io5';
import { BasicContext } from '../../context/BasicContext';
import { formatPhoneNumber } from '../../utils/CommonFunctions';
import { logout } from '../../services/AuthService';

const Header = () => {
    const { currentUser, setIsAuthenticated } = useContext(AuthContext);
    const { isDarkTheme, setIsDarkTheme } = useContext(BasicContext);
    const [openProfile, setOpenProfile] = useState<boolean>(false);

    const logoutAction = () => {
        logout()
            .then((response) => (window.location.href = response.data.url))
            .catch(console.log);
        setIsAuthenticated(false);
    };

    return (
        <>
            <header className="bg-white fixed left-0 shadow right-0 top-0 z-30 dark:bg-[#252525]">
                <nav className="px-5 lg:px-[64px] py-4">
                    <div className="flex flex-wrap justify-between items-center mx-auto">
                        <Link to={ROUTES.HOME} className="flex items-center logo-dark">
                            <img src={Logolight} alt="logo-dark" className="sm:w-[142px] w-[100px]" />
                            <div></div>
                        </Link>

                        <div className="flex items-center">
                            <div
                                className=" bg-[#FFEFE7] p-1 rounded-md flex cursor-pointer sm:me-6 me-4 sm:h-auto h-[35px] dark:bg-[#444444]"
                                onClick={() => setIsDarkTheme(!isDarkTheme)}
                            >
                                <div className={`rounded sm:p-2 px-[5px] py-[6px] ${!isDarkTheme ? 'toggle-active' : ''}`}>
                                    <IoSunny className="text-[#746E6D] dark:text-[#9F9F9F]" />
                                </div>

                                <div className={`rounded sm:p-2 px-[5px] py-[6px] ${isDarkTheme ? 'toggle-active' : ''}`}>
                                    <IoMoon className="text-[#746E6D]" />
                                </div>
                            </div>

                            <Dropdown
                                label="Dropdown Label"
                                className="dark:bg-[#393939]"
                                renderTrigger={() => (
                                    <div className="flex justify-center items-center border dark:border-[#393939] active:border-clrborder focus:border-clrborder active:border-green-500 px-2 py-1 rounded-lg cursor-pointer">
                                        <img src={currentUser?.images?.[0]?.url || Usericon} alt="logo-dark" className="w-[40px] pe-2" />
                                        <p className="p-0 m-0 text-sm font-normal pt-[4px] dark:text-white sm:block hidden">
                                            {currentUser?.full_name}
                                        </p>
                                        <FaChevronDown className="sm:ms-3 sm:text-base text-xs dark:text-white" />
                                    </div>
                                )}
                            >
                                <Dropdown.Item className="dark:hover:!bg-[#4d4d4d]" onClick={() => setOpenProfile(true)}>
                                    <FaRegUser className="mr-3" /> Profile
                                </Dropdown.Item>
                                <Dropdown.Item className="dark:hover:!bg-[#4d4d4d]" onClick={logoutAction}>
                                    <FaArrowRightFromBracket className="mr-3" />
                                    Sign out
                                </Dropdown.Item>
                            </Dropdown>
                        </div>
                    </div>
                </nav>
            </header>
            {openProfile && (
                <CommonModal handleClose={() => setOpenProfile(false)} title="" size={'max-w-6xl w-[490px]'} headerClass={'text-center'}>
                    <div className="flex items-center pb-5 border-b border-[#EAE9E9] dark:border-[#393939]">
                        <div className="relative me-6">
                            <img className="w-[100px] h-[100px] object-cover object-center" src={modalImg} alt="modal-img" />
                            <div className="w-[20px] h-[20px] absolute bottom-2 right-2 bg-[#51C84E] dark:border-[#252525] border-2 border-white rounded-full"></div>
                        </div>
                        <div>
                            <h4 className="text-xl font-normal uppercase dark:text-white">{currentUser?.full_name}</h4>
                            <p className="text-sm text-[#746E6D]">{currentUser?.profession} </p>
                        </div>
                    </div>

                    <div className="py-5 border-b border-[#EAE9E9] dark:border-[#393939]">
                        {currentUser?.email && <div className="flex items-center pb-3 dark:text-white">
                            <MdMailOutline className="text-lg me-2" />
                            <p className="text-sm mt-1">{currentUser?.email}</p>
                        </div>
                        }
                        {currentUser?.mobile_no && <div className="flex items-center pb-3 dark:text-white">
                            <FiPhone className="text-lg me-2" />
                            <p className="text-sm mt-1">{formatPhoneNumber(currentUser?.mobile_no)}</p>
                        </div>
                        }
                        {currentUser?.profession && <div className="flex items-center pb-3 dark:text-white">
                            <IoBagOutline className="text-lg me-2" />
                            <p className="text-sm mt-1">{currentUser?.profession}</p>
                        </div>
                        }
                        {currentUser?.country?.name && <div className="flex items-center dark:text-white">
                            <IoLocationOutline className="text-lg me-2" />
                            <p className="text-sm mt-1">{currentUser?.country?.name}</p>
                        </div>
                        }
                    </div>

                    {currentUser?.area_of_expertise?.length > 0 && (
                        <div className="pt-5">
                            <h4 className="uppercase mb-2 text-base dark:text-white">Areas of Expertise Badges</h4>
                            <div className="flex flex-wrap">
                                {/* {currentUser?.area_of_expertise?.split(',')?.map((id: any, index: number) => (
                                    <div
                                        className="flex bg-closebg dark:bg-[#746E6D]  rounded-full p-2 me-3 justify-center items-center mb-2 "
                                        key={index}
                                    >
                                        {currentUser?.area_of_expertise_data?.filter((v: any) => Number(v.id) === Number(id))?.[0]?.icon && (
                                            <Icon
                                                nameIcon={currentUser?.area_of_expertise_data?.filter((v: any) => Number(v.id) === Number(id))?.[0]?.icon}
                                                propsIcon={{ className: 'me-2 text-base text-grayTextClr dark:text-white' }}
                                            />
                                        )}
                                        <p className="text-grayTextClr leading-[14px] text-xs dark:text-white">
                                            {currentUser?.area_of_expertise_data?.filter((v: any) => Number(v.id) === Number(id))[0]?.name}
                                        </p>
                                    </div>
                                ))} */}
                                {currentUser?.area_of_expertise?.split(',')?.map((expertise: string, index: number) => (
                                            <span
                                            className="bg-closebg dark:bg-[#746E6D] dark:text-white text-xs mb-2 text-grayTextClr rounded-full px-2 py-1 me-1 text-center leading-[14px]"
                                            key={index}
                                            >
                                            {expertise.trim()}  {/* Displaying the expertise value directly */}
                                            </span>
                                        ))}
                            </div>
                        </div>
                    )}
                </CommonModal>
            )}
        </>
    );
};

export default Header;
