import { memo } from 'react';
import { GoArrowLeft } from 'react-icons/go';
import { useNavigate, useLocation } from 'react-router-dom';
import TopSearchBox from './component/TopSearchBar';
import CommunitySideBar from './component/CommunitySideBar';
import PhaseList from './component/PhaseList';
import BreadCrumb from './component/BreadCrumb';
import Button from '../../components/Button';
import { CommunityProvider } from '../../context/CommunityContext';
import { ROUTES } from '../../constants/routes';

const Community = () => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <>
            <CommunityProvider>
                <section className="content flex flex-col px-5 xl:px-[64px] lg:px-[34px] min-h-[calc(100vh-104px)]">
                    <BreadCrumb className="" data={location?.state} />
                    <TopSearchBox />
                    <div className="mt-3">
                        <Button className="text-orangeClr font-normal flex items-center  px-1 py-[4px]" onClick={() => navigate(ROUTES.HOME)}>
                            <GoArrowLeft className="mr-[6px] sm:text-xl text-lg" /> <p className="mt-1 text-sm">Back</p>
                        </Button>
                    </div>
                    <div className="flex pt-2 pb-6 lg:flex-nowrap flex-wrap">
                        <CommunitySideBar />
                        <PhaseList />
                    </div>
                </section>
            </CommunityProvider>
        </>
    );
};

export default memo(Community);
