import { memo } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../constants/routes';

const BreadCrumb = (props: any) => {
    return (
        <>
            <div className="flex text-sm leading-5">
                <Link className="text-grayTextClr" to={ROUTES?.HOME}>
                    <span className="mr-2 text-grayTextClr">Home</span>
                </Link>
                <span className="mr-2 text-grayTextClr">/</span>
                <Link
                    className="text-grayTextClr"
                    to={`${ROUTES?.COMMUNITY}/${props?.phaseData?.community_id?.[0]?.uuid}`}
                    state={{ communityName: props?.phaseData?.community_id?.[0]?.community_name }}
                >
                    <span className="mr-2 text-grayTextClr">{props?.data?.communityName || props?.phaseData?.community_id?.[0]?.community_name}</span>
                </Link>
                <span className="mr-2 text-grayTextClr">/</span>
                <Link className="text-orangeClr" to={''}>
                    <span className="mr-2 text-orangeClr">{props?.data?.phaseName || props?.phaseData?.phase_name}</span>
                </Link>
            </div>
        </>
    );
};

export default memo(BreadCrumb);
