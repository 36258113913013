import { memo, useContext } from 'react';
import no_img from '../../../assets/img/no-image.svg';
import Loader from '../../../components/Loader';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../constants/routes';
import { Icon } from '../../../components/Icon';
import { AuthContext } from '../../../context/AuthContext';
import active from '../../../assets/img/active.svg';
import closed from '../../../assets/img/closed.svg';

const PhaseDetailSideBar = (props: any) => {
    const { phaseData, isLoading } = props;
    const { currentUser } = useContext(AuthContext);
    return (
        <>
            <div className={'content h-full min-h-[75vh]'}>
                {isLoading && <Loader />}
                {isLoading === false && (
                    <>
                        <div className="flex justify-between items-center xl:mb-5 mb-3">
                            <div className="flex">
                                <h2 className="capitalize sm:text-[24px] text-xl dark:text-[white]">{phaseData?.phase_name}</h2>
                                 <button className={`ml-2 flex-none`} >
                                    <img src={(phaseData?.status === 'ACTIVE') ? active : closed} alt="" className="h-[24px]" />
                                </button>
                            </div>

                            <div>
                                {currentUser?.user_group === 'admin' && (
                                    <Link
                                        to={`${ROUTES.PHASE_UPDATE}/${phaseData?.projectId}`}
                                        state={{
                                            phaseName: phaseData?.phase_name,
                                            communityName: phaseData?.community_id?.community_name,
                                            communityId: phaseData?.community_id?.id,
                                        }}
                                    >
                                        <Icon nameIcon={'FaEdit'} propsIcon={{ className: 'text-base text-orangeClr dark:text-white h-4 w-full' }} />
                                    </Link>
                                )}
                            </div>
                        </div>

                        <div className="xl:rounded-2xl rounded-lg overflow-hidden xl:h-[288px] h-[220px] mb-6">
                            <img src={phaseData?.images?.[0]?.url || no_img} onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = no_img;
                            }} alt="img1" className="w-full h-full object-cover" />
                        </div>

                        <div className="max-h-[386px] overflow-y-auto">
                            <div className="flex xl:flex-nowrap flex-wrap">
                                <div className="xl:w-1/2 w-full">
                                    <div className="sm:mb-6 mb-4">
                                        <p className="sm:text-sm text-xs text-[#746E6D] leading-[16px] mb-2">Number of Lots</p>
                                        <p className="leading-[16px] sm:text-lg text-base dark:text-white">{phaseData?.no_of_lots}</p>
                                    </div>

                                    <div className="sm:mb-6 mb-4">
                                        <p className="sm:text-sm text-xs text-[#746E6D] leading-[16px] mb-2">Available Lots</p>
                                        <p className="leading-[16px] sm:text-lg text-base dark:text-white">{phaseData?.available_lots}</p>
                                    </div>
                                    <div className="sm:mb-6 mb-4">
                                        <p className="sm:text-sm text-xs text-[#746E6D] leading-[16px] mb-2">Base avg Price</p>
                                        <p className="leading-[16px] sm:text-lg text-base dark:text-white">${phaseData?.base_price}</p>
                                    </div>
                                    <div className="sm:mb-6 mb-4">
                                        <p className="sm:text-sm text-xs text-[#746E6D] leading-[16px] mb-2">Project Name</p>
                                        <p className="leading-[16px] sm:text-lg text-base dark:text-white">{phaseData?.project_name || '-'}</p>
                                    </div>
                                    <div className="sm:mb-6 mb-4">
                                        <p className="sm:text-sm text-xs text-[#746E6D] leading-[16px] mb-2">Name of Models</p>
                                        <div className="flex flex-wrap">
                                            {phaseData?.plan_models?.map((model: any, index: number) => (
                                                <span
                                                    className="bg-closebg mb-2 dark:bg-[#746E6D] dark:text-white text-xs text-grayTextClr rounded-full px-2 py-1 me-1 text-center leading-[14px]"
                                                    key={index}
                                                >
                                                    {model?.name}
                                                </span>
                                            ))}
                                        </div>
                                    </div>

                                    
                                </div>

                                <div className="xl:w-1/2 w-full">
                                    <div className="sm:mb-6 mb-4">
                                        <p className="sm:text-sm text-xs text-[#746E6D] leading-[16px] mb-2">Sold Lots</p>
                                        <p className="leading-[16px] sm:text-lg text-base dark:text-white">{phaseData?.sold_lots}</p>
                                    </div>
                                    <div className="sm:mb-6 mb-4">
                                        <p className="sm:text-sm text-xs text-[#746E6D] leading-[16px] mb-2">Closed Lots</p>
                                        <p className="leading-[16px] sm:text-lg text-base dark:text-white">{phaseData?.closed_lots}</p>
                                    </div>
                                    <div className="sm:mb-6 mb-4">
                                        <p className="sm:text-sm text-xs text-[#746E6D] leading-[16px] mb-2">Phase Sq. Footage</p>
                                        <p className="leading-[16px] sm:text-lg text-base dark:text-white">{phaseData?.phase_sq_footage}</p>
                                    </div>
                                    <div className="sm:mb-6 mb-4">
                                        <p className="sm:text-sm text-xs text-[#746E6D] leading-[16px] mb-2">Operating Name</p>
                                        <p className="leading-[16px] sm:text-lg text-base dark:text-white">{phaseData?.operating_name || '-'}</p>
                                    </div>
                                    <div className="sm:mb-6 mb-4">
                                        <p className="sm:text-sm text-xs text-[#746E6D] leading-[16px] mb-2">Number of Models</p>
                                        <p className="leading-[16px] sm:text-lg text-base dark:text-white">{phaseData?.no_of_modal}</p>
                                    </div>
                                </div>
                            </div>

                            <div></div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default memo(PhaseDetailSideBar);
