import React from 'react';

import Routes from './routes';

import './assets/css/app.css';
import './assets/scss/app.scss';

import 'react-toastify/dist/ReactToastify.css';

import { AuthContext, AuthProvider } from './context/AuthContext';

import Loader from './components/Loader';
import { BasicProvider } from './context/BasicContext';

function App() {
    return (
        <AuthProvider>
            <BasicProvider>
                <AuthContext.Consumer>{({ loader }) => (loader ? <Loader /> : <Routes />)}</AuthContext.Consumer>
            </BasicProvider>
        </AuthProvider>
    );
}

export default App;
