import { useContext, useEffect, useState, ChangeEvent, FormEvent } from 'react';
import Button from '../../components/Button';
import { AxiosError, AxiosResponse } from 'axios';
import InputText from '../../components/InputText/InputText';
import { Radio } from 'flowbite-react';
import SelectBox from '../../components/SelectBox/SelectBox';
import DateSelect from '../../components/DateSelect/DateSelect';
import { DATE_FORMAT } from '../../constants/common';
import moment from 'moment';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { GoArrowLeft } from 'react-icons/go';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import { fetchCity, fetchCountry, fetchState } from '../../services/LocationService';
import { fetchProductInformation } from '../../services/CommonService';
import Breadcrumbs from '../../components/Breadcrumbs';
import { communityDetails, createCommunity, updateCommunity } from '../../services/CommunityService';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { AuthContext } from '../../context/AuthContext';
import no_image from '../../assets/img/no-image.svg';
import CreatableDropdown from '../../components/CreatableDropdown/CreatableDropdown';
interface ValidForm {
    [key: string]: boolean;
  }
interface ErrorMsg {
    [key: string]: string;
}
interface CSC{
    id?: number;
    value: string;
    label: string;
}
interface ProductInfo{
    value: number;
    label: string;
}
interface FormData{
    id?: string;
    community_name: string,
    legal_name: string,
    status: string,
    division: string,    
    marketing_name: string,    
    country: string | null,
    state: string | null,
    city: string | null,
    acreage: string,
    address: string,
    latitude: string,
    longitude: string,
    acquisition_date: string | null,
    project_start_date: string | null,
    product_information: string,
    image: any,
    new_product_information?: number[];
}
interface ExtendedProductInfo extends ProductInfo {
    __isNew__?: boolean;
}
interface ResponseCSC{
    code: string;
    id: number;
    iso3: string;
    name: string;
}
interface ProductInfoApiResponse {
    product_information: {
        id: number;
        name: string;
        // Include other fields if necessary
    }[];
}
interface ErrorMessage{
    message: string;
}
const breadCrumbData = [
    { name: 'Home', link: ROUTES?.HOME },
    { name: 'Community', link: ROUTES?.HOME },
    { name: 'Create', link: '' },
];
const initFormData : FormData = {
    community_name: '',
    legal_name: '',
    status: '',
    division: '',    
    marketing_name: '',    
    country: null,
    state: null,
    city: null,
    acreage: '',
    address: '',
    latitude: '',
    longitude: '',
    acquisition_date: '',
    project_start_date: '',
    product_information: '',
    image: '',
};
const initValidForm: ValidForm = {};
const errorMessage : ErrorMsg = {};
Object.keys(initFormData).forEach((key) => {
    initValidForm[key] = true;
});
Object.keys(initFormData).forEach((key) => {
    errorMessage[key] = '';
});

const requiredFields = [
    'community_name',
    'legal_name',
    'status',
    'division',    
    'marketing_name',    
    'country',
    'state',
    'city',
    'acreage',
    'address',
    'latitude',
    'longitude',
    'image',
];
const CreatePhase = () => {
    const { currentUser } = useContext(AuthContext);
    const [formData, setFormData] = useState<FormData>(initFormData);
    const [validForm, setValidForm] = useState<ValidForm>(initValidForm);
    const [errorMsg, setErrorMsg] = useState<ErrorMsg>(errorMessage);
    const [countryData, setCountryData] = useState<CSC[]>([]);
    const [productInfoData, setProductInfoData] = useState<ProductInfo[]>([]);
    const [stateData, setStateData] = useState<CSC[]>([]);
    const [cityData, setCityData] = useState<CSC[]>([]);
    const [communityId, setCommunityId] = useState<string | null>(null);
    const [product_information, setProductInformation] = useState<ProductInfo[]>([]);
    const [isFormSubmitLoading, setFormSubmitLoading] = useState<boolean>(false);
    const [communityImage, setCommunityImage] = useState<string>('');
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const location = useLocation();
    // eslint-disable-next-line no-useless-escape
    const isNumberValue = /^[\-\+]?[\d]+\.?(\d+)?$/;

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleChangeRadio = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSelectDate = (e: any) => {
        console.log("date.........",e);
       
        const { name, value } = e; 
        if (value !== null) {
            const momentDate = moment(value).format('YYYY-MM-DD');
            setFormData((old) => ({ ...old, [name]: momentDate }));
        } else {
            setFormData((old) => ({ ...old, [name]: '' }));
        }
        
    };    
    useEffect(() => {
        if (currentUser?.user_group !== 'admin') {
            navigate(`${ROUTES.HOME}`);
        }
        const parts = pathname.split('/');
        setCommunityId(parts?.[2] || null);
        fetchCountry()
            .then((response: AxiosResponse<ResponseCSC[]>) => {                
                const country = response?.data?.map((v: ResponseCSC) => {
                    return { label: v.name, value: v.code, id: v.id };
                });
                setCountryData(country || []);
            })
            .catch((e) => {
                console.log(e);
            });
        getAndSetProductInfo();
    }, []);

    const getAndSetProductInfo = (selected: number[] = []) => {
        fetchProductInformation()
            .then((response: AxiosResponse<ProductInfoApiResponse>) => {                
                const product_information = response?.data?.product_information?.map((v) => {
                    return { label: v.name, value: v.id };
                });
                setProductInfoData(product_information);
                if (selected?.length > 0) {
                    const productInfo = product_information?.filter((v)=> selected?.includes(v?.value));                                       
                    setProductInformation(productInfo);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleChangeCountry = async (event: any) => {
        const selectedCountry = event?.value;
        if (formData.country === selectedCountry) {            
            return; // Exit the function if the same country is selected
        }
        
        setFormData({ ...formData, country: event?.value ?? null, state: null , city: null });
        
        setStateData([]);
        setCityData([]);
    };
    const handleChangeState = async (event: any) => {
        const selectedState = event?.value;
        if (formData.state === selectedState) {            
            return; // Exit the function if the same country is selected
        }
        setFormData({ ...formData, state: event?.value ?? null });
        setFormData({ ...formData, state: event?.value ?? null, city: '' });
        setCityData([]);
    };    
    useEffect(() => {
        fetchState(formData?.country)
            .then((response: AxiosResponse<ResponseCSC[]>) => {
                const state = response?.data?.map((v: ResponseCSC) => {
                    return { label: v.name, value: v.code, id: v.id };
                });
                setStateData(state || []);
            })
            .catch((e) => {
                console.log(e);
            });
    }, [formData?.country]);
    useEffect(() => {
        if (formData?.state === null || formData?.state === '') {
            return;
        }
        fetchCity(formData?.state)
            .then((response: AxiosResponse<ResponseCSC[]>) => {
                const city = response?.data?.map((v: ResponseCSC) => {
                    return { label: v.name, value: v.id.toString() };
                });
                setCityData(city || []);
            })
            .catch((e) => {
                console.log(e);
            });
    }, [formData?.state]);

    useEffect(() => {
        breadCrumbData[2].name = 'Create';
        if (communityId !== null) {
            breadCrumbData[2].name = location?.state?.communityName || 'Update Community';
            communityDetails(communityId)
                .then((response: any) => {                  
                    
                    const communityData = {
                        ...formData,
                        ...response?.data?.[0],
                        address: response?.data?.[0]?.address || '',
                        city: response?.data?.[0]?.city?.id,
                        state: response?.data?.[0]?.state?.code,
                        country: response?.data?.[0]?.country?.code,
                        image: response?.data?.[0]?.image,
                    };
                    setFormData(communityData);
                    
                    // Parse the comma-separated product information string into an array
                    const selectedProductInfo = communityData?.product_info?.map((v : {id: number})  => v.id);

                    getAndSetProductInfo(selectedProductInfo);
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }, [communityId]);

    const valid = () => {
        let valid = true;
        let checkValidForm = validForm;   
        let tempErrorMsg = { ...errorMsg };    
        // eslint-disable-next-line array-callback-return
        Object.keys(validForm).map((v) => {
            if (requiredFields?.includes(v)) {
                if (communityId && v !== 'image' && (formData?.[v as keyof FormData] === undefined || formData?.[v as keyof FormData] === '' || formData?.[v as keyof FormData] === null) ){
                    checkValidForm = { ...checkValidForm, [v]: false };     
                    valid = false;                   
                }
                else if (!communityId  && (formData?.[v as keyof FormData] === undefined || formData?.[v as keyof FormData] === '' || formData?.[v as keyof FormData] === null) ){
                    checkValidForm = { ...checkValidForm, [v]: false };                 
                    valid = false;
                   
                }else if (v === 'latitude' && formData?.[v as keyof FormData] !== '' && !isNumberValue?.test(formData?.[v as keyof FormData] as string)) {
                    checkValidForm = { ...checkValidForm, [v]: false };
                    tempErrorMsg = { ...tempErrorMsg, [v]: 'Latitude value should be a number.' };
                    valid = false;
                } else if (v === 'longitude' && formData?.[v as keyof FormData] !== '' && !isNumberValue?.test(formData?.[v as keyof FormData] as string)) {
                    checkValidForm = { ...checkValidForm, [v]: false };
                    tempErrorMsg = { ...tempErrorMsg, [v]: 'Longitude value should be a number.' };
                    valid = false;
                } else {
                    checkValidForm = { ...checkValidForm, [v]: true };
                    setErrorMsg({ ...errorMsg, [v]: '' });
                }
            }
        });
        setValidForm({ ...validForm, ...checkValidForm });
        setErrorMsg(tempErrorMsg);
        return valid;
    };  
    
    
    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!valid()) {
            return true;
        }
        setFormSubmitLoading(true);
        const formDataToSend = new FormData();

        const postData = _.pick(formData, _.keys(initFormData));         
               
        postData.product_information = product_information.filter((v: ExtendedProductInfo) => !v.__isNew__).map((v: ExtendedProductInfo) => v.value)?.join(',');            
        postData.new_product_information = product_information.filter((v: ExtendedProductInfo) => v.__isNew__).map((v: ExtendedProductInfo) => v.value);
        // Find the country name based on the selected value
        const selectedCountry = countryData.find((v: CSC) => v.value === formData.country);
        postData.country = selectedCountry ? selectedCountry.label : formData.country;

        // Find the state name based on the selected value
        const selectedState = stateData.find((v: CSC) => v.value === formData.state);
        postData.state = selectedState ? selectedState.label : formData.state;

        // Find the city name based on the selected value
        const selectedCity = cityData.find((v: CSC) => v.value === formData.city?.toString());
        postData.city = selectedCity ? selectedCity.label : formData.city; 
        
        // Object.keys(postData).forEach((key) => {
        //     formDataToSend.append(key, postData[key as keyof FormData]);
        // });
        Object.entries(postData).forEach(([key, value] :any) => {
            if (key !== 'image') {
                if (typeof value === 'string' || value instanceof Blob) {
                    formDataToSend.append(key, value);
                } else if (Array.isArray(value)) {
                    // Handle the array case if necessary, e.g., convert to a JSON string
                    formDataToSend.append(key, JSON.stringify(value));
                } else if (value !== null) {
                    // Handle other cases, such as numbers, booleans, etc.
                    formDataToSend.append(key, String(value));
                }
            }
        });
        if(postData.image instanceof File){
            formDataToSend.append(`image`, postData.image);
        }            
        
        if (communityId) {
            updateCommunity(communityId,formDataToSend)
                .then((result: AxiosResponse<FormData>) => {                    
                    setFormSubmitLoading(false);
                    toast.success('Successfully updated details');
                })
                .catch((error: AxiosError<ErrorMessage>) => {
                    setFormSubmitLoading(false);
                    const errMsg = error?.response?.status === 409 ? error?.response?.data?.message : 'Something went wrong! Please try again';
                    toast.error(errMsg);
                });
        } else {
            createCommunity(formDataToSend)
                .then((result: AxiosResponse<FormData>) => {
                    if (result) {                        
                        setFormSubmitLoading(false);
                        toast.success('Successfully created community');
                        navigate(`${ROUTES.COMMUNITY_ADD}/${result.data.id}`);
                    }
                })
                .catch((error: AxiosError<ErrorMessage>) => {
                    setFormSubmitLoading(false);
                    const errMsg = error?.response?.status === 409 ? error?.response?.data?.message : 'Something went wrong! Please try again';
                    toast.error(errMsg);                   
                });
        }
    };

    const handleProductChange = (selected: ProductInfo[]) => {
        
        
        setProductInformation(selected);
        setFormData({ ...formData, product_information: selected.toString() });        
        
    };
    const handleChangeImage = (event: any) => {
        const file = event.target.files[0]; // Get the first selected file
        if (file) {            
            const reader = new FileReader();
            reader.onloadend = () => {
                setCommunityImage(reader.result as string); // Set the Data URL for preview
            };
            reader.readAsDataURL(file);            
            setCommunityImage(event.target.result)            
                setFormData((prevData: any) => ({
                    ...prevData,
                    image: file
                }));
            }
    else {
            console.error('No file selected');
        }
    };
    return (
        <>
            <section className="content flex flex-col px-5 xl:px-[64px] lg:px-[34px] min-h-[calc(100vh-104px)]">
                <Breadcrumbs className={''} data={breadCrumbData}></Breadcrumbs>
                <div className="w-full pt-5">
                    <div className="flex">
                        <div className="w-[5%]">
                            <Button className="text-orangeClr font-normal flex items-center  px-1 py-[4px]" onClick={() => navigate(ROUTES.HOME)}>
                                <GoArrowLeft className="mr-[6px] sm:text-xl text-lg" /> <p className="mt-1 text-sm">Back</p>
                            </Button>
                        </div>
                    </div>
                </div>

                <div className="mb-4 bg-white sm:rounded-2xl rounded-lg drop-shadow-[0px_4px_25px_rgba(116,110,109,0.04)] sm:p-6 px-3 py-4 dark:bg-[#252525]">
                    <form className="w-full" onSubmit={handleSubmit}>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <InputText
                                    className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-transparent dark:text-[#746E6D]  dark:border-opacity-50"
                                    inputName="community_name"
                                    label="Community Name"
                                    requiredLabel={true}
                                    placeholder="Enter Community Name"
                                    value={formData?.community_name}
                                    onChangeFunc={handleInputChange}
                                    labelClassName="dark:text-[#746E6D]"
                                    errorText={!validForm.community_name ? 'Name is required' : null}
                                />
                            </div>
                            <div className="w-full md:w-1/2 px-3">
                                <InputText
                                    requiredLabel={true}
                                    className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-transparent dark:text-[#746E6D]  dark:border-opacity-50"
                                    inputName="legal_name"
                                    label="Legal Name"
                                    placeholder="Enter Legal Name"
                                    value={formData?.legal_name}
                                    onChangeFunc={handleInputChange}
                                    labelClassName="dark:text-[#746E6D]"
                                    errorText={!validForm.legal_name ? 'Legal Name is required' : null}
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <InputText
                                    requiredLabel={true}
                                    className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-transparent dark:text-[#746E6D]  dark:border-opacity-50"
                                    inputName="division"
                                    label="Division"
                                    placeholder="Enter Division"
                                    value={formData?.division}
                                    onChangeFunc={handleInputChange}
                                    labelClassName="dark:text-[#746E6D]"
                                    errorText={!validForm.division ? 'Division is required' : null}
                                />
                            </div>
                            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <InputText
                                    requiredLabel={true}
                                    className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-transparent dark:text-[#746E6D]  dark:border-opacity-50"
                                    inputName="marketing_name"
                                    label="Marketing Name"
                                    placeholder="Enter Marketing Name"
                                    value={formData?.marketing_name}
                                    onChangeFunc={handleInputChange}
                                    labelClassName="dark:text-[#746E6D]"
                                    errorText={!validForm.marketing_name ? 'Marketing Name is required' : null}
                                />
                            </div>                            
                        </div>                        
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label className="form_label dark:text-[#746E6D]">Status</label>
                                <span className="text-red-600 ms-[2px]">*</span>
                                <span className="flex">
                                    <label className="form_label mr-3 pl-2" htmlFor={'active'}>
                                        <Radio
                                            id={`active`}
                                            className="w-5 h-5 checked:bg-orange-600 dark:checked:bg-orange-600 bg-white border-1 cursor-pointer focus:ring-2 focus:ring-orange-600 focus:outline-none"
                                            onChange={handleChangeRadio}
                                            name={'status'}
                                            value={'ACTIVE'}
                                            checked={formData?.status === 'ACTIVE'}
                                        />
                                        <span className="form_label mr-3 pl-2 dark:text-[#746E6D]">ACTIVE</span>
                                    </label>
                                    <label className="form_label mr-2 pl-2" htmlFor={'closed'}>
                                        <Radio
                                            id={`closed`}
                                            className="w-5 h-5 checked:bg-orange-600 dark:checked:bg-orange-600 bg-white border-1 cursor-pointer focus:ring-2 focus:ring-orange-600 focus:outline-none"
                                            onChange={handleChangeRadio}
                                            name={'status'}
                                            value={'CLOSED'}
                                            checked={formData?.status === 'CLOSED'}
                                        />
                                        <span className="form_label mr-2 pl-2 dark:text-[#746E6D]">CLOSED</span>
                                    </label>
                                </span>
                                {!validForm.status && <p className="text-sm text-red-600 dark:text-red-500">Status is required</p>}
                            </div>
                            <div className="w-full md:w-1/2 px-3">
                                <InputText
                                    requiredLabel={true}
                                    className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-transparent dark:text-[#746E6D]  dark:border-opacity-50"
                                    inputName="acreage"
                                    label="Acreage"
                                    placeholder="Enter Acreage"
                                    value={formData?.acreage}
                                    onChangeFunc={handleInputChange}
                                    labelClassName="dark:text-[#746E6D]"
                                    errorText={!validForm.acreage ? 'Acreage is required' : null}
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="flex flex-col w-full md:w-1/2 md:mb-0">
                                <div className={`form_label dark:text-[#746E6D] h-[25px] px-3`}>Select location<span className='text-lg text-red-600 ms-[2px]'>*</span></div>
                                <div className="grid md:grid-cols-2 grid-cols-1 w-[100%] px-3 mb-6 md:mb-0 gap-3">
                                    <div className="w-[100%]">
                                        <SelectBox
                                            name="Country"
                                            id="country"
                                            placeholder="Select Country"
                                            className="w-5"
                                            options={countryData}
                                            onChangeFunc={handleChangeCountry}
                                            value={countryData?.find((val: CSC) => val.value === formData?.country)}
                                            errorText={!validForm.country ? 'Country is required' : null}
                                        />
                                    </div>
                                    <div className="w-[100%]">
                                        <SelectBox
                                            name="State"
                                            id="state"
                                            className="w-5"
                                            placeholder="Select State"
                                            options={stateData}
                                            onChangeFunc={handleChangeState}
                                            value={formData?.state ? stateData?.find((option: CSC) => option.value === formData?.state) : null}
                                            errorText={!validForm.state ? 'State is required' : null}
                                        />
                                    </div>
                                    <div className="w-[100%]">
                                        <SelectBox
                                            name="City"
                                            id="city"
                                            placeholder="Select City"
                                            className="w-5"
                                            options={cityData}
                                            onChangeFunc={(event: any) => setFormData({ ...formData, city: event?.value })}
                                            value={formData?.city ? cityData?.find((option: CSC) => option.value === formData?.city?.toString()) : null}
                                            errorText={!validForm.city ? 'City is required' : null}
                                        />
                                    </div>
                                </div>
                            </div>                            
                            <div className="w-full md:w-1/2 px-3">
                                <InputText
                                    requiredLabel={true}
                                    asTextarea={'textarea'}
                                    rows={4}
                                    inputName="address"
                                    label="Address"
                                    className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-transparent dark:text-[#746E6D]  dark:border-opacity-50"
                                    placeholder="Enter Community Address."
                                    value={formData?.address}
                                    onChangeFunc={handleInputChange}
                                    labelClassName="dark:text-[#746E6D]"
                                    errorText={!validForm.address ? 'Address is required' : null}
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="grid gap-x-8 gap-y-4 grid-cols-2 w-full md:w-1/2 px-3 mb-6 md:mb-0 gap-3">
                                <div className="w-[100%]">
                                    <InputText
                                        requiredLabel={true}
                                        className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-transparent dark:text-[#746E6D]  dark:border-opacity-50"
                                        inputName="latitude"
                                        label="Latitude"
                                        placeholder="Enter latitude"
                                        value={formData?.latitude}
                                        onChangeFunc={handleInputChange}
                                        labelClassName="dark:text-[#746E6D]"
                                        errorText={!validForm.latitude ? errorMsg?.latitude || 'Latitude is required' : null}
                                    />
                                </div>
                                <div className="w-[100%]">
                                    <InputText
                                        requiredLabel={true}
                                        className="appearance-none  block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-transparent dark:text-[#746E6D]  dark:border-opacity-50"
                                        inputName="longitude"
                                        label="Longitude"
                                        placeholder="Enter longitude"
                                        value={formData?.longitude}
                                        onChangeFunc={handleInputChange}
                                        labelClassName="dark:text-[#746E6D]"
                                        errorText={!validForm.longitude ? errorMsg?.longitude || 'Longitude is required' : null}
                                    />
                                </div>
                            </div>
                            <div className="w-full md:w-1/2 px-3">
                                <label className="form_label mr-2 pl-2 dark:text-[#746E6D]">Product Information</label>
                                <CreatableDropdown
                                    key="product_information"
                                    options={productInfoData}
                                    onChange={handleProductChange}
                                    value={product_information}
                                    className="w-full"
                                    classNamePrefix="dropdown"
                                    isSelectAll={true}
                                    menuPlacement={'bottom'}
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <DateSelect
                                    inputName="project_start_date"
                                    className="placeholder-[#746E6D] dark:bg-transparent dark:text-[#e2d7d6]  dark:border-opacity-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white w-full"
                                    label="Project Start Date"
                                    placeholder={DATE_FORMAT}
                                    dateFormat="dd/MM/yyyy"
                                    selected={formData?.project_start_date ? moment(formData?.project_start_date).toDate() : null}
                                    errorText={''}
                                    onChangeFunc={handleSelectDate}
                                    labelClassName="dark:text-[#746E6D]"
                                    icon={<FaRegCalendarAlt color="#746E6D"/>}
                                />
                            </div>
                            <div className="w-full md:w-1/2 px-3">
                                <DateSelect
                                    inputName="acquisition_date"
                                    className="placeholder-[#746E6D] dark:bg-transparent dark:text-[#e2d7d6]  dark:border-opacity-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white w-full"
                                    label="Acquisition Date"
                                    placeholder={DATE_FORMAT}
                                    dateFormat="dd/MM/yyyy"
                                    selected={formData?.acquisition_date ? moment(formData?.acquisition_date).toDate() : null}
                                    errorText={''}
                                    onChangeFunc={handleSelectDate}
                                    labelClassName="dark:text-[#746E6D]"
                                    icon={<FaRegCalendarAlt color="#746E6D"/>}
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full md:w-1/2 px-3">
                                <label className="form_label mb-1 dark:text-[#746E6D]">Image<span className='text-lg text-red-600 ms-[2px]'> *</span></label>                                
                                <div className="w-full">
                                <input type="file"
                                 className='placeholder-[#746E6D] dark:bg-transparent dark:text-[#746E6D]  dark:border-opacity-50 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white w-full' 
                                 onChange={handleChangeImage}
                                 id = 'fileUpload'
                                 />
                                <p className='text-sm text-red-600 dark:text-red-500 ms-[2px]'>{!validForm.image ? 'Image is required' : null}</p>
                                </div>
                            </div>
                            
                            <div className="w-full md:w-1/2 px-3">
                                <div className="overflow-hidden h-[150px] text-orangeClr">
                                    <img
                                        src={communityImage || formData?.image}
                                        alt=""
                                        className="h-[100%]"
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = no_image;
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap mt-10 justify-center">
                            <Button
                                disabled={isFormSubmitLoading}
                                type="submit"
                                className={`text-orange-500 border border-orange-500 flex text-center items-center justify-center py-[6px] px-16 rounded-md sm:rounded-lg  ${isFormSubmitLoading ? ' opacity-60' : ''
                                    }`}
                            >
                                {isFormSubmitLoading && (
                                    <svg
                                        aria-hidden="true"
                                        className="inline w-4 h-6 text-gray-200 me-2 animate-spin dark:text-gray-600 fill-[#FA5A0A]"
                                        viewBox="0 0 100 101"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="currentColor"
                                        />
                                        <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentFill"
                                        />
                                    </svg>
                                )}
                                {!isFormSubmitLoading && (
                                    <>
                                        {communityId && 'Update'}
                                        {!communityId && 'Create'}
                                    </>
                                )}
                            </Button>
                        </div>
                    </form>
                </div>
            </section>
        </>
    );
};

export default CreatePhase;
