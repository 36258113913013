import { memo, useState } from 'react';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import no_img from '../../../src/assets/img/no-image.svg';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

// import Swiper core and required modules
import SwiperCore from 'swiper';
import { Navigation, Thumbs } from 'swiper/modules';
import './slider.scss';

SwiperCore.use([Navigation, Thumbs]);

const PhaseSlider = (props: any) => {
    const [thumbsSwiper, setThumbsSwiper] = useState<any>();
    if (props?.images?.length > 0) {
        return (
            <>
                <Swiper
                    style={{ '--swiper-navigation-color': '#fff', '--swiper-pagination-color': '#fff' } as any}
                    spaceBetween={10}
                    navigation={true}
                    thumbs={{ swiper: thumbsSwiper }}
                    className={'mySwiper2 ' + props?.sliderClass}
                >
                    {props?.images?.map((img: any, index: number) => (
                        <SwiperSlide key={index}>
                            <img
                                src={img?.url || no_img}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = no_img;
                                }}
                                onClick={() => {
                                    if (props?.setIsOpen) {
                                        props?.setIsOpen(true);
                                    }
                                }}
                                alt=""
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
                <Swiper
                    onSwiper={setThumbsSwiper}
                    spaceBetween={10}
                    slidesPerView={3}
                    freeMode={true}
                    watchSlidesProgress={true}
                    className={'mySwiper lot-swiper-inner ' + props?.sliderClass}
                    breakpoints={{
                        480: {
                            slidesPerView: 4,
                        },
                    }}
                >
                    {props?.images?.map((img: any, index: number) => (
                        <SwiperSlide key={index}>
                            <div className="lot-swiper-img cursor-pointer">
                                <img  src={img?.url || no_img} alt="" onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = no_img;
                                }}/>
                                <div className="absolute top-[2px] right-[1px] bg-black text-white leading-3 text-xs px-1 py-[2px] rounded-l-full">
                                    floor plan
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </>
        );
    } else {
        return (
            <>
                <Swiper
                    style={{ '--swiper-navigation-color': '#fff', '--swiper-pagination-color': '#fff' } as any}
                    spaceBetween={10}
                    navigation={true}
                    thumbs={{ swiper: thumbsSwiper }}
                    className={'mySwiper2 ' + props?.sliderClass}
                >
                        <SwiperSlide style={{backgroundColor: 'transparent'}}>
                            <img
                                src={ no_img}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = no_img;
                                }}
                                onClick={() => {
                                    if (props?.setIsOpen) {
                                        props?.setIsOpen(true);
                                    }
                                }}
                                alt=""
                            />
                        </SwiperSlide>
                   
                </Swiper>
                <Swiper
                    onSwiper={setThumbsSwiper}
                    spaceBetween={10}
                    slidesPerView={3}
                    freeMode={true}
                    watchSlidesProgress={true}
                    className={'mySwiper lot-swiper-inner ' + props?.sliderClass}
                    breakpoints={{
                        480: {
                            slidesPerView: 4,
                        },
                    }}
                >
                </Swiper>
            </>
        );
    }
 
};

export default memo(PhaseSlider);