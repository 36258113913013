import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { communityDetails } from '../services/CommunityService';
import { ROUTES } from '../constants/routes';

export const CommunityContext = createContext<any>('');

export const CommunityProvider = (props: any) => {
    const { children } = props;
    const [community_id, setCommunityId] = useState<any>('');
    const [communityData, setCommunityData] = useState<[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const parts = pathname.split('/');
        const communityId: any = parts[parts.length - 1];
        if (communityId) {
            setIsLoading(true)
            setCommunityId(communityId);
            fetchCommunityData(communityId);
        }
    }, [pathname]);

    const fetchCommunityData = (communityId = '') => {
        communityDetails(communityId)
            .then((response: any) => {
                setIsLoading(false)
                if (!response?.data?.[0] || response?.data?.[0]?.length < 0) {
                    toast.error('Community details not found');
                    navigate(ROUTES.HOME);
                }
                setCommunityData(response?.data?.[0] || []);
            })
            .catch((e) => {
                setIsLoading(false)
                console.log(e)
            });
    }

    const value = {
        community_id,
        communityData,
        isLoading,
        setCommunityId,
        fetchCommunityData
    };

    return <CommunityContext.Provider value={value}>{children}</CommunityContext.Provider>;
};

CommunityProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
