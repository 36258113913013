import { axiosInterceptor } from './interceptors';
import { API } from '../constants/api';

export const createPhaseContact = async (data: any) => {
    const response = await axiosInterceptor.post(`${API.PHASE_CONTACT.CREATE}`, data);
    return response;
};
export const updatePhaseContact = async (id: number, data: any) => {
    const response = await axiosInterceptor.patch(`${API.PHASE_CONTACT.UPDATE}/${id}`, data);
    return response;
};
export const deletePhaseContact = async (id: number) => {
    const response = await axiosInterceptor.delete(`${API.PHASE_CONTACT.DELETE}/${id}`);
    return response;
};