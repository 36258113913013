import { axiosInterceptor } from './interceptors';
import { API } from '../constants/api';

export const login = async () => {
    const response: any = await axiosInterceptor.get(API.USER.LOGIN);
    const params = new URLSearchParams(response.data.url);
    const verifier: any = params.get('verifier');
    localStorage.setItem('verifier', verifier);
    return response;
};

export const logout = async () => {
    const response: any = await axiosInterceptor.get(API.USER.LOGOUT);
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    return response;
};

export const code = async (data: any) => {
    const response: any = await axiosInterceptor.post(API.USER.CODE, data);
    console.log(response.token);
    localStorage.setItem('token', response.token);
    return response;
};
