import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { fetchUserDetails } from '../services/UserService';



export const AuthContext = createContext<any>(null);

export const AuthProvider = (props: any) => {
    const { children } = props;
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [currentUser, setCurrentUser] = useState<any>(null);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        if (localStorage.hasOwnProperty('token') && localStorage.getItem('token')) {
            setIsAuthenticated(true)
            setLoader(false);
            const user = JSON.parse(localStorage.getItem('user') as string)
            setCurrentUser(user);

            fetchUserDetails()
                .then((response) => {
                    if (response.data) {
                        setCurrentUser(response.data);
                        setIsAuthenticated(true);
                        localStorage.setItem('user', JSON.stringify(response?.data) || '');
                    }
                })
                .finally(() => {
                    setLoader(false);
                })
                .catch(console.log);
        } else {
            setLoader(false);
            setIsAuthenticated(false);
        }
    }, [isAuthenticated]);

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                currentUser,
                loader,
                setIsAuthenticated
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
