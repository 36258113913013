export const preventEnterSubmit = (keyEvent: any) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
        keyEvent.preventDefault();
    }
};

export const getSelectBoxOptions = (data: Array<any>, value: string = 'id', label: string = 'name') => {
    if (!Array.isArray(data)) {
        data = [data];
    }
    return data.map((data) => ({ value: data[value], label: data[label] }));
};

export const getShortName = (name: string) => {
    const shortName = name
        .split(' ')
        .map((word) => word.charAt(0))
        .join('');
    return shortName;
};

export const onError = (event: any) => {
    event.target.src = '';
};

export const stringWithDot = (stringTobeCut: string, length = 7) => {
    return stringTobeCut.length > length ? stringTobeCut.substring(0, length - 3) + '...' : stringTobeCut;
};

export const formatPhoneNumber = (phoneNumberString: string) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
        var intlCode = match[1] ? '+1 ' : '';
        return [intlCode, match[2], ' ', match[3], ' ', match[4], ' ', match[5]].join('');
    }

    return phoneNumberString;
};
