import axios from 'axios';

const BaseApiUrl = window.API_URL;

export const axiosInterceptor = axios.create({
    baseURL: BaseApiUrl,
});

axiosInterceptor.interceptors.request.use(
    (config: any) => {
        if (localStorage.getItem('token')) {
            config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
        }
        return config;
    },
    (error: any) => Promise.reject(error),
);

axiosInterceptor.interceptors.response.use(
    (response) => {
        return response?.data;
    },
    (error) => {
        if (error.response.status === 401) {
            localStorage.removeItem('token');
            return (window.location.href = '/login');
        }
        return Promise.reject(error);
    },
);
