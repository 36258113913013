import React, { memo, useEffect } from 'react';
import Button from '../Button';
import { HiXMark } from 'react-icons/hi2';

interface IProps {
    title: string;
    handleClose(status: boolean): any;
    children: any;
    headerClass?: any;
    modalBodyClass?: any;
    size?: string;
    closeButtonClass?: any;
}

const CommonModal = ({ size, handleClose, children, modalBodyClass, closeButtonClass }: IProps) => {
    useEffect(() => {
        const handleEscapePress = (event: any) => {
            if (event.key === 'Escape') {
                handleClose(false);
            }
        };
        document.addEventListener('keydown', handleEscapePress);

        return () => {
            document.removeEventListener('keydown', handleEscapePress);
        };
    }, [handleClose]);

    return (
        <div className={'modal_backdrop ' + modalBodyClass}>
            <div id="small-modal" className="modal_content">
                <div className={`relative max-h-full lot-img-popup ${size}`}>
                    <div className="bg-white rounded-xl shadow dark:bg-[#252525] p-6 lot-img-popup-inner">
                        <Button onClick={handleClose} className={`absolute ${closeButtonClass?.buttonStyle ? `${closeButtonClass?.buttonStyle}` : 'top-4 right-3'}`}>
                            <HiXMark size={20} className={`dark:text-white ${closeButtonClass?.iconStyle? `${closeButtonClass?.iconStyle}`:'text-black'}`} />
                        </Button>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(CommonModal);
