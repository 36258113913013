import { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
// import { ROUTES } from "../../constants/routes";

import Loader from "../../components/Loader";
import { code } from "../../services/AuthService";
import { AuthContext } from "../../context/AuthContext";
import { toast } from "react-toastify";
// import { AuthContext } from "../../context/AuthContext";

const Callback = () => {
    // const navigate = useNavigate();
    const { setIsAuthenticated } = useContext(AuthContext);
    const [searchParams] = useSearchParams();

    const handleCallback = (message: string) => {
        // window.opener.postMessage(message, window.location.origin);
        if (message === 'loginSuccess') {
            setIsAuthenticated(true);
        } else {
            toast.error("Your email is not included in the app. \n Please contact to administrator.")
        }
    };

    useEffect(() => {
        const data = {
            code: searchParams.get("code"),
            state: searchParams.get("state"),
            verifier: localStorage.getItem("verifier"),
        };

        if (data.code && data.state && data.verifier) {
            code(data)
                .then(() => handleCallback("loginSuccess"))
                .catch(() => handleCallback("loginFailure"))
                .finally(() => localStorage.removeItem("verifier"));
        } else {
            // navigate(ROUTES.HOME);
        }
    }, []);

    return <Loader />;
};

export default Callback;
