import React, { KeyboardEventHandler, useEffect, useContext } from 'react';
import CreatableSelect from 'react-select/creatable';
import { BasicContext } from '../../context/BasicContext';
import { MultiValue, SingleValue, ActionMeta } from 'react-select';

interface Option {
  readonly label: string;
  readonly value: string;
}

interface CustomCreatableSelectProps {
  value: readonly Option[];
  onChange: (newValue: MultiValue<Option> | SingleValue<Option>, actionMeta: ActionMeta<Option>) => void;
  onInputChange: (newInput: string) => void;
  inputValue: string;
  placeholder?: string;
  isClearable?: boolean;
  isMulti?: boolean;
  menuIsOpen?: boolean;
  components?: any; // Custom components if needed
}

const defaultComponents = {
  DropdownIndicator: null,
};

const CustomCreatableSelect: React.FC<CustomCreatableSelectProps> = ({
  value,
  onChange,
  onInputChange,
  inputValue,
  placeholder = "Type something and press enter...",
  isClearable = true,
  isMulti = true,
  menuIsOpen = false,
  components = defaultComponents,
}) => {
  // Get the isDarkTheme value from context inside the component
  const { isDarkTheme } = useContext(BasicContext);

  // Define custom styles based on the context value
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: isDarkTheme ? 'transparent' : 'white', // Dark background for the control
      borderColor: isDarkTheme ? '#999' : 'rgb(229 231 235)', // Border color for the control
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#999', // Hover border color
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: '#333', // Dark background for the menu
      color: '#fff', // Text color in the menu
    }),
    menuList: (provided: any) => ({
      ...provided,
      backgroundColor: '#333', // Dark background for the menu list
      color: '#fff', // Text color in the menu list
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#555' : '#333', // Background for selected option
      color: '#fff', // Text color for the option
      '&:hover': {
        backgroundColor: '#444', // Hover background color for option
      },
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: isDarkTheme ? '#746E6D' : '#aaa', // Placeholder text color
    }),
    input: (provided: any) => ({
      ...provided,
      color: isDarkTheme ? '#e2d7d6' : 'text-gray-700', // Text color for input
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: '#fff', // Text color for single value
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: '#555', // Background for multi-value
      color: '#fff', // Text color for multi-value
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: '#fff', // Text color for multi-value label
    }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      color: '#fff', // Color for multi-value remove icon
      ':hover': {
        backgroundColor: '#666', // Hover background color for remove icon
        color: '#fff', // Hover color for remove icon
      },
    }),
  };

  // Filter out any empty values during the update phase
  useEffect(() => {
    const filteredValue = value.filter(option => option.label.trim() !== '');
    const removedValues = value.filter(option => option.label.trim() === ''); // Capture removed values

    if (filteredValue.length !== value.length) {
      onChange(filteredValue, {
        action: 'remove-value',
        removedValue: removedValues[0] || { label: '', value: '' }, // Assign the removed value properly
      });
    }
  }, [value, onChange]);

  const handleKeyDown: KeyboardEventHandler = (event) => {
    const trimmedInputValue = inputValue.trim(); // Trim input to avoid empty options

    if (!trimmedInputValue) return; // Prevent empty input from being added

    switch (event.key) {
      case 'Enter':
      case 'Tab':
        if (isMulti) {
          onChange(
            [...(value as Option[]), { label: trimmedInputValue, value: trimmedInputValue }],
            { action: 'create-option', option: { label: trimmedInputValue, value: trimmedInputValue } } 
          );
        } else {
          onChange({ label: trimmedInputValue, value: trimmedInputValue }, { action: 'create-option', option: { label: trimmedInputValue, value: trimmedInputValue } });
        }
        onInputChange(''); // Clear input after adding
        event.preventDefault();
    }
  };

  return (
    <CreatableSelect
      components={components}
      inputValue={inputValue}
      isClearable={isClearable}
      isMulti={isMulti}
      menuIsOpen={menuIsOpen}
      onChange={onChange}
      onInputChange={onInputChange}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      value={value}
      styles={customStyles} // Apply custom styles here
    />
  );
};

export default CustomCreatableSelect;
