import { axiosInterceptor } from './interceptors';
import { API } from '../constants/api';

export const fetchCountry = async () => {
    const response = await axiosInterceptor.get(`${API.LOCATION.COUNTRY_LIST}`);
    return response;
};
export const fetchState = async (data: any) => {
    const response = await axiosInterceptor.get(`${API.LOCATION.STATES_LIST}?country_code=${data}`);
    return response;
};

export const fetchCity = async (data: any) => {
    const response = await axiosInterceptor.get(`${API.LOCATION.CITY_LIST}?state_code=${data}`);
    return response;
};