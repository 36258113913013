import { useContext, useEffect, useState, ChangeEvent,FormEvent } from 'react';
import Button from '../../../components/Button';

import InputText from '../../../components/InputText/InputText';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants/routes';
import no_image from '../../../assets/img/no-image.svg';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../context/AuthContext';
import { fetchCity, fetchCountry, fetchState } from '../../../services/LocationService';
import SelectBox from '../../../components/SelectBox/SelectBox';
import { createPhaseContact, updatePhaseContact } from '../../../services/PhaseContactService';
import ErrorMsg from '../../../components/errorMsg';
import CreatableSelect from '../../../components/CreatableSelect/CreatableSelect';
import { AxiosResponse } from 'axios';

interface CSC{
    value: string | number; // Ensure value is always defined
    label: string;
}
interface FormData {
    name: string;
    email: string;
    contact_no: string;
    organization: string;
    position: string;
    area_of_expertise: string;
    division: string;
    office_location: string;
    city: string | null;
    country: string | null;
    state: string | null;
    address: string;
    image: string;
    new_area_of_expertise?: string;
    id?: number;
    projectId?: number;
    images?: any;
    lot_id?: number;
}
interface ExtendedOption extends Option {
    __isNew__?: boolean;
}
interface Option {
    label: string;
    value: string;
}
interface ValidForm {
    [key: string]: boolean;
}
interface ErrorMessage {
    [key: string]: string;
}
interface ResponseCSC{
    code: string;
    id: number;
    iso3: string;
    name: string;
}
const initFormData : FormData = {
    name: '',
    email: '',
    contact_no: '',
    organization: '',
    position: '',
    area_of_expertise: '',
    division: '',
    office_location: '',
    city: null,
    country: null,
    state: null,
    address: '',
    image: '',
};
const initValidForm: ValidForm = {};
const errorMessage : ErrorMessage = {};
Object.keys(initFormData).forEach((key) => {
    initValidForm[key] = true;
});
Object.keys(initFormData).forEach((key) => {
    errorMessage[key] = '';
});

const requiredFields = [
    'name',
    'email',
    'contact_no',
    'organization',
    'position',
    'division',
    'office_location',
    'city',
    'country',
    'state',
    'address',
    'image',
];
const CreatePhaseContacts = (props: any) => {
    const { currentUser } = useContext(AuthContext);
    const [formData, setFormData] = useState<FormData>(initFormData);
    const [phaseContactId, setPhaseContactId] = useState<number | null>(null);
    const [validForm, setValidForm] = useState<ValidForm>(initValidForm);
    const [errorMsg, setErrorMsg] = useState<ErrorMessage>(errorMessage);    
    const [area_of_expertise, setAreaOfExpertise] = useState<Option[]>([]);
    const [countryData, setCountryData] = useState<CSC[]>([]);
    const [stateData, setStateData] = useState<CSC[]>([]);
    const [cityData, setCityData] = useState<CSC[]>([]);
    const [inputValue, setInputValue] = useState<string>('');
    const [phaseContactImage, setPhaseContactImage] = useState<string>('');
    const [isFormSubmitLoading, setFormSubmitLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g;
    
    useEffect(() => {
        if (currentUser?.user_group !== 'admin') {
            navigate(`${ROUTES.HOME}`);
        }
        if (props?.phaseContact?.id) {
            setPhaseContactId(props?.phaseContact?.id);
            setFormData({
                ...formData,
                ...props?.phaseContact,
                city: props?.phaseContact?.city?.id,
                state: props?.phaseContact?.state?.code,
                country: props?.phaseContact?.country?.code,
                image: props?.phaseContact?.images?.[0]?.url,
            });
            const selectedProductInfo = props?.phaseContact?.area_of_expertise 
                ? props.phaseContact.area_of_expertise.split(',') 
                : [];

            if (Array.isArray(selectedProductInfo)) {
                const areaOfExpertiseArray = selectedProductInfo.map((item: string) => ({
                    label: item.trim(),
                    value: item.trim(),
                }));
                setAreaOfExpertise(areaOfExpertiseArray);
            } else {
                setAreaOfExpertise([]); // handle the case where selectedProductInfo is not an array
            }

        }
    }, []);

    useEffect(() => {
        fetchCountry()
            .then((response: AxiosResponse<ResponseCSC[]>) => {
                const country = response?.data?.map((v: ResponseCSC) => {
                    return { label: v.name, value: v.code, id: v.id };
                });                          
                setCountryData(country || []);
            })
            .catch((e) => {
                console.log(e);
            });
    }, []);

    useEffect(() => {
        fetchState(formData?.country)
            .then((response: AxiosResponse<ResponseCSC[]>) => {                
                const state = response?.data?.map((v: ResponseCSC) => {
                    return { label: v.name, value: v.code, id: v.id };
                });
                setStateData(state || []);
            })
            .catch((e) => {
                console.log(e);
            });
    }, [formData?.country]);
    useEffect(() => {
        if (formData?.state === null || formData?.state === '') {
            return;
        }
        fetchCity(formData?.state)
            .then((response: AxiosResponse<ResponseCSC[]>) => {
                const city = response?.data?.map((v: ResponseCSC) => {
                    return { label: v.name, value: v.id };
                });
                setCityData(city || []);
            })
            .catch((e) => {
                console.log(e);
            });
    }, [formData?.state]);

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleChangeCountry = async (event: any) => {
        const selectedCountry = event?.value;
        if (formData.country === selectedCountry) {            
            return; // Exit the function if the same country is selected
        }
        setFormData({ ...formData, country: event?.value, state: '', city: '' });
        setStateData([]);
        setCityData([]);
    };
    const handleChangeState = async (event: any) => {
        const selectedState = event?.value;
        if (formData.state === selectedState) {            
            return; // Exit the function if the same country is selected
        }
        setFormData({ ...formData, state: event?.value });
        setFormData({ ...formData, state: event?.value, city: '' });
        setCityData([]);
    };

    const valid = () => {
        let valid = true;
        let checkValidForm = validForm;
        let makeErrorMsg = errorMsg;
        Object.keys(validForm).map((v) => {
            if (requiredFields?.includes(v as keyof FormData)) {
                makeErrorMsg[v] = '';
                if (phaseContactId && v!=='image' && (formData?.[v as keyof FormData] === undefined || formData?.[v as keyof FormData] === '' || formData?.[v as keyof FormData] === null)) {
                    checkValidForm = { ...checkValidForm, [v]: false };
                    valid = false;

                }else if (!phaseContactId && (formData?.[v as keyof FormData] === undefined || formData?.[v as keyof FormData] === '' || formData?.[v as keyof FormData] === null)){
                    checkValidForm = { ...checkValidForm, [v]: false };
                    valid = false;
                } 
                else if (v === 'email' && formData?.[v as keyof FormData] !== '' && !emailRegex?.test(formData[v as keyof FormData] as string)) {
                    valid = false;
                    checkValidForm = { ...checkValidForm, [v]: false }
                    makeErrorMsg[v] = 'Must be a enter valid email address.';
                } else if (v === 'area_of_expertise' && area_of_expertise?.length < 1) {
                    makeErrorMsg[v] = 'Area of expertise is required.';
                    valid = false;
                } else {
                    checkValidForm = { ...checkValidForm, [v]: true };
                }
            }
            return valid;
        });
        setErrorMsg({ ...errorMsg, ...makeErrorMsg });
        setValidForm({ ...validForm, ...checkValidForm });
        return valid;
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!valid()) {
            return true;
        }
        setFormSubmitLoading(true);
        const formDataToSend = new FormData();
        const postData = _.pick(formData, _.keys(initFormData));
        postData.area_of_expertise = area_of_expertise.filter((v: ExtendedOption) => !v.__isNew__).map((v: ExtendedOption) => v.value)?.join(',');
        

        // Find the country name based on the selected value
        const selectedCountry = countryData.find((v: CSC) => v.value === formData.country);
        postData.country = selectedCountry ? selectedCountry.label : formData.country;

        // Find the state name based on the selected value
        const selectedState = stateData.find((v: CSC) => v.value === formData.state);
        postData.state = selectedState ? selectedState.label : formData.state;

        // Find the city name based on the selected value
        const selectedCity = cityData.find((v: CSC) => v.value === formData.city);
        postData.city = selectedCity ? selectedCity.label : formData.city;
        formDataToSend.append('projectId', props?.phaseId);
        
        Object.entries(postData).forEach(([key, value] :any) => {
            if (key !== 'image') {
                if (typeof value === 'string' || value instanceof Blob) {
                    formDataToSend.append(key, value);
                } else if (Array.isArray(value)) {
                    // Handle the array case if necessary, e.g., convert to a JSON string
                    formDataToSend.append(key, JSON.stringify(value));
                } else if (value !== null) {
                    // Handle other cases, such as numbers, booleans, etc.
                    formDataToSend.append(key, String(value));
                }
            }
        }); 
        if (postData.image && (postData.image as any) instanceof File) {
            formDataToSend.append('image', postData.image);
        }
              
        if (phaseContactId) {
            postData.id = phaseContactId;
            updatePhaseContact(phaseContactId, formDataToSend)
                .then((result: any) => {
                    setFormSubmitLoading(false);
                    toast.success('Successfully updated details');
                    props.getPhaseData(props?.phaseId);
                    props.handleCloseModalAction();
                })
                .catch((error: any) => {
                    setFormSubmitLoading(false);
                    const errMsg = error?.response?.status === 409 ? error?.response?.data?.message : 'Something went wrong! Please try again';
                    toast.error(errMsg);
                });
        } else {
            if (props?.phaseId) {
                postData.projectId = props?.phaseId;
                if (phaseContactId) {
                    postData.lot_id = phaseContactId;
                }
                createPhaseContact(formDataToSend)
                    .then((result: any) => {
                        if (result) {
                            setFormSubmitLoading(false);
                            toast.success('Successfully created phase');
                            props.setOpenPhaseContactContainer(false);
                            props.getPhaseData(props?.phaseId);
                        }
                    })
                    .catch((error: any) => {
                        setFormSubmitLoading(false);
                        const errMsg = error?.response?.status === 409 ? error?.response?.data?.message : 'Something went wrong! Please try again';
                        toast.error(errMsg);
                    });
            } else {
                toast.error('Phase not found');
            }
        }
    };
    
    const handleAOEChange = (selected:any) => {
        setAreaOfExpertise(selected);
        setFormData({ ...formData, area_of_expertise: selected.map((option: any) => option.value).join(',') });
      };   
    const handleInputSelectChange = (newValue: string) => {

        // Check if the new value contains a comma
        if (newValue.includes(',')) {
            // Update error message when comma is detected
            setErrorMsg((old) => ({
            ...old,
            'area_of_expertise': 'Comma is not Allowed',
            }));

            // Prevent input value from being updated with the comma
            return;
        } else {    

            // Clear the error message when the input is valid
            setErrorMsg((old) => ({
            ...old,
            'area_of_expertise': '',
            }));
        }

        // Update the input value only if valid (no comma)
        setInputValue(newValue);
        }; 
      const handleChangeImage = (event: any) => {
        const file = event.target.files[0]; // Get the first selected file
        if (file) {  
            const reader = new FileReader();
            reader.onloadend = () => {
                setPhaseContactImage(reader.result as string); // Set the Data URL for preview
            };
            reader.readAsDataURL(file); 
            setPhaseContactImage(event.target.result)              
                setFormData((prevData: any) => ({
                    ...prevData,
                    image: file
                }));
            }
        else {
                console.error('No file selected');
            }
        };
    return (
        <>
            <section className="content flex flex-col px-5 xl:px-[64px] lg:px-[34px] min-h-[100%]">
                <form className="w-full" onSubmit={handleSubmit}>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3">
                            <InputText
                                requiredLabel={true}
                                className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-transparent dark:text-[#746E6D]  dark:border-opacity-50"
                                inputName="name"
                                label="Name"
                                placeholder="Enter Contact name"
                                value={formData?.name || ''}
                                onChangeFunc={handleInputChange}
                                labelClassName="dark:text-[#746E6D]"
                                errorText={!validForm?.name ? 'Contact name is required' : null}
                            />
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                            <label className="form_label mr-2 pl-2 dark:text-[#746E6D]">Area of expertise</label>
                            <CreatableSelect
                                key="area_of_expertise"
                                onChange={handleAOEChange}
                                onInputChange={handleInputSelectChange} // Add this
                                inputValue={inputValue} 
                                value={area_of_expertise}
                                placeholder="Select or type..."
                                isClearable
                                isMulti                 
                            />
                            {errorMsg?.area_of_expertise && <ErrorMsg errorText={errorMsg?.area_of_expertise} />}
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3">
                            <InputText
                                requiredLabel={true}
                                className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-transparent dark:text-[#746E6D]  dark:border-opacity-50"
                                inputName="email"
                                label="Email"
                                placeholder="Enter Email"
                                value={formData?.email}
                                onChangeFunc={handleInputChange}
                                labelClassName="dark:text-[#746E6D]"
                                errorText={!validForm?.email ? errorMsg?.email || 'email is required' : null}
                            />
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                            <InputText
                                requiredLabel={true}
                                className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-transparent dark:text-[#746E6D]  dark:border-opacity-50"
                                inputName="contact_no"
                                label="Contact Number"
                                placeholder="Enter Contact Number"
                                value={formData?.contact_no}
                                onChangeFunc={handleInputChange}
                                labelClassName="dark:text-[#746E6D]"
                                errorText={!validForm?.contact_no ? 'Contact No is required' : null}
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3">
                            <InputText
                                requiredLabel={true}
                                className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-transparent dark:text-[#746E6D]  dark:border-opacity-50"
                                inputName="organization"
                                label="Organization"
                                placeholder="Enter Organization"
                                value={formData?.organization}
                                onChangeFunc={handleInputChange}
                                labelClassName="dark:text-[#746E6D]"
                                errorText={!validForm?.organization ? 'Organization is required' : null}
                            />
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                            <InputText
                                requiredLabel={true}
                                inputName="position"
                                label="Position"
                                className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-transparent dark:text-[#746E6D]  dark:border-opacity-50"
                                placeholder="Enter Position"
                                value={formData?.position || ''}
                                onChangeFunc={handleInputChange}
                                labelClassName="dark:text-[#746E6D]"
                                errorText={!validForm?.position ? 'Position is required' : null}
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3">
                            <InputText
                                requiredLabel={true}
                                rows={4}
                                inputName="division"
                                label="Division"
                                className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-transparent dark:text-[#746E6D]  dark:border-opacity-50"
                                placeholder="Enter Division"
                                value={formData?.division || ''}
                                onChangeFunc={handleInputChange}
                                labelClassName="dark:text-[#746E6D]"
                                errorText={!validForm?.division ? 'Division is required' : null}
                            />
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                            <InputText
                                requiredLabel={true}
                                inputName="office_location"
                                label="Office Location"
                                className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-transparent dark:text-[#746E6D]  dark:border-opacity-50"
                                placeholder="Enter Office Location"
                                value={formData?.office_location || ''}
                                onChangeFunc={handleInputChange}
                                labelClassName="dark:text-[#746E6D]"
                                errorText={!validForm?.office_location ? ' Office Location is required' : null}
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label className={`form_label dark:text-[#746E6D]`}>Select location<span className='text-lg text-red-600 ms-[2px]'>*</span></label>
                            <div className="grid gap-x-8 gap-y-4 grid-cols-2 gap-3 ">
                                <div className="w-[100%]">
                                    <SelectBox
                                        name="Country"
                                        id="country"
                                        placeholder="Select Country"
                                        className="w-5"
                                        options={countryData}
                                        onChangeFunc={handleChangeCountry}
                                        value={countryData?.find((val: CSC) => val.value === formData?.country)}
                                        errorText={!validForm.country ? 'Country is required' : null}
                                    />
                                </div>
                                <div className="w-[100%]">
                                    <SelectBox
                                        name="State"
                                        id="state"
                                        className="w-5"
                                        placeholder="Select State"
                                        options={stateData}
                                        onChangeFunc={handleChangeState}
                                        value={formData?.state ? stateData?.find((option: CSC) => option.value === formData?.state) : null}
                                        errorText={!validForm.state ? 'State is required' : null}
                                    />
                                </div>
                                <div className="w-[100%]">
                                    <SelectBox
                                        name="City"
                                        id="city"
                                        placeholder="Select City"
                                        className="w-5"
                                        options={cityData}
                                        onChangeFunc={(event: any) => setFormData({ ...formData, city: event?.value })}
                                        value={formData?.city ? cityData?.find((option: CSC) => option.value === formData?.city) : null}
                                        errorText={!validForm.city ? 'City is required' : null}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                            <InputText
                                requiredLabel={true}
                                asTextarea={'textarea'}
                                rows={4}
                                inputName="address"
                                label="Address"
                                className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-transparent dark:text-[#746E6D]  dark:border-opacity-50"
                                placeholder="Enter Contact Person Address."
                                value={formData?.address}
                                onChangeFunc={handleInputChange}
                                labelClassName="dark:text-[#746E6D]"
                                errorText={!validForm?.address ? 'Address is required' : null}
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3">
                            <label className="form_label mb-1 dark:text-[#746E6D]">Image<span className='text-lg text-red-600 ms-[2px]'> *</span></label>
                            <div className="w-full ">
                            <input type="file"
                                    className=' placeholder-[#746E6D] dark:bg-transparent dark:text-[#746E6D]  dark:border-opacity-50 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white w-full' 
                                    onChange={handleChangeImage}
                                    id = 'fileUpload'
                                    />
                                    <p className='text-sm text-red-600 dark:text-red-500 ms-[2px]'>{!validForm.image ? 'Image is required' : null}</p>
                            </div>
                        </div>
                        
                        <div className="w-full md:w-1/2 px-3">
                                <div className="overflow-hidden h-[150px] text-orangeClr">
                                    <img
                                        src={phaseContactImage || formData?.image}
                                        alt=""
                                        className="h-[100%]"
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = no_image;
                                        }}
                                    />
                                </div>
                            </div>
                    </div>
                    <div className="flex flex-wrap mt-10 justify-center">
                        <Button
                            disabled={isFormSubmitLoading}
                            type="submit"
                            className={`text-orange-500 border border-orange-500 flex text-center items-center justify-center py-[6px] px-16 rounded-md sm:rounded-lg  ${
                                isFormSubmitLoading ? ' opacity-60' : ''
                            }`}
                        >
                            {isFormSubmitLoading && (
                                <svg
                                    aria-hidden="true"
                                    className="inline w-4 h-6 text-gray-200 me-2 animate-spin dark:text-gray-600 fill-[#FA5A0A]"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentFill"
                                    />
                                </svg>
                            )}
                            {!isFormSubmitLoading && (
                                <>
                                    {phaseContactId && 'Update'}
                                    {!phaseContactId && 'Create'}
                                </>
                            )}
                        </Button>
                    </div>
                </form>
            </section>
        </>
    );
};

export default CreatePhaseContacts;
