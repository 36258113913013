import React, { memo } from 'react';
import { Button, Modal } from 'flowbite-react';
import { HiOutlineExclamationCircle } from 'react-icons/hi';

interface IDeleteModalProps {
    show: boolean;
    handleClose(status: boolean): any;
    message: string;
}

const DeleteModal = ({ show, handleClose,message }: IDeleteModalProps) => (
    <Modal show={show} size="md" popup onClose={handleClose(false)}>
        <Modal.Header />
        <Modal.Body>
            <div className="text-center">
                <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{ message || 'Are you sure you want to delete this?'}</h3>
                <div className="flex justify-center gap-4">
                    <Button color="failure" onClick={handleClose(true)}>
                        Yes, I'm sure
                    </Button>
                    <Button color="gray" onClick={handleClose(false)}>
                        No, cancel
                    </Button>
                </div>
            </div>
        </Modal.Body>
    </Modal>
);

DeleteModal.defaultProps = {
    show: false,
    message: 'Are you sure you want to delete this?',
};

export default memo(DeleteModal);
