import { memo, useCallback, useContext,useState } from 'react';
import { AxiosResponse,AxiosError } from 'axios';
import { PiMapPin } from 'react-icons/pi';
import { FiArrowRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { GoArrowLeft } from 'react-icons/go';
import { CiLocationOn } from 'react-icons/ci';
import { PiHouseLine } from 'react-icons/pi';

import EmpireAvalone from '../../../assets/img/empire_avalone.svg';
import Leftarrow from '../../../assets/img/double_left.svg';
import Rightarrow from '../../../assets/img/double_right.svg';
import { DashboardContext } from '../../../context/DashboardContext';
import Drawer from '../../../components/Drawer';
import { ROUTES } from '../../../constants/routes';
import { Icon } from '../../../components/Icon';
import Loader from '../../../components/Loader';
import DeleteModal from '../../../components/DeleteModal';
import { deleteCommunity } from '../../../services/CommunityService';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../context/AuthContext';
import active from '../../../assets/img/active.svg';
import closed from '../../../assets/img/closed.svg';
interface DeleteCommunityResponse {
    message: string;
}
interface ErrorMessage{
    message: string;
}
interface Product {
    icon?: string;
    name: string;
}

interface Community {
    id: number;
    community_name: string;
    images?: { url: string }[];
    status: 'ACTIVE' | 'CLOSED';
    city?: { name: string };
    address?: string;
    product_info?: Product[];
    image?: string;
}
const CommunitySideBar = (props: any) => {
    const { communityData, communityPageDetail, setCommunityPageDetail, setCommunityId } = useContext(DashboardContext);
    const { currentUser } = useContext(AuthContext);
    const [openLeft, setOpenLeft] = useState<boolean>(true);
    const [isDeleteModel, setIsDeleteModel] = useState<boolean>(false);
    const [deleteID, setDeleteId] = useState<number | null>(null);
    const handleModalClose = useCallback(
        (status: boolean) => () => {
            if (status) {
                if (deleteID !== null) {
                    deleteCommunity(deleteID)
                        .then((result: AxiosResponse<DeleteCommunityResponse>) => {
                            setDeleteId(null);
                            toast.success('Successfully delete community details');
                            props?.reloadData();
                        })
                        .catch((error: AxiosError<ErrorMessage>) => {
                            setDeleteId(null);
                            const errMsg =
                                error?.response?.status === 409 ? error?.response?.data?.message : 'Something went wrong! Please try again';
                            toast.error(errMsg);
                        });
                    setIsDeleteModel(false);
                }
            } else {
                setIsDeleteModel(false);
            }
            setDeleteId(null);
        },
        [isDeleteModel],
    );

    return (
        <>
            <Drawer open={openLeft} setOpen={setOpenLeft}>
                <>
                    <div
                        className="border border-orange-500 bg-white dark:bg-[#252525] rounded-md w-8 h-8 text-center p-[2px] md:absolute absolute top-[23px] -right-4 hidden lg:flex items-center justify-center cursor-pointer"
                        onClick={() => setOpenLeft(!openLeft)}
                    >
                        <img src={openLeft ? Leftarrow : Rightarrow} alt="" className="object-cover w-[20px]" />
                    </div>
                    {props?.isLoading && <Loader />}
                    {props?.isLoading === false && (
                        <>
                            {!communityPageDetail && (
                                <>
                                    {openLeft && (
                                        <div className="flex items-center sm:mb-4 mb-3">
                                            <h5
                                                className={
                                                    'font-normal text-dark-900 dark:text-white lg:ps-6 ps-3 capitalize sm:text-[26px] text-xl' +
                                                    (openLeft ? ' lg:text-[32px] ' : ' text-[24px] ')
                                                }
                                            >
                                                {communityData?.length} communities
                                            </h5>
                                            {currentUser?.user_group === 'admin' && (
                                                <Link to={`${ROUTES.COMMUNITY_ADD}`}>
                                                    <Icon
                                                        nameIcon={'IoMdAddCircle'}
                                                        propsIcon={{
                                                            className: 'me-2 text-base text-orangeClr h-5 ms-1 mb-2 dark:text-white w-full',
                                                        }}
                                                    />
                                                </Link>
                                            )}
                                        </div>
                                    )}
                                    <div className={`overflow-auto lg:px-6 px-3 ${openLeft ? 'max-h-[90%]' : 'max-h-full'}`}>
                                        {communityData.length ? (
                                            communityData.map((community: Community, index: number) => (
                                                <div className="mt-2 md:mt-3 first:mt-0" key={index}>
                                                    <label
                                                        className={`bg-white dark:bg-[#252525] dark:border-[#393939] items-center flex shadow-card mb-2 md:mb-3 xl:rounded-[24px] rounded-xl
                                                            ${openLeft ? ' border-2 px-2 py-3 lg:p-4' : ' last-of-type:mb-0'}`}
                                                    >
                                                        <div className="w-[100px] h-[100px] sm:w-[124px] sm:h-[124px] md:w-[140px] md:h-[120px] text-orangeClr">
                                                            <img
                                                                src={community?.image || EmpireAvalone}
                                                                alt=""
                                                                className={`rounded-xl object-cover w-full h-full`}
                                                                onClick={() => setCommunityPageDetail(community)}
                                                            />
                                                        </div>
                                                        {openLeft && (
                                                            <div className="w-[75%]">
                                                                <div className="sm:ps-6 ps-4 w-full flex flex-col justify-center cursor-pointer">
                                                                    {currentUser?.user_group === 'admin' && (
                                                                        <span className="flex w-full mb-3 justify-end mt-[-6px]">
                                                                            <Link
                                                                                to={`${ROUTES.COMMUNITY_ADD}/${community?.id}`}
                                                                                state={{ communityName: community?.community_name }}
                                                                            >
                                                                                <Icon
                                                                                    nameIcon={'FaEdit'}
                                                                                    propsIcon={{
                                                                                        className:
                                                                                            'me-4 text-orangeClr dark:text-white h-[14px] w-full',
                                                                                    }}
                                                                                />
                                                                            </Link>
                                                                            <div
                                                                                className="cursor-pointer"
                                                                                onClick={() => {
                                                                                    setDeleteId(community?.id);
                                                                                    setIsDeleteModel(true);
                                                                                }}
                                                                            >
                                                                                <Icon
                                                                                    nameIcon={'FaTrash'}
                                                                                    propsIcon={{
                                                                                        className: 'text-orangeClr dark:text-white h-[14px] w-full',
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </span>
                                                                    )}
                                                                    <div className="flex justify-between items-start" onClick={() => setCommunityPageDetail(community)}>
                                                                        <p className="p-0 m-0 font-normal text-base sm:text-lg dark:text-white me-2 uppercase">
                                                                            {community?.community_name}
                                                                        </p>
                                                                        {community?.status === 'ACTIVE' && (
                                                                            <button className="flex-none">
                                                                                {/* <span className=''>{community?.status}</span> */}
                                                                                <img src={(community?.status === 'ACTIVE') ? active : closed} alt="" className="h-[24px]" />
                                                                            </button>
                                                                        )}
                                                                        {community?.status === 'CLOSED' && (
                                                                            <button className="flex-none">
                                                                                {/* <span className='flex h-[24px] leading-[27px]'>{community?.status}</span> */}
                                                                                <img src={closed} alt="" className="h-[24px]" />
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                    <div
                                                                        className="flex items-center mt-1"
                                                                        onClick={() => setCommunityPageDetail(community)}
                                                                    >
                                                                        <PiMapPin size={16} className="me-1 dark:text-white" />
                                                                        <p className="p-0 m-0 mt-1 text-sm font-HelveticaNeueCyr dark:text-white">
                                                                            {community?.city?.name}
                                                                        </p>
                                                                    </div>
                                                                    <div className="mt-5">
                                                                        <Link
                                                                            onClick={() => setCommunityId([])}
                                                                            to={`${ROUTES.COMMUNITY}/${community?.id}`}
                                                                            state={{ communityName: community?.community_name }}
                                                                        >
                                                                            <button className="border border-orange-500 flex text-center items-center justify-center py-[6px] px-8 rounded-md sm:rounded-lg">
                                                                                <span className="px-1 mt-1 text-orange-500 font-normal text-xs md:text-base leading-none uppercase">
                                                                                    See details
                                                                                </span>
                                                                                <FiArrowRight
                                                                                    size={24}
                                                                                    className="text-orange-500 self-center hidden md:block"
                                                                                />
                                                                            </button>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </label>
                                                </div>
                                            ))
                                        ) : (
                                            <></>
                                        )}
                                        <DeleteModal show={isDeleteModel} handleClose={handleModalClose} />
                                    </div>
                                </>
                            )}

                            {communityPageDetail && (
                                <>
                                    <div className="mb-2 lg:px-6 px-3">
                                        <button
                                            className="text-orangeClr text-sm font-normal flex items-center"
                                            onClick={() => setCommunityPageDetail('')}
                                        >
                                            <GoArrowLeft size="20" className="mr-[6px]" />
                                            <p className="mt-1">Back</p>
                                        </button>
                                    </div>
                                    <div className="mb-6 lg:px-6 px-3">
                                        <img
                                            src={communityPageDetail?.image || EmpireAvalone}
                                            alt=""
                                            className="w-[100%] h-[100%] md:max-h-[24vh] xl:max-h-[25vh] rounded-xl object-cover"
                                        />
                                    </div>

                                    {openLeft && (
                                        <>
                                            <div className="lg:px-6 px-3  md:max-h-[20vh] overflow-x-auto mb-3">
                                                <div className="flex items-center mb-5">
                                                    <h2 className="capitalize sm:text-[24px] text-xl dark:text-white">
                                                        {communityPageDetail?.community_name}
                                                    </h2>
                                                    <button className={`sm:ml-4 ml-3 flex-none`} >
                                                        <img src={(communityPageDetail?.status === 'ACTIVE') ? active : closed} alt="" className="h-[24px]" />
                                                    </button>
                                                </div>
                                                <div className="flex items-center mb-4 text-[#2E2624] dark:text-white">
                                                    <CiLocationOn className="sm:me-4 me-3 sm:text-lg text-base" />
                                                    <p className="sm:text-lg text-base font-normal mt-1">{communityPageDetail?.city?.name}</p>
                                                </div>
                                                <div className="flex items-center mb-4 text-[#2E2624] dark:text-white">
                                                    <PiHouseLine className="sm:me-4 me-3 sm:text-lg text-base" />
                                                    <p className="sm:text-lg text-base font-normal mt-1">{communityPageDetail?.address}</p>
                                                </div>
                                                {communityPageDetail?.product_info?.length ? (
                                                    communityPageDetail?.product_info?.map((product: Product, index: number) => (
                                                        <div className="flex items-center mb-4 text-[#2E2624] dark:text-white" key={index}>
                                                            {product?.icon && (
                                                                <Icon
                                                                    nameIcon={product?.icon}
                                                                    propsIcon={{ className: 'sm:me-4 me-3 sm:text-lg text-base' }}
                                                                />
                                                            )}
                                                            <p className="sm:text-lg text-base font-normal mt-1">{product?.name}</p>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                        </>
                                    )}
                                    <div className="lg:px-6 px-3">
                                        <Link
                                            onClick={() => setCommunityPageDetail('')}
                                            to={`${ROUTES.COMMUNITY}/${communityPageDetail?.id}`}
                                            state={{ communityName: communityPageDetail?.community_name }}
                                        >
                                            <button
                                                className={`border border-orange-500 bg-orange-500 flex text-center items-center m-0 p-0 justify-center max-w-full w-full
                                                    ${openLeft ? ' rounded-lg py-2 ' : ' rounded py-1 '}`}
                                            >
                                                <span
                                                    className={`text-white font-normal mt-1 leading-none uppercase
                                                        ${openLeft ? ' sm:text-base text-sm me-2 ' : ' text-xs me-[2px] '}`}
                                                >
                                                    See details
                                                </span>
                                                <FiArrowRight
                                                    className={`text-white self-center ${openLeft ? ' sm:text-2xl text-lg ' : ' text-lg '}`}
                                                />
                                            </button>
                                        </Link>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </>
            </Drawer>
        </>
    );
};

export default memo(CommunitySideBar);
