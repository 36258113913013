import { axiosInterceptor } from './interceptors';
import { API } from '../constants/api';

export const fetchProductInformation = async () => {
    const response = await axiosInterceptor.get(`${API.COMMON.FETCH_PRODUCT_INFORMATION}`);
    return response;
};
export const fetchCommunityProductInformation = async () => {
    const response = await axiosInterceptor.get(`${API.COMMON.COMMUNITY_PRODUCT_INFO_DATA}`);
    return response;
};
export const fetchPlanModels = async () => {
    const response = await axiosInterceptor.get(`${API.COMMON.FETCH_PLAN_MODELS}`);
    return response;
};
export const fetchAreaOfExpertise = async () => {
    const response = await axiosInterceptor.get(`${API.COMMON.AREA_OF_EXPERTISE}`);
    return response;
};
