import React from 'react';

const PageNotFound = () => {
    return (
        <div className="main__layout_height overflow-x-auto p-14">
            <section>
                <div className="w-full h-96 bg-[#FFEFE7] flex justify-center items-center">
                    <div>
                        <div className="flex items-center mb-2">
                            <h1 className="md:text-lg xxl:text-[28px] font-bold leading-none">404</h1>
                            <span className="mx-3 text-[#FA5A0A]">Page not found</span>
                        </div>
                        <p className="text-sm">
                            The page you are looking for does not exist. You have clicked on a bad link or entered an invalid URL.
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default PageNotFound;
