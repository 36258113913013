import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumbs = (props: any) => {
    return (
        <div className={`flex text-sm ${props?.className}`}>
            {props?.data?.map((breadcrumb: any, index: number) => (
                <span key={index}>
                    {breadcrumb?.link !== '' && <>
                        <Link className="dark:text-[#746E6D]" to={breadcrumb?.link}>
                            <span className="mr-2 dark:text-[#746E6D] ">{breadcrumb?.name}</span>
                        </Link>
                        <span className="mr-2 dark:text-[#746E6D]">/</span>
                    </>}
                    {breadcrumb?.link === '' && <>
                        <Link className="text-orangeClr" to={''}>
                            {breadcrumb?.name}
                        </Link>
                    </>}
                </span>
            ))}
        </div>
    )
};

export default Breadcrumbs;