import { memo } from 'react';
import SearchBar from '../../../components/SearchBar/SearchBar';

const TopSearchBox = () => {
    return (
        <>
            <div className="py-4 px-6 border border-gray-200 dark:border-[#252525] bg-white dark:bg-[#252525] sm:rounded-lg mt-[20px] z-[20]">
                <SearchBar />
            </div>
        </>
    );
};

export default memo(TopSearchBox);
