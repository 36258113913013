import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';

import Header from './Header';
import { AuthContext } from '../../context/AuthContext';
import ToastBodyContainer from '../../components/ToastContainer';
import { BasicContext } from '../../context/BasicContext';

const Layout = () => {
    const { isAuthenticated } = useContext(AuthContext);
    const { isDarkTheme } = useContext(BasicContext);
    return (
        <div id="app-layout" className={`bg-grayLight sm:pt-[104px] pt-[90px] min-h-screen ${isDarkTheme ? 'dark' : ''}`}>
            {isAuthenticated && (
                <>
                    <Header />
                </>
            )}
            <ToastBodyContainer />
            <Outlet />
        </div>
    );
};

export default Layout;
