import { useContext, useEffect, useState, FormEvent } from 'react';
import Button from '../../../components/Button';
import InputText from '../../../components/InputText/InputText';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants/routes';
import { FaRegCalendarAlt } from 'react-icons/fa';
import DateSelect from '../../../components/DateSelect/DateSelect';
import moment from 'moment';
import { DATE_FORMAT } from '../../../constants/common';
import { useLocation } from 'react-router-dom';
import { fetchProductInformation } from '../../../services/CommonService';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../context/AuthContext';
import { createLot, updateLot } from '../../../services/LotService';
import CreatableDropdown from '../../../components/CreatableDropdown/CreatableDropdown';
import { ChangeEvent } from 'react';
import { AxiosResponse,AxiosError } from 'axios';


interface ProductInfo{
    value: number;
    label: string;
}
interface FormData{
    model : string;
    elevation: string;
    base_price: string;
    phase_sq_footage: string;
    lot_size: string;
    avg_lot_price: string;
    lot_sq_footage: string;
    lot_image:any,
    modelId: string;
    construction_start: string,
    construction_end: string,
    no_of_lots:string,
    sold_lots: string,
    closed_lots: string,
    available_lots: string,
    [key: string]: any;
}
interface ExtendedProductInfo extends ProductInfo {
    __isNew__?: boolean;
}
interface ValidForm {
    [key: string]: boolean;
}
interface ErrorMessage {
    [key: string]: string;
}
interface ResponseLot{
    data: FormData;
    message: string;
}
interface ProductInfoApiResponse {
    product_information: {
        id: number;
        name: string;
        // Include other fields if necessary
    }[];
}
const initFormData = {
    model: '',
    elevation: '',
    base_price: '',
    phase_sq_footage: '',
    lot_size: '',
    avg_lot_price: '',
    lot_sq_footage: '',
    modelId:'',
    construction_start:'',
    construction_end:'',
    no_of_lots:'',
    sold_lots: '',
    closed_lots: '',
    available_lots: '',
    lot_image:'',
};
const initValidForm: ValidForm = {};
const errorMessage : ErrorMessage = {};
Object.keys(initFormData).forEach((key) => {
    initValidForm[key] = true;
});
Object.keys(initFormData).forEach((key) => {
    errorMessage[key] = '';
});

const requiredFields = [
    "model",
    "elevation",
    "base_price",
    "phase_sq_footage",
    "lot_size",
    "avg_lot_price",
    "lot_sq_footage",
    "modelId",
    "construction_start",
    "construction_end",
    "no_of_lots",
    "sold_lots",
    "closed_lots",
    "available_lots",
    "lot_image",
];
const numberTypeFields = [
    "base_price",
    "phase_sq_footage",
    "lot_size",
    "avg_lot_price",
    "lot_sq_footage",
    "no_of_lots",
    "sold_lots",
    "closed_lots",
    "available_lots",
];
const CreateLot = (props: any) => {
    const { currentUser } = useContext(AuthContext);
    const [formData, setFormData] = useState<FormData>(initFormData);
    const [lotId, setLotId] = useState<string | null>(null);
    const [validForm, setValidForm] = useState<ValidForm>(initValidForm);
    const [errorMsg, setErrorMsg] = useState<ErrorMessage>(errorMessage);
    const [productInfoData, setProductInfoData] = useState<ProductInfo[]>([]);
    const [product_information, setProductInformation] = useState<ProductInfo[]>([]);
    const [isFormSubmitLoading, setFormSubmitLoading] = useState<boolean>(false);
    
    const navigate = useNavigate();
    // eslint-disable-next-line no-useless-escape
    const isNumberValue = /^[\-\+]?[\d]+\.?(\d+)?$/;
    const location = useLocation();
            
    useEffect(() => {
        if (currentUser?.user_group !== 'admin') {
            navigate(`${ROUTES.HOME}`);
        }
        getAndSetProductInfo();
        if (props?.lotItem && props?.lotItem[0]) {
            setLotId(props?.lotItem[0]?.id);
            if (props?.lotItem && props?.lotItem[0]) {
                const updatedFormData = {
                    ...props?.lotItem[0],  // Spread the lot item properties
                    model: props?.lotItem[0]?.ModelName,  // Assuming ModelName is the correct field for model
                    modelId: props?.lotItem[0]?.model,  // Assuming model is the correct field for modelId
                    url: props?.lotItem[0]?.images?.[0]?.url,
                };
                setFormData(updatedFormData);
            } else {
                setFormData({ ...formData, model: props?.lotItem?.model });
            }
            const selectedProductInfo = props?.lotItem[0]?.product_information?.map((v : {id: string }) => v.id);
            getAndSetProductInfo(selectedProductInfo);          

        }
    }, []);
    const handleSelectDate = (e: any) => {
        const { name, value } = e; 
        if (value !== null) {
            const momentDate = moment(value).format('YYYY-MM-DD');
            setFormData((old) => ({ ...old, [name]: momentDate }));
        } else {
            setFormData((old) => ({ ...old, [name]: null }));
        }
        
    };
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleProductChange = (selected: ProductInfo[]) => {
        setProductInformation(selected);
        setFormData({ ...formData, product_information: selected });
    };

    const valid = () => {
        let valid = true;
        let checkValidForm = validForm;
        let makeErrorMsg = errorMsg;
        Object.keys(validForm).map((v) => {
            if (requiredFields?.includes(v)) {
                makeErrorMsg[v] = '';
                if (lotId && v!== 'lot_image' && (formData?.[v] === undefined || formData?.[v] === '' || formData?.[v] === null)) {
                    checkValidForm = { ...checkValidForm, [v]: false };
                    valid = false;
                } else if (!lotId && (formData?.[v] === undefined || formData?.[v] === '' || formData?.[v] === null)){
                    checkValidForm = { ...checkValidForm, [v]: false };
                    valid = false;
                } 
                else if (v === 'elevation' && formData?.[v] !== '' && formData?.[v]?.length > 2) {
                    valid = false;
                    checkValidForm = { ...checkValidForm, [v]: false }
                    makeErrorMsg[v] = 'The `Elevation Value` should not exceed 2 characters.';
                } else if (numberTypeFields.includes(v) && formData?.[v] !== '' && !isNumberValue?.test(formData?.[v])) {
                    valid = false;
                    checkValidForm = { ...checkValidForm, [v]: false }
                    makeErrorMsg[v] = 'Value should be number.';
                } else {
                    checkValidForm = { ...checkValidForm, [v]: true };
                }
            }
            return valid;
        });
        setErrorMsg({ ...errorMsg, ...makeErrorMsg });
        setValidForm({ ...validForm, ...checkValidForm });
        return valid;
    };


    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!valid()) {
            return true;
        }
        setFormSubmitLoading(true);
        const formDataToSend = new FormData();

        const postData = _.pick(formData, _.keys(initFormData));
        postData.product_information = product_information.filter((v: ExtendedProductInfo) => !v.__isNew__).map((v: ExtendedProductInfo) => v.value)?.join(',');
        postData.new_product_information = (product_information || []).filter((v: ExtendedProductInfo) => v.__isNew__).map((v: ExtendedProductInfo) => v.value);

        Object.entries(postData).forEach(([key, value] :any) => {
            if (key !== 'lot_image') {
                if (typeof value === 'string' || value instanceof Blob) {
                    formDataToSend.append(key, value);
                } else if (Array.isArray(value)) {
                    // Handle the array case if necessary, e.g., convert to a JSON string
                    formDataToSend.append(key, JSON.stringify(value));
                } else if (value !== null) {
                    // Handle other cases, such as numbers, booleans, etc.
                    formDataToSend.append(key, String(value));
                }
            }
        });
        
        formDataToSend.append('projectId', props?.projectId);
        if (lotId && props?.lotItem?.[0]) {
            formDataToSend.append('old_elevation', props?.lotItem?.[0]?.elevation);
            formDataToSend.append('old_model', props?.lotItem?.[0]?.model);
            formDataToSend.append('lot_detail_id', props?.lotItem?.[0]?.id);
        }  
        if(formData.lot_image) {
            Array.from(formData.lot_image).forEach((file, index) => {
                formDataToSend.append(`lot_images`, file as File);
            });
        }     

        // First check for '/phase-add'
        if (location.pathname.includes('/phase-add')) {
            props.setTempLotData(postData);
            props.handleCloseModalAction();
        }
        // Then check for '/phase'
        else if (location.pathname.includes('/phase')) {
            if (lotId && props?.lotItem?.[0]) {                
                updateLot(props.projectId, formDataToSend)
                    .then((result: AxiosResponse<ResponseLot>) => {
                        setFormSubmitLoading(false);
                        toast.success('Successfully updated details');
                        props.getPhaseData(props?.projectId);
                        props.handleCloseModalAction();
                    })
                    .catch((error: AxiosError<ErrorMessage>) => {
                        setFormSubmitLoading(false);
                        const errMsg = error?.response?.status === 409 ? error?.response?.data?.message : 'Something went wrong! Please try again';
                        toast.error(errMsg);
                    });
            } else {
                if (props?.projectId) {
                    postData.projectId = props?.projectId;
                    if (lotId) {
                        postData.lot_id = lotId;
                    }
                    createLot(formDataToSend)
                        .then((result: AxiosResponse<ResponseLot>) => {
                            if (result) {
                                setFormSubmitLoading(false);
                                toast.success('Successfully created phase');
                                props.setOpenLotContainer(false);
                                props.getPhaseData(props?.projectId);
                            }
                        })
                        .catch((error: AxiosError<ErrorMessage>) => {
                            setFormSubmitLoading(false);
                            const errMsg = error?.response?.status === 409 ? error?.response?.data?.message : 'Something went wrong! Please try again';
                            toast.error(errMsg);
                        });
                } else {
                    toast.error('Phase not found');
                }
            }
        }
        // Catch-all else
        else {
            toast.error('Invalid URL');
            setFormSubmitLoading(false);
        }
    };

    const getAndSetProductInfo = (selected: ProductInfo[] = []) => {
        fetchProductInformation()
             .then((response: AxiosResponse<ProductInfoApiResponse>) => {
                const product_information = response?.data?.product_information?.map((v) => {
                    return { label: v.name, value: v.id };
                });
                setProductInfoData(product_information);
                if (selected?.length > 0) {
                    const productInfo = product_information?.filter((v: any) => selected?.includes(v?.value));
                    setProductInformation(productInfo);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    
    const handleMultiInputChange = (e: any) => {
        const file = e.target.files; // Get the selected file      
        
        if (file) {
            setFormData((prevData: any) => ({
                ...prevData,
                lot_image:  file // Add or update the new image

            }));
            
        } else {
            console.error('No file selected or invalid index');
        }
    };
    return (
        <>
            <section className="content flex flex-col px-5 xl:px-[64px] lg:px-[34px] min-h-[calc(100vh-104px)]">
                <form className="w-full" onSubmit={handleSubmit}>
                    <div className="flex flex-wrap -mx-3 mb-6 border-b-2 border-orange-400">
                        <div className="w-full md:w-1/2 px-3 pb-5 md:mb-0">
                            <InputText
                                className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-transparent dark:text-[#746E6D]  dark:border-opacity-50"
                                inputName="model"
                                label="Model"
                                requiredLabel={true}
                                placeholder="Enter Model"
                                value={formData?.model || ''}
                                onChangeFunc={handleInputChange}
                                labelClassName="dark:text-[#746E6D]"
                                errorText={!validForm.model ? 'Model is required' : null}
                                readOnly = {!!props?.lotItem?.[0]?.ModelName}
                            />
                        </div>
                        <div className="w-full md:w-1/2 px-3 pb-5 md:mb-0">
                            <InputText
                                className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-transparent dark:text-[#746E6D]  dark:border-opacity-50"
                                inputName="modelId"
                                label="Model Id"
                                requiredLabel={true}
                                placeholder="Enter Model Id"
                                value={formData?.modelId || ''}
                                onChangeFunc={handleInputChange}
                                labelClassName="dark:text-[#746E6D]"
                                errorText={!validForm.modelId ? 'Model Id is required' : null}
                                readOnly = {!!props?.lotItem?.[0]?.model}
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 dark:text-[#746E6D]">Enter Lot Elevation Details</div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3">
                            <InputText
                                requiredLabel={true}
                                className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-transparent dark:text-[#746E6D]  dark:border-opacity-50"
                                inputName="elevation"
                                label="Elevation Name"
                                placeholder="Enter Elevation Name eg. A"
                                value={formData?.elevation || ''}
                                onChangeFunc={handleInputChange}
                                labelClassName="dark:text-[#746E6D]"
                                errorText={!validForm.elevation ? errorMsg?.elevation || 'Elevation Name is required' : null}
                            />
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                            <label className="form_label mr-2 pl-2 dark:text-[#746E6D]">Product Information</label>
                            <CreatableDropdown
                                key="product_information"
                                options={productInfoData}
                                onChange={handleProductChange}
                                value={product_information}
                                className="w-full"
                                classNamePrefix="dropdown"
                                isSelectAll={true}
                                menuPlacement={'bottom'}
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3">
                            <InputText
                                requiredLabel={true}
                                className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-transparent dark:text-[#746E6D]  dark:border-opacity-50"
                                inputName="base_price"
                                label="Base Avg Price"
                                placeholder="Enter Base Avg Price"
                                value={formData?.base_price}
                                onChangeFunc={handleInputChange}
                                labelClassName="dark:text-[#746E6D]"
                                errorText={!validForm.base_price ? errorMsg?.base_price || 'Base Avg Price is required' : null}
                            />
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                            <InputText
                                requiredLabel={true}
                                className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-transparent dark:text-[#746E6D]  dark:border-opacity-50"
                                inputName="phase_sq_footage"
                                label="Phase Sq. Footage"
                                placeholder="Enter Phase Sq. Footage"
                                value={formData?.phase_sq_footage}
                                onChangeFunc={handleInputChange}
                                labelClassName="dark:text-[#746E6D]"
                                errorText={!validForm.phase_sq_footage ? errorMsg?.phase_sq_footage || 'Phase Sq. Footage is required' : null}
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3">
                            <InputText
                                requiredLabel={true}
                                className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-transparent dark:text-[#746E6D]  dark:border-opacity-50"
                                inputName="lot_size"
                                label="Lot Size (frontage)"
                                placeholder="Enter Lot Size (frontage)"
                                value={formData?.lot_size}
                                onChangeFunc={handleInputChange}
                                labelClassName="dark:text-[#746E6D]"
                                errorText={!validForm.lot_size ? errorMsg?.lot_size || 'Lot Size (frontage) is required' : null}
                            />
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                            <InputText
                                requiredLabel={true}
                                inputName="avg_lot_price"
                                label="Average Lot Price"
                                className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-transparent dark:text-[#746E6D]  dark:border-opacity-50"
                                placeholder="Enter Average Lot Price"
                                value={formData?.avg_lot_price || ''}
                                onChangeFunc={handleInputChange}
                                labelClassName="dark:text-[#746E6D]"
                                errorText={!validForm.avg_lot_price ? errorMsg?.avg_lot_price || 'Average Lot Price is required' : null}
                            />
                        </div>
                    </div>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="grid gap-x-8 gap-y-4 grid-cols-2 w-full md:w-1/2 px-3 mb-6 md:mb-0 gap-3">
                               <div className="w-[100%]">
                                    <DateSelect
                                        required={true}
                                        inputName="construction_start"
                                        className="placeholder-[#746E6D] dark:bg-transparent dark:text-[#e2d7d6]  dark:border-opacity-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white w-full"
                                        label="Project Construction Start"
                                        placeholder={DATE_FORMAT}
                                        dateFormat="dd/MM/yyyy"
                                        selected={formData?.construction_start ? moment(formData?.construction_start).toDate() : null}
                                        errorText={!validForm.construction_start ? errorMsg?.construction_start || 'Start Date is required' : null}
                                        onChangeFunc={handleSelectDate}
                                        labelClassName="dark:text-[#746E6D]"
                                        icon={<FaRegCalendarAlt color="#746E6D"/>}
                                    />
                                </div>
                                <div className="w-[100%]">
                                    <DateSelect
                                        required={true}
                                        inputName="construction_end"
                                        className="placeholder-[#746E6D] dark:bg-transparent dark:text-[#e2d7d6]  dark:border-opacity-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white w-full"
                                        label="Project Construction End"
                                        placeholder={DATE_FORMAT}
                                        dateFormat="dd/MM/yyyy"
                                        selected={formData?.construction_end ? moment(formData?.construction_end).toDate() : null}
                                        errorText={!validForm.construction_end ? errorMsg?.construction_end || 'End Date is required' : null}
                                        onChangeFunc={handleSelectDate}
                                        labelClassName="dark:text-[#746E6D]"
                                        icon={<FaRegCalendarAlt color="#746E6D"/>}
                                    />
                                </div>
                            </div>

                            <div className="w-full md:w-1/2 px-3">
                                <InputText
                                    requiredLabel={true}
                                    className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-transparent dark:text-[#746E6D]  dark:border-opacity-50"
                                    inputName="no_of_lots"
                                    label="Number of Lots"
                                    placeholder="Enter Number of Lots"
                                    value={formData?.no_of_lots}
                                    onChangeFunc={handleInputChange}
                                    labelClassName="dark:text-[#746E6D]"
                                    errorText={!validForm.no_of_lots ? errorMsg?.no_of_lots || 'Number of Lots is required' : null}
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full md:w-1/2 px-3">
                                    <InputText
                                        requiredLabel={true}
                                        inputName="sold_lots"
                                        label="Sold Lots"
                                        className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-transparent dark:text-[#746E6D]  dark:border-opacity-50"
                                        placeholder="Enter Sold Lots"
                                        value={formData?.sold_lots || ''}
                                        onChangeFunc={handleInputChange}
                                        labelClassName="dark:text-[#746E6D]"
                                        errorText={!validForm.sold_lots ? errorMsg?.sold_lots || 'Number of Sold Lots is required' : null}
                                    />
                                </div>
                            <div className="w-full md:w-1/2 px-3">
                                    <InputText
                                        requiredLabel={true}
                                        rows={4}
                                        inputName="closed_lots"
                                        label="Closed Lots"
                                        className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-transparent dark:text-[#746E6D]  dark:border-opacity-50"
                                        placeholder="Enter Closed Lots"
                                        value={formData?.closed_lots || ''}
                                        onChangeFunc={handleInputChange}
                                        labelClassName="dark:text-[#746E6D]"
                                        errorText={!validForm.closed_lots ? errorMsg?.closed_lots || 'Number of Closed Lots is required' : null}
                                    />
                                </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3">
                                <InputText
                                    requiredLabel={true}
                                    className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-transparent dark:text-[#746E6D]  dark:border-opacity-50"
                                    inputName="available_lots"
                                    label="Available Lots"
                                    placeholder="Enter Available Lots"
                                    value={formData?.available_lots}
                                    onChangeFunc={handleInputChange}
                                    labelClassName="dark:text-[#746E6D]"
                                    errorText={!validForm.available_lots ? errorMsg?.available_lots ||'Available Lots is required' : null}
                                />
                            </div>
                            <div className="w-full md:w-1/2 px-3">
                            <InputText
                                requiredLabel={true}
                                rows={4}
                                inputName="lot_sq_footage"
                                label="Lot Sq. Footage"
                                className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-transparent dark:text-[#746E6D]  dark:border-opacity-50"
                                placeholder="Enter Lot Sq. Footage"
                                value={formData?.lot_sq_footage || ''}
                                onChangeFunc={handleInputChange}
                                labelClassName="dark:text-[#746E6D]"
                                errorText={!validForm.lot_sq_footage ? errorMsg?.lot_sq_footage || 'Lot Sq. Footage is required' : null}
                            />
                        </div>
                        </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3">
                            <label className={`form_label dark:text-[#746E6D]`}>Image<span className='text-lg text-red-600'> *</span></label>
                            <div className="h-[162px] overflow-y-auto">
                                <div className="flex items-center xl:flex-nowrap flex-wrap w-full pb-2" >
                                    <div className="w-[80%]">
                                        <input type="file"
                                            className='placeholder-[#746E6D] dark:bg-transparent dark:text-[#746E6D]  dark:border-opacity-50 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white w-full'
                                            onChange={handleMultiInputChange}
                                            id = 'fileUpload'
                                            multiple
                                            />
                                         <p className='text-sm text-red-600 dark:text-red-500 ms-[2px]'>{!validForm.lot_image ? 'Image is required' : null}</p>
                                    </div>                                            
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap justify-center">
                        <Button
                            disabled={isFormSubmitLoading}
                            type="submit"
                            className={`text-orange-500 border border-orange-500 flex text-center items-center justify-center py-[6px] px-16 rounded-md sm:rounded-lg  ${
                                isFormSubmitLoading ? ' opacity-60' : ''
                            }`}
                        >
                            {isFormSubmitLoading && (
                                <svg
                                    aria-hidden="true"
                                    className="inline w-4 h-6 text-gray-200 me-2 animate-spin dark:text-gray-600 fill-[#FA5A0A]"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentFill"
                                    />
                                </svg>
                            )}
                            {!isFormSubmitLoading && (
                                <>
                                    {lotId && 'Update'}
                                    {!lotId && 'Create'}
                                </>
                            )}
                        </Button>
                    </div>
                </form>
            </section>
        </>
    );
};

export default CreateLot;
