import React from 'react';
import DatePicker from 'react-datepicker';

import { IDateSelectProps } from './dateSelect.interface';
import ErrorMsg from '../errorMsg';

const DateSelect = React.forwardRef(
    (
        { inputName, placeholder, className,labelClassName, errorText, onKeyDown, required, label, tabIndex, icon, onChangeFunc, ...props }: IDateSelectProps,
        ref: any,
    ) => {
        const onChangeDate = (date: Date | null, event: React.SyntheticEvent<any, Event> | undefined) => {
            let customEvent: any = event;
            customEvent = {
                event,
                ...{ value: date, id: props.id, name: inputName },
            };
            onChangeFunc(customEvent);
        };

        return (
            <>
                {label && <label className={`form_label mb-1 ${labelClassName}`}>{label}</label>}
                {required && <span className="text-red-600">*</span>}
                <div className="rt-signup-date-box relative w-full">
                    <DatePicker
                        ref={ref}
                        placeholderText={placeholder}
                        onChange={onChangeDate}
                        className={className}
                        tabIndex={tabIndex}
                        id={props.id}
                        onKeyDown={(e:any) => {
                            if (e?.keyCode === 9 || e?.which === 9) {
                                ref.current.setOpen(false);
                            }
                        }}
                        autoComplete="off"
                        name={inputName}
                        dropdownMode="select"
                        popperPlacement="top-end"
                        {...props}
                    />
                    <div className="icon absolute right-5 top-1/2 -translate-y-1/2 text-lg text-grayText">{icon}</div>
                </div>
                {errorText && <ErrorMsg errorText={errorText} />}
            </>
        );
    },
);

DateSelect.defaultProps = {
    inputName: '',
    placeholder: '',
    className: '',
    labelClassName: '',
    label: '',
    errorText: null,
    id: '',
};
export default DateSelect;
