import { useContext, useEffect, useRef, useState } from 'react';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

import { LightStyle, DarkStyle } from './MapStyle';
import MarkerIcon from '../../../assets/img/marker-pin.svg';
import MarkerDarkIcon from '../../../assets/img/marker-pin-dark.svg';

import { DashboardContext } from '../../../context/DashboardContext';
import { BasicContext } from '../../../context/BasicContext';

const MyMap = ({ mapClassName, locations }: any) => {
    const ref = useRef<any>();
    const [map, setMap] = useState<google.maps.Map | undefined>();
    const { communityPageDetail, setCommunityPageDetail } = useContext(DashboardContext);
    const { isDarkTheme } = useContext(BasicContext);

    useEffect(() => {
        const styledMapType = new google.maps.StyledMapType(!isDarkTheme ? LightStyle : DarkStyle, {
            name: 'Styled Map',
        });
        const _map = new window.google.maps.Map(ref.current, {
            zoom: communityPageDetail ? 18 : 8,
            center: communityPageDetail
                ? { lat: Number(communityPageDetail?.latitude), lng: Number(communityPageDetail?.longitude) }
                : { lat: 43.27709500661392, lng: -79.91374670500528 },
            mapId: 'e0bd06e7f66cd15b',
            mapTypeId: google.maps.MapTypeId.ROADMAP,
        });

        _map.mapTypes.set('styled_map', styledMapType);
        _map.setMapTypeId('styled_map');
        setMap(_map);
    }, [locations, isDarkTheme]);

    useEffect(() => {
        if (map && ref.current) {
            handleMapMarker();
        }
    }, [map, ref.current, locations]);

    const handleMapMarker = () => {
        const infoWindow = new window.google.maps.InfoWindow({
            content: '',
            disableAutoPan: true,
        });

        const markers = locations.map((position: any, i: any) => {
            const beachFlagImg = document.createElement('img');
            beachFlagImg.src = !isDarkTheme ? MarkerIcon : MarkerDarkIcon;
            const marker = new window.google.maps.marker.AdvancedMarkerElement({
                position: { lat: Number(position?.latitude), lng: Number(position?.longitude) },
                content: beachFlagImg,
                title: position?.community_name
            });

            google.maps.event.addListener(marker, 'click', function () {
                setCommunityPageDetail(position)
                infoWindow.setMap(null);
                infoWindow.setContent(`<b> ${position?.community_name} </b>, ${position?.city?.name}`);
                infoWindow.open(map, marker);
            });
            return marker;
        });

        const renderer = {
            render: ({ count, position }: any) => {
                let clusterBgColor = '#343330';
                if (isDarkTheme) {
                    clusterBgColor = '#D3D1DC';
                }
                const svg = window.btoa(`
                                    <svg fill="${clusterBgColor}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
                                    <circle cx="120" cy="120" opacity=".6" r="70" />
                                    <circle cx="120" cy="120" opacity=".3" r="90" />
                                    <circle cx="120" cy="120" opacity=".2" r="110" />
                                    <circle cx="120" cy="120" opacity=".1" r="130" />
                                    </svg>`);
                return new google.maps.Marker({
                    position,
                    icon: {
                        url: `data:image/svg+xml;base64,${svg}`,
                        scaledSize: new google.maps.Size(50, 50),
                    },
                    label: {
                        text: String(count),
                        color: !isDarkTheme ? '#FFFFFF' : '#2B2B2B',
                        fontSize: '14px',
                    },
                    zIndex: 1000 + count,
                });
            },
        };

        new MarkerClusterer({ markers, map, renderer });
    };

    return (
        <>
            <div ref={ref} id="map" className={`rounded-xl ${mapClassName}`} />
        </>
    );
};

export default MyMap;
