/* eslint-disable array-callback-return */
import { useCallback, useContext, useEffect, useState } from 'react';
import { FiPhone } from 'react-icons/fi';
import { MdMailOutline, MdOutlineFileDownload } from 'react-icons/md';
import { IoLocationOutline, IoBagOutline } from 'react-icons/io5';
import Slider from 'react-slick';
import { FiArrowRight } from 'react-icons/fi';
import DataTable from 'react-data-table-component';
import { AxiosResponse,AxiosError } from 'axios';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ROUTES } from '../../../constants/routes';
import { useNavigate } from 'react-router-dom';
import PhaseSlider from '../../../components/Slider';
import CommonModal from '../../../components/CommonModal';
import { downloadContactDetails } from '../../../services/PhaseService';
import Loader from '../../../components/Loader';
import { Icon } from '../../../components/Icon';
import CreateLot from './CreateLot';
import no_image from '../../../assets/img/no-image.svg';
import CreatePhaseContacts from './CreatePhaseContacts';
import CreateModelChange from './CreateModelChange';
import DeleteModal from '../../../components/DeleteModal';
import { deletePhaseContact } from '../../../services/PhaseContactService';
import { toast } from 'react-toastify';
import { deleteLot, deleteLotClassDetail } from '../../../services/LotService';
import { AuthContext } from '../../../context/AuthContext';

interface PhaseContact {
    id: number;
    name: string;
    email: string;
    contact_no: number;
    address: string;
    city: {
        id: number;
        latitude: number;
        longitude: number;
        name: string;
        country_code: string;
    };
    state: {
        id: number;
        code: string;
        name: string;
        country: string;
    };
    country: {
        id: number;
        code: string;
        name: string;
        iso3: string;
    };
    division: string;
    position: string;
    office_location: string;
    organization: string;
    createdAt: string;
    updatedAt: string;
    area_of_expertise?: string;
    projectId: string;
    // Add other fields as necessary
}
interface Image {
    createdAt: string;
    updatedAt: string;
    id: number;
    type: string;
    url: string; // URL of the image
    // Add other properties if there are more
}

interface ContactDetails {
    id: number;
    name: string;
    position: string;
    organization: string;
    division: string;
    images?: Image[];
    email?: string;
    contact_no?: number;
    address?: string;
    country?: {
        id: number;
        code: string;
        name: string;
        iso3: string;
    }; // Optional country object
    area_of_expertise?: string;
    // Add other fields if necessary
}
interface ProductInfo {
    createdAt: string;
    icon: string;
    id: number;
    name: string;
    updatedAt: string;
}

interface LotDetails {
    id: number;
    avg_lot_price: number;
    base_price: number;
    elevation: string;
    createdAt: string;
    updatedAt: string;
    images: Image[];
    lot_id: number;
    lot_size: string;
    lot_sq_footage: string;
    phase_sq_footage: string;
    product_info: ProductInfo[];
    product_type: string | null;
    projectId: string;
    model: string;
}
interface ErrorMessage {
    [key: string]: string;
}
interface ResponsePhaseDelete{
    data:FormData;
    message: string;
}
const PhaseDetails = (props: any) => {
    const { phaseData, isLoading: mainLoading } = props;
    const { currentUser } = useContext(AuthContext);
    const [selectedLotDetails, setSelectedLotDetails] = useState<any | null>(null);
    const [selectedLotName, setSelectedLotName] = useState<any | null>(null);
    const [selectedLotEditLotItem, setSelectedLotEditLotItem] = useState<any>(null);
    const [selectedLotClass, setSelectedLotClass] = useState<any | null>(null);
    const [selectedContactDetails, setSelectedContactDetails] = useState<ContactDetails | null>(null);
    const [isOpenFullScreenSlider, setIsOpenFullScreenSlider] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [openLotContainer, setOpenLotContainer] = useState<boolean>(false);
    const [openPhaseContactContainer, setOpenPhaseContactContainer] = useState<boolean>(false);
    const [openModelChangeContainer, setOpenModelChangeContainer] = useState<boolean>(false);
    const [selectedPhaseContact, setSelectedPhaseContact] = useState<PhaseContact |null>(null);
    const [isDeleteModel, setIsDeleteModel] = useState<boolean>(false);
    const [deletePhaseContactId, setDeletePhaseContactId] = useState<number | null>(null);
    const [deleteLotId, setDeleteLotId] = useState<{ projectId: string; model: string } | null>(null);
    const [deleteLotDetailId, setDeleteLotDetailId] = useState<{ projectId: string; model: string ; elevation: string; } | null>(null);
    const navigate = useNavigate();
      
    useEffect(() => {
        const lotId = selectedLotDetails?.id;
        const updatedLotData = props?.phaseData?.lots?.filter((v: LotDetails) => v.id === lotId)?.[0];
        setSelectedLotDetails(updatedLotData);
    }, [props]);
    
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: phaseData?.plan_models?.length > 2 ? 3 : phaseData?.plan_models?.length,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    const columns = [
        {
            name: 'Name',
            selector: (row: PhaseContact) => row.name,
            sortable: true,
        },
        {
            name: 'Position',
            selector: (row: PhaseContact) => row?.position,
            sortable: true,
        },
        {
            name: 'Organization',
            selector: (row: PhaseContact) => row?.organization,
            sortable: true,
        },
        {
            name: 'Division',
            selector: (row: PhaseContact) => row?.division,
            sortable: true,
        },
        {
            name: 'Actions',
            button: true,
            cell: (row: PhaseContact) => (
                <>
                    <button
                        className="border border-orange-500 flex text-center items-center justify-center me-1 py-1 px-2 md:px-4 rounded-md"
                        onClick={() => setSelectedContactDetails(row)}
                    >
                        <span className="text-orange-500 text-sm mt-1 me-1">See details</span>
                        <FiArrowRight className="text-orange-500 text-lg self-center hidden md:block" />
                    </button>
                    {currentUser?.user_group === 'admin' && (
                        <>
                            <p
                                className="xl:text-lg text-base dark:text-white font-normal uppercase"
                                onClick={() => handleOpenPhaseContactModalAction(row)}
                            >
                                <Icon nameIcon={'FaEdit'} propsIcon={{ className: 'me-2 text-base text-orangeClr dark:text-white h-3 w-full' }} />
                            </p>
                            <div
                                className="cursor-pointer me-2"
                                onClick={() => {
                                    setDeletePhaseContactId(row?.id);
                                    setIsDeleteModel(true);
                                }}
                            >
                                <Icon nameIcon={'FaTrash'} propsIcon={{ className: 'me-2 text-base text-orangeClr dark:text-white h-3 w-full' }} />
                            </div>
                        </>
                    )}
                </>
            ),
        },
    ];
    useEffect(() => {
        setSelectedLotClass(selectedLotDetails?.[0] ?? null);
        
    }, [selectedLotDetails]);
    const handleDownloadCard = (selectedContactDetails: ContactDetails | null) => {
        if (selectedContactDetails) {
            
        setIsLoading(true);
        downloadContactDetails(selectedContactDetails.id)
            .then((response: any) => {
                const href = URL.createObjectURL(response);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', selectedContactDetails?.name?.trim() + '.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
                setIsLoading(false);
            })
            .catch((e) => {
                setIsLoading(false);
                console.log(e);
            });
        }
    };
    const handleEditLotDetailAction = (selectedLotItem: any, selectedClass = false, addNewClass = false) => {    
                   
        if (selectedClass) {
            setSelectedLotEditLotItem([selectedLotItem]);            

        } else if (addNewClass) {            
            const lotItem = {                
                0: {
                    ModelName: selectedLotItem?.ModelName,
                    model: selectedLotItem?.model,
                },
            };            
            setSelectedLotEditLotItem(lotItem);
        } else {
            setSelectedLotEditLotItem(selectedLotItem);
        }
        setOpenLotContainer(true);
    };


    const handleCloseModalAction = () => {
        setOpenLotContainer(false);
        setOpenPhaseContactContainer(false);
        setOpenModelChangeContainer(false);
        setSelectedLotEditLotItem(null);
        setSelectedPhaseContact(null);
        return true;
    };

    const handleOpenPhaseContactModalAction = (selected: PhaseContact) => {
        setOpenPhaseContactContainer(true);
        setSelectedPhaseContact(selected);
    };

    const handleEditModelAction = (selected: PhaseContact) => {        
        setSelectedLotEditLotItem([selected])
        setOpenModelChangeContainer(true);        
    };
    
    const handleModalClose = useCallback(
        (status: boolean) => () => {
            if (status) {
                if (deletePhaseContactId !== null) {
                    deletePhaseContact(deletePhaseContactId)
                        .then((result: AxiosResponse<ResponsePhaseDelete>) => {
                            setDeletePhaseContactId(null);
                            toast.success('Successfully delete phaseContact details');
                            props?.getPhaseData(phaseData?.projectId);
                        })
                        .catch((error: AxiosError<ErrorMessage>) => {
                            setDeletePhaseContactId(null);
                            const errMsg =
                                error?.response?.status === 409 ? error?.response?.data?.message : 'Something went wrong! Please try again';
                            toast.error(errMsg);
                        });
                    setIsDeleteModel(false);
                } else if (deleteLotId !== null) {
                    deleteLot(deleteLotId)
                        .then((result: any) => {
                            setDeleteLotId(null);
                            toast.success('Successfully delete lot details');                            
                            if (!result.isLastLot) {
                                props?.getPhaseData(`${phaseData?.projectId}`);
                            }   
                            else{
                                navigate(`${ROUTES.COMMUNITY}/${props?.phaseData?.community_id?.[0]?.uuid}`);
                            }        
                        })
                        .catch((error: any) => {
                            setDeleteLotId(null);
                            const errMsg =
                                error?.response?.status === 409 ? error?.response?.data?.message : 'Something went wrong! Please try again';
                            toast.error(errMsg);
                        });
                    setIsDeleteModel(false);
                } else if (deleteLotDetailId !== null) {
                    deleteLotClassDetail(deleteLotDetailId)
                        .then((result: any) => {
                            setDeleteLotDetailId(null);
                            toast.success('Successfully delete lot class details');
                            if (!result.isLastLot) {
                                props?.getPhaseData(`${phaseData?.projectId}`);
                            }
                            else{
                                navigate(`${ROUTES.COMMUNITY}/${props?.phaseData?.community_id?.[0]?.uuid}`);
                            }
                        })
                        .catch((error: any) => {
                            setDeleteLotDetailId(null);
                            const errMsg =
                                error?.response?.status === 409 ? error?.response?.data?.message : 'Something went wrong! Please try again';
                            toast.error(errMsg);
                        });
                    setIsDeleteModel(false);
                }
            } else {
                setIsDeleteModel(false);
            }
            setDeletePhaseContactId(null);
            setDeleteLotId(null);
            setDeleteLotDetailId(null);
        },
        [isDeleteModel],
    );

    return (
        <>
            <div className="content w-[100%]">
                {/* lot slider start */}
                {!selectedLotDetails && (
                    <div className="bg-white dark:bg-[#252525] mb-6 sm:rounded-2xl relative rounded-lg drop-shadow-[0px_4px_25px_rgba(116,110,109,0.04)] xl:p-6 sm:p-5 p-3 sm:pt-8 pt-4 lg:ms-6">
                        {mainLoading && <Loader />}
                        {currentUser?.user_group === 'admin' && (
                            <p
                                className="text-orangeClr leading-normal absolute right-5 sm:text-2xl text-xl font-normal cursor-pointer"
                                onClick={() => handleEditLotDetailAction(false, false, false)}
                            >
                                <Icon
                                    nameIcon={'IoMdAddCircle'}
                                    propsIcon={{ className: 'me-2 text-base text-orangeClr dark:text-white h-[22px] w-full' }}
                                />
                            </p>
                        )}
                        {mainLoading === false && (
                            <>
                                <p className="leading-normal sm:text-2xl text-xl font-normal sm:mb-4 mb-3 dark:text-white">Lot details</p>
                                {phaseData?.plan_models?.length > 0 ? (
                                    <div className="xs:w-[375px] w-[300px] min-w-full lot-slider">
                                        <Slider {...settings}>
                                            {phaseData?.plan_models?.map((lot: any, index: number) => {
                                                if (lot?.name) {
                                                    return (
                                                        <span key={index} className="relative">
                                                            <div
                                                                className={'cursor-pointer'}
                                                                onClick={() => {
                                                                    setSelectedLotDetails(phaseData?.lotDetails[`${phaseData?.projectId}_${lot?.id}`]);
                                                                    setSelectedLotName(lot?.name);
                                                                }}
                                                            >

                                                                <div className="px-2">
                                                                    <img
                                                                        className="xl:h-52 sm:h-40 h-48 w-full object-cover md:rounded-xl rounded-lg mb-3"
                                                                        src={phaseData?.lotDetails?.[`${phaseData?.projectId}_${lot?.id}`]?.[0]?.images?.[0]?.url || no_image}
                                                                        alt="slider"
                                                                        onError={({ currentTarget }) => {
                                                                            currentTarget.onerror = null;
                                                                            currentTarget.src = no_image;
                                                                        }}
                                                                    />
                                                                    <p className="xl:text-lg text-base dark:text-white font-normal uppercase">
                                                                        {lot?.name}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            {currentUser?.user_group === 'admin' && (
                                                                <>
                                                                    <div className="flex absolute bottom-2 right-2">
                                                                        <p
                                                                            className="xl:text-lg text-base dark:text-white font-normal uppercase"
                                                                            onClick={() => handleEditModelAction(phaseData?.lotDetails[`${phaseData?.projectId}_${lot?.id}`])}
                                                                            // onClick={() => handleEditLotDetailAction(phaseData?.lotDetails[`${phaseData?.projectId}_${lot?.id}`])}
                                                                        >
                                                                            <Icon
                                                                                nameIcon={'FaEdit'}
                                                                                propsIcon={{
                                                                                    className:
                                                                                        'me-2 text-base text-orangeClr dark:text-white h-4 w-full',
                                                                                }}
                                                                            />
                                                                        </p>
                                                                        <div
                                                                            className="cursor-pointer"
                                                                            onClick={() => {
                                                                                setDeleteLotId({ projectId: props?.phaseData?.projectId, model: lot?.id });
                                                                                setIsDeleteModel(true);
                                                                            }}
                                                                        >
                                                                            <Icon
                                                                                nameIcon={'FaTrash'}
                                                                                propsIcon={{
                                                                                    className:
                                                                                        'me-2 text-base text-orangeClr dark:text-white h-4 w-full',
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </span>
                                                    );
                                                }
                                            })}
                                        </Slider>
                                    </div>
                                ) : (
                                    <div className="flex flex-wrap">
                                        <div className="box-content h-full w-full text-center p-4 border-2 dark:text-white">
                                            <div style={{ padding: '20px' }}>Not found any lot.</div>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                )}
                {/* lot slider end */}

                {/* lot details start */}
                {selectedLotDetails && (
                    <div className="bg-white dark:bg-[#252525] mb-6 sm:rounded-2xl rounded-lg drop-shadow-[0px_4px_25px_rgba(116,110,109,0.04)] xl:p-6 sm:p-5 p-3 sm:pt-8 pt-4 lg:ms-6">
                        <div className="flex items-center text-center sm:mb-4 mb-3">
                            <p
                                className="text-grayTextClr leading-normal sm:text-2xl text-xl font-normal cursor-pointer"
                                onClick={() => setSelectedLotDetails(null)}
                            >
                                Lot details
                            </p>
                            <p className="sm:px-4 px-2 text-grayTextClr leading-normal sm:text-2xl text-xl font-normal">/</p>
                            <p className="text-orangeClr leading-normal sm:text-2xl text-xl font-normal">{selectedLotName}</p>
                        </div>
                        {selectedLotDetails ? (
                            <div className="flex xl:flex-nowrap flex-wrap items-center">
                                <div className="xxl:w-1/2 xl:w-[70%] w-full flex items-center ">
                                    <div className="sm:h-80 h-56 w-80 min-w-full lot-swiper">
                                        <PhaseSlider
                                            setIsOpen={() => setIsOpenFullScreenSlider(true)}
                                            images={selectedLotClass?.images}
                                        ></PhaseSlider>
                                    </div>
                                </div>
                                <div className="xl:w-1/2 w-full xl:mt-0 mt-4 xl:ms-6">
                                    <div className="flex items-center justify-between mb-7">
                                        <div className="xxl:max-w-[330px] xl:max-w-[230px] max-w-[80%]  overflow-auto bg-lighttop dark:bg-[#393939] flex items-center p-[4px] rounded-md">
                                            {selectedLotDetails ? (
                                                selectedLotDetails?.map((detail: LotDetails, index: number) => (
                                                    <button
                                                        className={`px-4 mx-1 py-[2px] text-sm font-normal text-orangeClr dark:text-white xxl:w-[86px] w-[60px] flex-none rounded-md text-center
                                                        ${detail?.elevation === selectedLotClass?.elevation ? ' bg-white dark:bg-[#4D4D4D] ' : ''}`}
                                                        key={index}
                                                        onClick={() => setSelectedLotClass(detail)}
                                                    >
                                                        {detail?.elevation}
                                                    </button>
                                                ))
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                        {currentUser?.user_group === 'admin' && (
                                            <div className="">
                                                <p
                                                    className="text-orangeClr leading-normal sm:text-2xl text-xl font-normal cursor-pointer"
                                                    onClick={() => handleEditLotDetailAction(selectedLotClass, false, true)}
                                                >
                                                    <Icon
                                                        nameIcon={'IoMdAddCircle'}
                                                        propsIcon={{ className: 'me-2 text-base text-orangeClr dark:text-white h-[22px] w-full' }}
                                                    />
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                    <div className="flex">
                                        <div className="w-1/3">
                                            <div className="sm:mb-5 mb-4">
                                                <p className="text-[#746E6D] leading-4 font-normal sm:text-sm text-xs mb-[7px]">No. Of Lots</p>
                                                <p className="mainTextClr dark:text-white leading-4 font-normal sm:text-lg text-base">
                                                    {Number(selectedLotClass?.no_of_lots)?.toLocaleString() || '-'}
                                                </p>
                                            </div>
                                            <div className="sm:mb-5 mb-4">
                                                <p className="text-[#746E6D] leading-4 font-normal sm:text-sm text-xs mb-[7px]">
                                                    Lot Size (frontage)
                                                </p>
                                                <p className="mainTextClr dark:text-white leading-4 font-normal sm:text-lg text-base">
                                                    {selectedLotClass?.lot_size || '-'}
                                                </p>
                                            </div>                                            
                                        </div>
                                        <div className="w-1/3">
                                            <div className="sm:mb-5 mb-4">
                                                <p className="text-[#746E6D] leading-4 font-normal sm:text-sm text-xs mb-[7px]">Average Lot Price</p>
                                                <p className="mainTextClr dark:text-white leading-4 font-normal sm:text-lg text-base">
                                                    {Number(selectedLotClass?.avg_lot_price)?.toLocaleString() || '-'}
                                                </p>
                                            </div>
                                            <div className="sm:mb-5 mb-4">
                                                <p className="text-[#746E6D] leading-4 font-normal sm:text-sm text-xs mb-[7px]">Lot Sq. Footage</p>
                                                <p className="mainTextClr dark:text-white leading-4 font-normal sm:text-lg text-base">
                                                    {selectedLotClass?.lot_sq_footage || '-'}
                                                </p>
                                            </div>
                                        </div>
                                        {currentUser?.user_group === 'admin' && (
                                            <div className="w-1/3 flex justify-end">
                                                <div className="flex">
                                                    <p
                                                        className="xl:text-lg text-base dark:text-white font-normal uppercase"
                                                        onClick={() => handleEditLotDetailAction(selectedLotClass, true)}
                                                    >
                                                        <Icon
                                                            nameIcon={'FaEdit'}
                                                            propsIcon={{ className: 'me-2 text-base text-orangeClr dark:text-white h-4 w-full' }}
                                                        />
                                                    </p>
                                                    <div
                                                        className="cursor-pointer"
                                                        onClick={() => {
                                                            if (selectedLotClass?.projectId !== undefined) {
                                                                setDeleteLotDetailId({ projectId: selectedLotClass.projectId, model: selectedLotClass.model, elevation: selectedLotClass.elevation});
                                                            } else {
                                                                setDeleteLotDetailId(null);
                                                            }
                                                            setIsDeleteModel(true);
                                                        }}
                                                    >
                                                        <Icon
                                                            nameIcon={'FaTrash'}
                                                            propsIcon={{ className: 'me-2 text-base text-orangeClr dark:text-white h-4 w-full' }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="mb-5">
                                                <p className="text-grayTextClr leading-4 font-normal text-sm mb-[7px]">Product Information</p>
                                                <div className="flex flex-wrap">
                                                    {selectedLotClass?.product_information?.length ? (
                                                        selectedLotClass?.product_information?.map((info: ProductInfo, index: number) => (
                                                            <span
                                                                className="bg-closebg dark:bg-[#746E6D] mb-2 dark:text-white text-xs text-grayTextClr rounded-full px-2 py-1 me-1 text-center leading-[14px]"
                                                                key={index}
                                                            >
                                                                {info?.name}
                                                            </span>
                                                        ))
                                                    ) : (
                                                        <p className='dark:text-white'>-</p>
                                                    )}
                                                </div>
                                            </div>
                                </div>
                            </div>
                        ) : (
                            <div className="flex flex-wrap">
                                <div className="box-content h-full w-full text-center p-4 border-2 dark:text-white">
                                    <div style={{ padding: '20px' }}>Not found any lot details.</div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {/* lot details end */}

                {/* phase contact table start */}
                {!selectedContactDetails && (
                    <div className="bg-white dark:bg-[#252525] mb-6 sm:rounded-2xl rounded-lg drop-shadow-[0px_4px_25px_rgba(116,110,109,0.04)] xl:p-6 sm:p-5 p-3 sm:pt-8 pt-4 lg:ms-6">
                        {mainLoading && <Loader />}
                        {mainLoading === false && (
                            <>
                                <div className="sm:mb-6 mb-3 flex justify-between">
                                    <h2 className="capitalize sm:text-[24px] text-xl dark:text-white font-normal ">Project Contacts</h2>
                                    {currentUser?.user_group === 'admin' && (
                                        <p
                                            className="text-orangeClr leading-normal sm:text-2xl text-xl font-normal cursor-pointer"
                                            onClick={() => setOpenPhaseContactContainer(true)}
                                        >
                                            <Icon
                                                nameIcon={'IoMdAddCircle'}
                                                propsIcon={{ className: 'me-2 text-base text-orangeClr dark:text-white h-[22px] w-full' }}
                                            />
                                        </p>
                                    )}
                                </div>
                                {phaseData?.phaseContacts?.length > 0 ? (
                                    <DataTable className="table-detail" columns={columns} data={phaseData?.phaseContacts} pagination />
                                ) : (
                                    <div className="flex flex-wrap">
                                        <div className="box-content h-full w-full text-center p-4 border-2 dark:text-white">
                                            <div style={{ padding: '20px' }}>Not found any project contact.</div>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                )}
                {/* phase contact table end */}

                {/* phase contact card start */}
                {selectedContactDetails && (
                    <div className="bg-white dark:bg-[#252525] sm:rounded-2xl rounded-lg drop-shadow-[0px_4px_25px_rgba(116,110,109,0.04)] xl:p-6 sm:p-5 p-3 sm:pt-8 pt-6 lg:ms-6">
                        <h2 className="uppercase sm:text-2xl text-lg sm:mb-6 mb-3 font-normal text-[#746E6D]">
                            <span onClick={() => setSelectedContactDetails(null)} className="cursor-pointer">
                                {' '}
                                Project Contacts
                            </span>{' '}
                            / <span className="text-[#FA5A0A]"> {selectedContactDetails?.name}</span>
                        </h2>

                        <div className="flex xxl:flex-nowrap flex-wrap">
                            <div className="w-full pe-6 xxl:mb-0 mb-4">
                                <div className="flex items-center sm:pb-6 pb-4 border-b border-[#EAE9E9] dark:border-[#5C5C5C]">
                                    <img
                                        className="sm:w-[64px] w-[50px] sm:h-[64px] h-[50px] object-cover object-center sm:me-4 me-2"
                                        src={selectedContactDetails?.images?.[0]?.url || no_image}
                                        alt="user-img"
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = no_image;
                                        }}
                                    />
                                    <p className="uppercase sm:text-2xl text-xl font-normal dark:text-white">{selectedContactDetails?.name}</p>
                                </div>
                                <div className="flex sm:flex-nowrap flex-wrap sm:py-6 py-4 border-b border-[#EAE9E9] dark:border-[#5C5C5C] sm:mb-[50px] mb-6">
                                    <div className="sm:w-1/2 w-full sm:mb-0 mb-3">
                                        <div className="flex sm:pb-6 pb-3 items-center">
                                            <MdMailOutline className="sm:me-3 me-2 sm:text-xl text-lg dark:text-white" />
                                            <p className="sm:text-lg text-base font-normal dark:text-white">{selectedContactDetails?.email}</p>
                                        </div>
                                        <div className="flex items-center">
                                            <FiPhone className="sm:me-3 me-2 sm:text-xl text-lg dark:text-white" />
                                            <p className="sm:text-lg text-base font-normal dark:text-white">{selectedContactDetails?.contact_no}</p>
                                        </div>
                                    </div>
                                    <div className="sm:w-1/2 w-full">
                                        <div className="flex sm:pb-6 pb-3 items-center">
                                            <IoBagOutline className="sm:me-3 me-2 sm:text-xl text-lg dark:text-white" />
                                            <p className="sm:text-lg text-base font-normal dark:text-white">{selectedContactDetails?.position}</p>
                                        </div>
                                        <div className="flex items-center">
                                            <IoLocationOutline className="sm:me-3 me-2 sm:text-xl text-lg dark:text-white" />
                                            <p className="sm:text-lg text-base font-normal dark:text-white">
                                                {selectedContactDetails?.country?.name}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <p className="text-[#746E6D] leading-4 font-normal text-sm mb-[10px]">Areas of Expertise Badges</p>
                                    <div className="flex flex-wrap">
                                    {selectedContactDetails?.area_of_expertise && selectedContactDetails.area_of_expertise.trim() !== '' ? (
                                        selectedContactDetails.area_of_expertise.split(',').map((expertise: string, index: number) => (
                                            <span
                                                className="bg-closebg dark:bg-[#746E6D] dark:text-white text-xs mb-2 text-grayTextClr rounded-full px-2 py-1 me-1 text-center leading-[14px]"
                                                key={index}
                                            >
                                                {expertise.trim()}  {/* Displaying the expertise value directly */}
                                            </span>
                                        ))
                                    ) : <p className='dark:text-white'> - </p>}
                                        </div>
                                </div>
                            </div>

                            <div className="xl:min-w-[363px] min-w-full rounded-lg border border-[#EAE9E9] dark:border-[#393939]">
                                <div className=" sm:px-4 px-3 py-3 bg-[#F7F7F7] dark:bg-[#4D4D4D] rounded-t-lg ">
                                    <h4 className="sm:text-base text-sm font-normal uppercase dark:text-white">{selectedContactDetails?.name} </h4>
                                </div>
                                <div className="sm:p-4 p-3">
                                    <div className="pb-4 border-b border-[#EAE9E9] dark:border-[#393939]">
                                        <h2 className="uppercase sm:text-2xl text-xl font-medium sm:pb-4 pb-2 dark:text-white">
                                            {selectedContactDetails?.name}
                                        </h2>
                                        <p className="sm:text-sm text-xs text-[#746E6D] font-normal">
                                            {selectedContactDetails?.position} / {selectedContactDetails?.division}
                                        </p>
                                    </div>
                                    <div className="sm:pt-4 pt-3">
                                        <div className="flex sm:pb-4 pb-3 items-center">
                                            <MdMailOutline className=" me-2 text-base dark:text-white" />
                                            <p className="sm:text-base text-sm sm:mt-0 mt-1 font-normal dark:text-white">
                                                {selectedContactDetails?.email}
                                            </p>
                                        </div>
                                        <div className="flex sm:pb-4 pb-3 items-center">
                                            <FiPhone className=" me-2 text-base dark:text-white" />
                                            <p className="sm:text-base text-sm sm:mt-0 mt-1 font-normal dark:text-white">
                                                {selectedContactDetails?.contact_no}
                                            </p>
                                        </div>
                                        <div className="flex items-center">
                                            <IoLocationOutline className=" me-2 text-base dark:text-white" />
                                            <p className="sm:text-base text-sm sm:mt-0 mt-1 font-normal dark:text-white">
                                                {selectedContactDetails?.address}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="sm:mt-10 mt-6">
                                        <button
                                            className={`
                                                w-full border border-[#FA5A0A] p-2 sm:rounded-lg rounded-md text-[#FA5A0A] flex justify-center
                                                ${isLoading ? ' opacity-60' : ''}`}
                                            onClick={() => handleDownloadCard(selectedContactDetails)}
                                        >
                                            {isLoading && (
                                                <svg
                                                    aria-hidden="true"
                                                    className="inline w-4 h-6 text-gray-200 me-2 animate-spin dark:text-gray-600 fill-[#FA5A0A]"
                                                    viewBox="0 0 100 101"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                        fill="currentColor"
                                                    />
                                                    <path
                                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                        fill="currentFill"
                                                    />
                                                </svg>
                                            )}
                                            {!isLoading && (
                                                <>
                                                    <MdOutlineFileDownload className=" me-2 sm:text-xl text-lg" />
                                                    <h4 className="sm:text-base text-sm sm:mt-0 mt-[1px]">Download Card</h4>
                                                </>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {/* phase contact card end */}
                {isOpenFullScreenSlider && (
                    <CommonModal
                        handleClose={() => setIsOpenFullScreenSlider(false)}
                        title=""
                        size={'max-w-6xl w-[490px]'}
                        headerClass={'text-center'}
                        modalBodyClass={'lot-popup'}
                        closeButtonClass={{ buttonStyle: ' top-[-3%] right-[-3%]', iconStyle: 'text-white' }}
                    >
                        {' '}
                        <PhaseSlider sliderClass={' fullscreen-slider'} images={selectedLotClass?.images}></PhaseSlider>
                    </CommonModal>
                )}
            </div>
            {openLotContainer && (
                <CommonModal handleClose={handleCloseModalAction} title="" size={'max-w-6xl w-[100%]'} headerClass={'text-center'}>
                    <CreateLot
                        setOpenLotContainer={setOpenLotContainer}
                        handleCloseModalAction={handleCloseModalAction}
                        projectId={phaseData?.projectId}
                        getPhaseData={props.getPhaseData}
                        lotItem={selectedLotEditLotItem || null}
                    />
                </CommonModal>
            )}
            {openModelChangeContainer && (
                <CommonModal handleClose={handleCloseModalAction} title="" size={'max-w-6xl w-[30%]'} headerClass={'text-center'}>
                    <CreateModelChange
                        setOpenModelChangeContainer={setOpenModelChangeContainer}
                        handleCloseModalAction={handleCloseModalAction}
                        projectId={phaseData?.projectId}
                        getPhaseData={props.getPhaseData}
                        lotItem={selectedLotEditLotItem || null}
                    />
                </CommonModal>   
            )}            
            {openPhaseContactContainer && (
                <CommonModal handleClose={handleCloseModalAction} title="" size={'max-w-6xl w-[100%]'} headerClass={'text-center'}>
                    <CreatePhaseContacts
                        setOpenPhaseContactContainer={setOpenPhaseContactContainer}
                        handleCloseModalAction={handleCloseModalAction}
                        phaseId={phaseData?.projectId}
                        getPhaseData={props.getPhaseData}
                        phaseContact={selectedPhaseContact || null}
                    />
                </CommonModal>
            )}
            <DeleteModal show={isDeleteModel} handleClose={handleModalClose} />
        </>
    );
};

export default PhaseDetails;
