import { axiosInterceptor } from './interceptors';
import { API } from '../constants/api';

export const listCommunity = async (data: any) => {
    const response = await axiosInterceptor.post(`${API.COMMUNITY.LIST}`, data);
    return response;
};

export const communityDetails = async (id: any) => {
    const response = await axiosInterceptor.get(`${API.COMMUNITY.LIST}?id=${id}`);
    return response;
};

export const createCommunity = async (data: any) => {   
      
    const response = await axiosInterceptor.post(`${API.COMMUNITY.CREATE}`, data);
    return response;
};

export const updateCommunity = async (id: string, data: any) => {    
    const response = await axiosInterceptor.patch(`${API.COMMUNITY.UPDATE}/${id}`, data);
    return response;
};
export const deleteCommunity = async (id: number) => {
    const response = await axiosInterceptor.delete(`${API.COMMUNITY.DELETE}/${id}`);
    return response;
};
