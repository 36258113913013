import { memo, useContext, useEffect, useState } from 'react';
import { listCommunity } from '../../../services/CommunityService';
import MultiSelect from '../../../components/MultiSelect/MultiSelect';
import { DashboardContext } from '../../../context/DashboardContext';
import { listPhase } from '../../../services/PhaseService';
import SearchBar from '../../../components/SearchBar/SearchBar';
interface Response{
    community_name: string,
    id:string,
    projectId:string | null,
    uuid:string
}
interface CommunityOption {
    value: string;
    label: string;
    projectId: string;
}
interface PhaseResponse {
    phase_name: string;
    id: string;
}
const TopSearchBox = () => {
    const [communityDrpData, setCommunityDropDown] = useState([]);
    const { community_id, setCommunityId, phase_id, status, setStatus, community_name } = useContext(DashboardContext);
    const [isDisabledCommunityDrp, setIsDisabledCommunityDrp] = useState<boolean>(community_name?.length > 0);
    const [isDisabledCommunitySearch, setIsDisabledCommunitySearch] = useState<boolean>(community_id?.length > 0);
    useEffect(() => {
        listCommunity({ is_drp_listing: true })
            .then((response) => {
                
                const communityData = response?.data.map((v: Response) => {
                    return { value: v.id, label: v.community_name,projectId:v.projectId };
                });
                setCommunityDropDown(communityData);
            })
            .catch(console.log);
        const phaseFilter = { is_drp_listing: true, projectId: '' };
        const comArray = community_id?.map((v: CommunityOption) => v.projectId);
        

        if (comArray?.length > 0) {
            phaseFilter.projectId = comArray?.join(',');
        }
        listPhase(phaseFilter)
            .then((response) => {
                
                response?.data.map((v: PhaseResponse) => {
                    return { value: v.id, label: v.phase_name };
                });
            })
            .catch(console.log);
    }, [community_id, phase_id]);

    useEffect(() => {        
        setIsDisabledCommunityDrp(community_name?.length > 0);
        setIsDisabledCommunitySearch(community_id?.length > 0);
    });

    const handleCommunityChange = (selected: CommunityOption) => {
        setCommunityId(selected);
    };
    const handleStatusChange = (selected: string) => {
        setStatus(selected);
    };

    return (
        <>
            <div className="border border-gray-200 bg-white dark:bg-[#252525] dark:border-[#252525] rounded-lg py-4 lg:px-6 px-3">
                <div className="flex w-full">
                    <SearchBar isDisabledCommunitySearch={isDisabledCommunitySearch} />
                </div>
                <div className="flex pt-6 text-center items-center xl:flex-nowrap flex-wrap gap-x-2">
                    <div className="flex sm:flex-nowrap flex-wrap items-center xl:mb-0 sm:mb-4 mb-6 ">
                        <div className="min-w-fit xxl:me-14 me-6 text-start">
                            <p className="p-0 m-0 font-normal text-black-900 text-base uppercase dark:text-white sm:mb-0 mb-2">Filter by:</p>
                        </div>
                        <div className="flex xs:flex-nowrap flex-wrap items-center sm:w-auto w-full">
                            <p className="p-0 m-0 xxl:me-4 me-2 font-normal text-sm w-max dark:text-[#746E6D] xs:mb-0 mb-1">Project Status</p>
                            <div className="bg-orange-100 py-1 rounded-md  text-sm flex ps-1 justify-center dark:bg-[#393939]">
                                <button
                                    className={`text-sm font-normal text-[#746E6D] p-1 pt-[6px] rounded-md dark:text-[#878584]  xxl:w-[134px] lg:w-[115px] w-[80px]
                                        ${status === '' ? 'bg-white text-orangeClr dark:bg-[#4D4D4D] dark:!text-white' : ''})
                                    `}
                                    onClick={() => handleStatusChange('')}
                                >
                                    All
                                </button>
                                <button
                                    className={
                                        `text-sm font-normal text-[#746E6D] dark:bg-[#393939] p-1 pt-[6px] xxl:w-[134px] lg:w-[115px] w-[80px] mx-3 rounded-md
                                        ${status === 'ACTIVE' ? ' bg-white text-orangeClr dark:bg-[#4D4D4D] dark:!text-white' : ''}`
                                    }
                                    onClick={() => handleStatusChange('ACTIVE')}
                                >
                                    Active
                                </button>
                                <button
                                    className={
                                        `text-sm font-normal text-[#746E6D] dark:bg-[#393939] p-1 pt-[6px] xxl:w-[134px] lg:w-[115px] w-[80px] mx-1 rounded-md
                                        ${status === 'CLOSED' ? ' bg-white text-orangeClr dark:bg-[#4D4D4D] dark:!text-white' : ''}`
                                    }
                                    onClick={() => handleStatusChange('CLOSED')}
                                >
                                    Closed
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="flex lg:mb-0 mb-3 flex-1">
                        <div className="xl:w-3/5 md:w-3/4 w-full">
                            <div className="relative text-left">
                                <MultiSelect
                                    key="community_id"
                                    options={communityDrpData}
                                    isDisabled={isDisabledCommunityDrp}
                                    onChange={handleCommunityChange}
                                    value={community_id}
                                    className="w-full"
                                    placeHolder={"Select Community Name"}
                                    classNamePrefix="dropdown"
                                    isSelectAll={true}
                                    menuPlacement={'bottom'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default memo(TopSearchBox);
