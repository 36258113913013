export const API = {
    USER: {
        LOGIN: '/auth/login',
        LOGOUT: '/auth/logout',
        GET: '/user/get',
        CODE: "/auth/code",
    },
    COMMUNITY: {
        LIST: '/community',
        CREATE:'/community/create',
        UPDATE:'/community/update',
        DELETE:'/community/delete'
    },
    PHASE: {
        LIST: '/phase',
        DOWNLOAD: '/phase/download-contact/',
        CREATE:'/phase/create',
        UPDATE:'/phase/update',
        DELETE:'/phase/delete',
    },
    LOCATION: {
        COUNTRY_LIST: '/fetch-country',
        STATES_LIST: '/fetch-state',
        CITY_LIST: '/fetch-city',
    },
    COMMON: {
        FETCH_PRODUCT_INFORMATION: '/fetch-common-utility?type=PRODUCT_INFO_DATA',
        COMMUNITY_PRODUCT_INFO_DATA: '/fetch-common-utility?type=COMMUNITY_PRODUCT_INFO_DATA',
        FETCH_PLAN_MODELS: '/fetch-common-utility?type=PLAN_MODELS',
        AREA_OF_EXPERTISE: '/fetch-common-utility?type=AREA_OF_EXPERTISE',
    },
    LOT: {
        LOT_CREATE: '/lot/create',
        LOT_UPDATE: '/lot/update',
        LOT_DELETE: '/lot/delete',
        LOT_DETAIL_DELETE: '/lot/delete_lot_class',
        LOT_UPDATE_MODELNAME: 'lot/update-model'
    },
    PHASE_CONTACT: {
        CREATE: '/phase_contact/create',
        UPDATE: '/phase_contact/update',
        DELETE: '/phase_contact/delete',
    },
};
