export const LightStyle = [
    {
        featureType: 'administrative',
        elementType: 'all',
        stylers: [
            {
                visibility: 'on',
            },
            {
                lightness: 33,
            },
        ],
    },
    {
        featureType: 'administrative.province',
        elementType: 'all',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'all',
        stylers: [
            {
                lightness: '50',
            },
            {
                visibility: 'simplified',
            },
        ],
    },
    {
        featureType: 'poi.attraction',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'landscape.man_made',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#ffffff',
            },
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'poi.business',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi.medical',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi.place_of_worship',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi.school',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi.sports_complex',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'landscape',
        elementType: 'all',
        stylers: [
            {
                color: '#F9F5ED',
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
            {
                visibility: 'off',
            },
            {
                color: '#c5dac6',
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off',
            },
            {
                lightness: 20,
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'all',
        stylers: [
            {
                lightness: 20,
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#c5c6c6',
            },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [
            {
                color: '#e4d7c6',
            },
        ],
    },
    {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [
            {
                color: '#fbfaf7',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'all',
        stylers: [
            {
                visibility: 'on',
            },
            {
                color: '#AEE0F4',
            },
        ],
    },
];

export const DarkStyle = [
    {
        featureType: 'administrative',
        elementType: 'all',
        stylers: [
            { visibility: 'on' },
            { lightness: 33 },
            { invert_lightness: true },
        ],
    },
    {
        featureType: 'administrative.province',
        elementType: 'all',
        stylers: [
            { visibility: 'on' },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'all',
        stylers: [
            { lightness: -50 },
            { visibility: 'simplified' },
        ],
    },
    {
        featureType: 'poi.attraction',
        stylers: [
            { visibility: 'off' },
        ],
    },
    {
        featureType: 'landscape.man_made',
        elementType: 'geometry.fill',
        stylers: [
            { color: '#000000' },
            { visibility: 'on' },
        ],
    },
    {
        featureType: 'poi.business',
        stylers: [
            { visibility: 'off' },
        ],
    },
    {
        featureType: 'poi.medical',
        stylers: [
            { visibility: 'off' },
        ],
    },
    {
        featureType: 'poi.place_of_worship',
        stylers: [
            { visibility: 'off' },
        ],
    },
    {
        featureType: 'poi.school',
        stylers: [
            { visibility: 'off' },
        ],
    },
    {
        featureType: 'poi.sports_complex',
        stylers: [
            { visibility: 'off' },
        ],
    },
    {
        featureType: 'landscape',
        elementType: 'all',
        stylers: [
            { color: '#333333' },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
            { visibility: 'off' },
            { color: '#121212' },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'labels',
        stylers: [
            { visibility: 'off' },
            { lightness: 20 },
        ],
    },
    {
        featureType: 'road',
        elementType: 'all',
        stylers: [
            { lightness: -70 },
            { saturation: -80 },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
            { color: '#C27032' },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [
            { color: '#666666' },
        ],
    },
    {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [
            { color: '#7D5333' },
        ],
    },
    {
        featureType: 'water',
        elementType: 'all',
        stylers: [
            { visibility: 'on' },
            { color: '#2B2B2B' },
        ],
    },
];
