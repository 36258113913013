export const APP_ROOT = '/';

export const ROUTES = {
    HOME: `${APP_ROOT}`,
    CALLBACK: `${APP_ROOT}callback`,
    LOGIN: `${APP_ROOT}login`,
    COMMUNITY: `${APP_ROOT}community`,
    COMMUNITY_ADD: `${APP_ROOT}community-create`,
    PHASE: `${APP_ROOT}phase`,
    PHASE_ADD: `${APP_ROOT}phase-add`,
    PHASE_UPDATE: `${APP_ROOT}phase-update`,
};
