import React, { Fragment } from 'react';

import ErrorMsg from '../errorMsg';

import { IInputTextProps } from './InputText.interface';

const InputText = ({
    inputName,
    placeholder,
    errorText,
    asTextarea,
    labelcontainer,
    className,
    regularExp,
    label,
    tabIndex,
    maxLength,
    icon,
    min,
    max,
    disabled,
    inputType,
    minLength,
    iconShow,
    onIconClick,
    onChangeFunc,
    onBlur,
    onFocus,
    required,
    requiredAfter,
    requiredLabel,
    readOnly,
    rows,
    labelClassName,
    ...props
}: IInputTextProps) => {
    const regExpArray: any = {
        number: /(^[0-9]+$|^$)/,
        letters: /^[A-za-z]+$/,
        alphanumeric: /^[ A-Za-z0-9_@.]*$/,
        regularExp: regularExp,
    };
    const typeArr = ['text', 'email', 'password', 'file', 'textarea'];
    const HandleChange = (e: any) => {
        var testValueType: any = regularExp === '' ? inputType : 'regularExp';
        var valueTypeMatch = testValueType !== '' && !typeArr.includes(testValueType) ? regExpArray[testValueType].test(e.target.value) : true;
        if (e.target.value === '' || valueTypeMatch) {
            onChangeFunc(e);
        }
    };

    return (
        <>
            <div className={errorText ? 'login-form' : ''}>
                {required && <span className="text-red-600">*</span>}
                {label && <label className={`form_label ${labelClassName}`}>{label}</label>}
                {requiredAfter && <span className="text-red-600 ms-[2px]">*</span>}
                {requiredLabel && <span className="text-red-600 ms-[2px]">*</span>}
                {asTextarea === 'textarea' ? (
                    <textarea
                        name={inputName}
                        className={`${className} placeholder-[#746E6D] dark:bg-transparent dark:text-[#e2d7d6]  dark:border-opacity-50`}
                        placeholder={placeholder}
                        onChange={HandleChange}
                        tabIndex={tabIndex}
                        rows={rows || 5}
                        maxLength={maxLength}
                        minLength={minLength}
                        disabled={disabled}
                        autoComplete="off"
                        required={required || requiredAfter}
                        {...props}
                    />
                ) : (
                    <input
                        type={inputType}
                        name={inputName}
                        className={`${className} placeholder-[#746E6D] dark:bg-transparent dark:text-[#e2d7d6]  dark:border-opacity-50`}
                        placeholder={placeholder}
                        onChange={HandleChange}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        tabIndex={tabIndex}
                        min={min}
                        maxLength={maxLength}
                        minLength={minLength}
                        max={max}
                        disabled={disabled}
                        autoComplete="off"
                        required={required || requiredAfter}
                        readOnly={readOnly}
                        {...props}
                    />
                )}
            </div>
            {errorText && <ErrorMsg errorText={errorText} />}
        </>
    );
};

InputText.defaultProps = {
    inputName: '',
    placeholder: '',
    errorText: '',
    asTextarea: '',
    inputType: '',
    label: '',
    regularExp: '',
    id: '',
    icon: '',
    labelcontainer: '',
    maxLength: null,
    className: '',
    labelClassName: '',
    readOnly: false,
    onChangeFunc: () => void 0,
    onBlur: () => void 0,
};

export default InputText;
