const side = 'left';

const Drawer = ({ children, open }: any) => {
    return (
        <div
            id={`dialog-${side}`}
            onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
            }}
            className={
                `border border-gray-100 bg-white dark:bg-[#252525] lg:order-1 order-2 dark:border-[#252525] h-full  lg:h-[65vh] rounded-lg  sm:pt-7 pt-5 sm:pb-6 pb-4 relative transition-all duration-75 ease-linear
                ${!open ? 'w-40 ' : 'w-full lg:w-[45%] xl:w-[430px]'}`
            }
        >
            {children}
        </div>
    );
};

export default Drawer;
