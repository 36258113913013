import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';


export const BasicContext = createContext<any>(null);

export const BasicProvider = (props: any) => {
    const { children } = props;
    const [isDarkTheme, setIsDarkTheme] = useState<boolean>(false);

    const value = {
        isDarkTheme,
        setIsDarkTheme,
    };

    return <BasicContext.Provider value={value}>{children}</BasicContext.Provider>;
};

BasicProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
