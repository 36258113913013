/* eslint-disable array-callback-return */
import { useContext, useEffect, useState, ChangeEvent, FormEvent } from 'react';
import Button from '../../components/Button';
import InputText from '../../components/InputText/InputText';
import { Radio } from 'flowbite-react';
import { Icon } from '../../components/Icon';
import CommonModal from '../../components/CommonModal';
import { GoArrowLeft } from 'react-icons/go';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import CreateLot from './component/CreateLot';
import Breadcrumbs from '../../components/Breadcrumbs';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { AuthContext } from '../../context/AuthContext';
import { createPhase, fetchPhaseDetails, updatePhase } from '../../services/PhaseService';
import no_image from '../../assets/img/no-image.svg';
import { createLot } from '../../services/LotService';
interface PlanModel{
    label:string;
    value: number;
    __isNew__?: boolean;
}
interface CommunityDataDetails {
    acquisition_date: string; 
    acreage: string;
    address: string;
    city: string;
    community_name: string;
    country: string;
    createdAt: string; 
    division: string;
    id: string;
    image:string;
    latitude: string;
    legal_name: string;
    longitude: string;
    marketing_name: string;
    operating_name: string;
    product_info: string;
    projectId: string;
    project_name: string;
    project_start_date: string; 
    state: string;
    status: string;
    updatedAt: string; 
    uuid: string;
  }
  interface FormData {
    community_uuid?: string;
    createdAt?: string; 
    id?: string;
    phase_name: string;
    plan_model?: PlanModel[];
    status?: string;
    updatedAt?: string; 
    image: string;
    uuid?: string;
    project_name: string;
    operating_name: string;
  }
  interface ValidForm {
    [key: string]: boolean;
  }
  interface ErrorMsg {
    [key: string]: string;
  }
const breadCrumbData = [
    { name: 'Home', link: ROUTES?.HOME },
    { name: 'Phase', link: ROUTES?.HOME },
    { name: 'Create', link: '' },
];
const initFormData: FormData = {
    phase_name: '',    
    status: '',    
    image: '',
    project_name: '',
    operating_name: '',    
};
const initValidForm: ValidForm = {};
const errorMessage : ErrorMsg = {};
Object.keys(initFormData).forEach((key) => {
    initValidForm[key] = true;
});
Object.keys(initFormData).forEach((key) => {
    errorMessage[key] = '';
});

const requiredFields = ['phase_name', 'status', 'image','project_name','operating_name'];
const CreatePhase = () => {
    const { currentUser } = useContext(AuthContext);
    const [formData, setFormData] = useState<FormData>(initFormData);
    const [validForm, setValidForm] = useState<ValidForm>(initValidForm);
    const [errorMsg, setErrorMsg] = useState<ErrorMsg>({hasTempLotData: ''});
    const [phaseId, setPhaseId] = useState<string | null>(null);
    const [communityId, setCommunityId] = useState<string | null>(null);
    const [communityDetails, setCommunityDetails] = useState<CommunityDataDetails | null>(null);
    const [isFormSubmitLoading, setFormSubmitLoading] = useState<boolean>(false);
    const [openLotContainer, setOpenLotContainer] = useState<boolean>(false);
    const [tempLotData, setTempLotData] = useState<any>(null);
    const [phaseImage, setPhaseImage] = useState<string>('');
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const location = useLocation();
    // eslint-disable-next-line no-useless-escape
    const isNumberValue = /^[\-\+]?[\d]+\.?(\d+)?$/;
    
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleChangeRadio = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        if (currentUser?.user_group !== 'admin') {
            navigate(`${ROUTES.HOME}`);
        }
        const search = window.location.search;
        const params = new URLSearchParams(search);
        setCommunityId(params?.get('community_id') || null);
        const parts = pathname.split('/');
        setPhaseId(parts?.[2] || null);
    }, []);

    useEffect(() => {
        breadCrumbData[1].link = `${ROUTES?.COMMUNITY}/${location?.state?.communityId || communityId}`;
        breadCrumbData[1].name = location?.state?.communityName || communityDetails?.community_name || 'Community';
        breadCrumbData[2].name = phaseId ? location?.state?.phaseName || formData?.phase_name || 'Update Phase' : 'Create';
    }, [communityId]);
    useEffect(() => {
        if (phaseId !== null) {
            breadCrumbData[2].name = location?.state?.phaseName || 'Update Phase';
            fetchPhaseDetails(phaseId)
                .then((response: any) => {
                    setFormData({ ...response?.data?.[0], image: response?.data?.[0]?.images?.[0]?.url });
                    setCommunityDetails(response?.data?.[0]?.community_id[0]);
                    setCommunityId(response?.data?.[0]?.community_id[0]?.uuid);                    
                })
                .catch((e: any) => {
                    console.log(e);
                });
        }
    }, [phaseId]);

    const valid = () => {
        let checkValidForm = validForm;
        let makeErrorMsg = errorMsg;
        let valid = true;
        Object.keys(validForm).map((v) => {
            if (requiredFields?.includes(v)) {
                makeErrorMsg[v as keyof FormData] = '';
                if (formData?.[v as keyof FormData] === undefined || formData?.[v as keyof FormData] === '' || formData?.[v as keyof FormData] === null) {
                    checkValidForm = { ...checkValidForm, [v]: false };
                    valid = false;
                } else if (v === 'no_of_plans' && formData?.[v as keyof FormData] !== '' && !isNumberValue?.test(formData?.[v as keyof FormData] as string)) {
                    valid = false;
                    checkValidForm = { ...checkValidForm, [v]: false };
                    makeErrorMsg[v] = 'Number of Plans/Models value should be number.';
                } else {
                    checkValidForm = { ...checkValidForm, [v]: true };
                }
            }
        });
        if (!phaseId && !tempLotData) {
            checkValidForm.hasTempLotData = false;
            errorMsg.hasTempLotData = 'Please create a model before submitting.';
            valid = false;
        } else {
            checkValidForm.hasTempLotData = true;
        }
        setErrorMsg({ ...errorMsg, ...makeErrorMsg });
        setValidForm({ ...validForm, ...checkValidForm });
        return valid;
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!valid()) {
            return true;
        }
        setFormSubmitLoading(true);
        const formDataToSend = new FormData();
        const postDataToSend = new FormData();
        const postData = _.pick(formData, _.keys(initFormData)) as any;       
        
        if(tempLotData){
            Object.entries(tempLotData).forEach(([key, value] :any) => {
                if (key !== 'lot_image' && key !== 'image') {
                    if (typeof value === 'string' || value instanceof Blob) {
                        formDataToSend.append(key, value);
                    } else if (Array.isArray(value)) {
                        // Handle the array case if necessary, e.g., convert to a JSON string
                        formDataToSend.append(key, JSON.stringify(value));
                    } else if (value !== null) {
                        // Handle other cases, such as numbers, booleans, etc.
                        formDataToSend.append(key, String(value));
                    }
                }
            });
        }
        Object.entries(postData).forEach(([key, value] :any) => {
            if (key !== 'image') {
                if (typeof value === 'string' || value instanceof Blob) {
                    postDataToSend.append(key, value);
                } else if (Array.isArray(value)) {
                    // Handle the array case if necessary, e.g., convert to a JSON string
                    postDataToSend.append(key, JSON.stringify(value));
                } else if (value !== null) {
                    // Handle other cases, such as numbers, booleans, etc.
                    postDataToSend.append(key, String(value));
                }
            }
        });
        if(communityId){
            postDataToSend.append(`community_uuid`, communityId);
        }
        if(postData?.image instanceof File){
            postDataToSend.append(`image`, postData?.image);            
        }              
              
        
        if (phaseId) {
            updatePhase(phaseId, postDataToSend)
                .then((result: any) => {
                    setFormSubmitLoading(false);
                    toast.success('Successfully updated details');
                })
                .catch((error: any) => {
                    setFormSubmitLoading(false);
                    const errMsg = error?.response?.status === 409 ? error?.response?.data?.message : 'Something went wrong! Please try again';
                    toast.error(errMsg);
                });
        } else {
            if (communityId) {
                createPhase(postDataToSend)
                    .then((result: any) => {
                        if (result) {
                            formDataToSend.append(`projectId`, result?.data?.projectId);
                            if(tempLotData){
                                Array.from(tempLotData.lot_image).forEach((file, index) => {
                                    formDataToSend.append(`lot_images`, file as File);
                                });
                            } 
                            createLot(formDataToSend)
                            .then((result: any) => {
                                setFormSubmitLoading(false);
                                toast.success('Successfully created phase');
                                setErrorMsg(prevErrorMsg => ({
                                    ...prevErrorMsg,
                                    hasTempLotData:'',
                                    // You can set other properties as needed here
                                  }));                                 
                                navigate(`${ROUTES.COMMUNITY}/${communityId}`);
                            }).catch((error: any) => {
                                setFormSubmitLoading(false);
                                const errMsg = error?.response?.status === 409 ? error?.response?.data?.message : 'Something went wrong! Please try again';
                                toast.error(errMsg);
                            })                            
                        }
                    })
                    .catch((error: any) => {
                        setFormSubmitLoading(false);
                        const errMsg = error?.response?.status === 409 ? error?.response?.data?.message : 'Something went wrong! Please try again';
                        toast.error(errMsg);
                    });
            } else {
                toast.error('Community not found');
            }
        }
    };

    
    const handleCloseModalAction = () => {
        setOpenLotContainer(false);

        return true;
    };
    const handleCreateClick = () => {
        setOpenLotContainer(true);
    };
    const handleChangeImage = (event: any) => {        
        
        const file = event.target.files[0]; // Get the first selected file
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPhaseImage(reader.result as string); // Set the Data URL for preview
            };
            reader.readAsDataURL(file);
            setPhaseImage(event.target.result)
                setFormData((prevData: any) => ({
                    ...prevData,
                    image: file
                }));
            }
    else {
            console.error('No file selected');
        }
    };
    return (
        <>
            <section className="content flex flex-col px-5 xl:px-[64px] lg:px-[34px] min-h-[calc(100vh-104px)]">
                <Breadcrumbs className={''} data={breadCrumbData}></Breadcrumbs>
                <div className="w-full pt-5">
                    <div className="flex">
                        <div className="w-[5%]">
                            <Button
                                className="text-orangeClr font-normal flex items-center  px-1 py-[4px]"
                                onClick={() => navigate(`${ROUTES.COMMUNITY}/${location?.state?.communityId || communityId}`)}
                            >
                                <GoArrowLeft className="mr-[6px] sm:text-xl text-lg" /> <p className="mt-1 text-sm">Back</p>
                            </Button>
                        </div>
                    </div>
                </div>

                <div className="mb-4 bg-white sm:rounded-2xl rounded-lg drop-shadow-[0px_4px_25px_rgba(116,110,109,0.04)] sm:p-6 px-3 py-4 dark:bg-[#252525]">
                    <form className="w-full" onSubmit={handleSubmit}>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <InputText
                                    className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-transparent dark:text-[#746E6D]  dark:border-opacity-50"
                                    inputName="phase_name"
                                    label="Phase Name"
                                    requiredLabel={true}
                                    placeholder="Enter Phase Name"
                                    value={formData?.phase_name || ''}
                                    onChangeFunc={handleInputChange}
                                    labelClassName="dark:text-[#746E6D]"
                                    errorText={!validForm.phase_name ? 'Phase Name is required' : null}
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full md:w-1/2 px-3">
                                <InputText
                                    requiredLabel={true}
                                    className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-transparent dark:text-[#746E6D]  dark:border-opacity-50"
                                    inputName="project_name"
                                    label="Project Name"
                                    placeholder="Enter Project Name"
                                    value={formData?.project_name}
                                    onChangeFunc={handleInputChange}
                                    labelClassName="dark:text-[#746E6D]"
                                    errorText={!validForm.project_name ? 'Project Name is required' : null}
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-6">

                            <div className="w-full md:w-1/2 px-3">
                                <InputText
                                    requiredLabel={true}
                                    className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-transparent dark:text-[#746E6D]  dark:border-opacity-50"
                                    inputName="operating_name"
                                    label="Operating Name"
                                    placeholder="Enter Operating Name"
                                    value={formData?.operating_name}
                                    onChangeFunc={handleInputChange}
                                    labelClassName="dark:text-[#746E6D]"
                                    errorText={!validForm.operating_name ? 'Operating Name is required' : null}
                                />
                            </div>

                        </div>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label className="form_label dark:text-[#746E6D]">Status</label>
                                <span className="text-red-600 ms-[2px]">*</span>
                                <span className="flex">
                                    <label className="form_label mr-3 pl-2" htmlFor={'active'}>
                                        <Radio
                                            id={`active`}
                                            className="w-5 h-5 checked:bg-orange-600 dark:checked:bg-orange-600 bg-white border-1 cursor-pointer focus:ring-2 focus:ring-orange-600 focus:outline-none"
                                            onChange={handleChangeRadio}
                                            name={'status'}
                                            value={'ACTIVE'}
                                            checked={formData?.status === 'ACTIVE'}
                                        />
                                        <span className="form_label mr-3 pl-2 dark:text-[#746E6D]">ACTIVE</span>
                                    </label>
                                    <label className="form_label mr-2 pl-2" htmlFor={'closed'}>
                                        <Radio
                                            id={`closed`}
                                            className="w-5 h-5 checked:bg-orange-600 dark:checked:bg-orange-600 bg-white border-1 cursor-pointer focus:ring-2 focus:ring-orange-600 focus:outline-none"
                                            onChange={handleChangeRadio}
                                            name={'status'}
                                            value={'CLOSED'}
                                            checked={formData?.status === 'CLOSED'}
                                        />
                                        <span className="form_label mr-2 pl-2 dark:text-[#746E6D]">CLOSED</span>
                                    </label>
                                </span>
                                {!validForm.status && <p className="text-sm text-red-600 dark:text-red-500">Status is required</p>}
                            </div>


                        </div>

                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full md:w-1/2 px-3">
                                <label className="form_label mb-1 dark:text-[#746E6D]">Image<span className='text-lg text-red-600 ms-[2px]'> *</span></label>
                                <div className="w-full">
                                <input type="file"
                                    className='placeholder-[#746E6D] dark:bg-transparent dark:text-[#746E6D]  dark:border-opacity-50 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white w-full'
                                    onChange={handleChangeImage}
                                    id = 'fileUpload'
                                    />
                                    <p className='text-sm text-red-600 dark:text-red-500 ms-[2px]'>{!validForm.image ? 'Image is required' : null}</p>
                                </div>
                            </div>
                            
                            <div className="w-full md:w-1/2 px-3">
                                <div className="overflow-hidden h-[150px] text-orangeClr">
                                    <img
                                        src={phaseImage || formData?.image}
                                        alt=""
                                        className="h-[100%]"
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = no_image;
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        {!phaseId && (
                            <div className="flex flex-wrap -mx-3 mb-6">
                            {currentUser?.user_group === 'admin' && (
                                <>
                            <p
                                className="text-orangeClr leading-normal right-5 sm:text-2xl text-xl font-normal cursor-pointer"
                                onClick={handleCreateClick}
                            >
                                <Icon
                                    nameIcon={'IoMdAddCircle'}
                                    propsIcon={{ className: 'me-2 text-base text-orangeClr dark:text-white h-[22px] w-full' }}
                                />

                            </p>
                            <p className='dark:text-[#746E6D]'> Create Model</p>
                            {!validForm.hasTempLotData && (
                                <div className="w-full mt-1">
                                    <p className="text-sm text-red-600 dark:text-red-500 ml-4">
                                        {errorMsg.hasTempLotData}
                                    </p>
                                </div>
                            )}
                            </>
                        )}

                        </div>
                        )}


                        <div className="flex flex-wrap mt-10 justify-center">
                            <Button
                                disabled={isFormSubmitLoading}
                                type="submit"
                                className={`text-orange-500 border border-orange-500 flex text-center items-center justify-center py-[6px] px-16 rounded-md sm:rounded-lg  ${
                                    isFormSubmitLoading ? ' opacity-60' : ''
                                }`}
                            >
                                {isFormSubmitLoading && (
                                    <svg
                                        aria-hidden="true"
                                        className="inline w-4 h-6 text-gray-200 me-2 animate-spin dark:text-gray-600 fill-[#FA5A0A]"
                                        viewBox="0 0 100 101"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="currentColor"
                                        />
                                        <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentFill"
                                        />
                                    </svg>
                                )}
                                {!isFormSubmitLoading && (
                                    <>
                                        {phaseId && 'Update'}
                                        {!phaseId && 'Create'}
                                    </>
                                )}
                            </Button>
                        </div>
                    </form>
                </div>
            </section>
            {openLotContainer && (
                <CommonModal handleClose={handleCloseModalAction} title="" size={'max-w-6xl w-[100%]'} headerClass={'text-center'}>
                <CreateLot
                    setTempLotData={setTempLotData}
                    setOpenLotContainer={setOpenLotContainer}
                    handleCloseModalAction={handleCloseModalAction}
                />
            </CommonModal>
            )}
        </>
    );
};

export default CreatePhase;
