import React, { useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { AuthContext } from '../context/AuthContext';

import { ROUTES } from '../constants/routes';
import { APP_FULL_NAME } from '../constants/common';
import { DashboardProvider } from '../context/DashboardContext';

const PrivateRoute = ({ component: Component, title, role, permission, ...props }: any) => {

    const { isAuthenticated } = useContext(AuthContext);

    useEffect(() => {
        document.title = `${title} - ${APP_FULL_NAME}`;
    }, [title]);
    return isAuthenticated ? (
        <DashboardProvider>
            <Component {...props} />
        </DashboardProvider>
    ) : (
        <Navigate to={ROUTES.LOGIN} />
    );
};

export default PrivateRoute;
