import { memo, useContext, useEffect, useState } from 'react';
import TopSearchBox from './component/TopSearchBar';
import CommunitySideBar from './component/CommunitySideBar';
import MapContainer from './component/MapContainer';
import { listCommunity } from '../../services/CommunityService';
import { DashboardContext } from '../../context/DashboardContext';
import { AxiosResponse,AxiosError } from 'axios';
interface CommunityFilter {
    community_id?: string;
    status?: string;
    community_name?: string;
}
interface Community {
    id: number;
    community_name: string;
}

interface CommunityResponse {
    data: Community[];
}
interface ErrorMessage{
    message: string;
}
const Dashboard = () => {
    const { setCommunityData, setCommunityPageDetail, community_name, status, community_id, phase_id } = useContext(DashboardContext);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        getCommunityData();
    }, [community_name, status, community_id, phase_id]);

    const getCommunityData = () => {
        setIsLoading(true);
        const comArray = community_id?.map((v: any) => v.value);
        const filter : CommunityFilter = {
            community_id: comArray?.join(','),
            status: status,
            community_name: community_name,
        };
        listCommunity(filter)
            .then((response: AxiosResponse<CommunityResponse>) => {
                setIsLoading(false);
                setCommunityData(response?.data || []);
            })
            .catch((e: AxiosError<ErrorMessage>) => {
                setIsLoading(false);
                console.log(e);
            });
        setCommunityPageDetail('');
    };
    return (
        <>
            <section className="content flex flex-col px-5 xl:px-[64px] lg:px-[34px]">
                <TopSearchBox />
                <div className="flex py-6 lg:flex-nowrap flex-wrap">
                    <CommunitySideBar isLoading={isLoading || false} reloadData={getCommunityData}/>
                    <MapContainer isLoading={isLoading || false} />
                </div>
            </section>
        </>
    );
};

export default memo(Dashboard);
